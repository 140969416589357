import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);




// const DO_APP: React.FunctionComponent = (props: any) => {

//   return(
//     <>
//       <h1 className={`some-class-${process.env.REACT_APP_KURO_TYPE}`}>
//         THIS IS DO APP
//       </h1>
//     </>
//   )
// } 

// const PUBLIC_APP: React.FunctionComponent = (props: any) => {

//   return(
//     <>
//       <h1>
//         THIS IS PUBLIC APP
//       </h1>
//     </>
//   )
// } 

// console.log("HELLO REACT_APP_KURO_TYPE: ", process.env.REACT_APP_KURO_TYPE);
// console.log("HELLO REACT_APP_KURO_TYPE: ENV VARIABLES", process.env);

// switch(process.env && process.env.REACT_APP_KURO_TYPE){
//   case "DO":
//     root.render(
//       <React.StrictMode>
//         <DO_APP />
//       </React.StrictMode>
//     );
    
//     break;


//   case "PUBLIC":
//     root.render(
//       <React.StrictMode>
//         <PUBLIC_APP />
//       </React.StrictMode>
//     );

//     break;


//   default:
//     root.render(
//       <React.StrictMode>
//         <PUBLIC_APP />
//       </React.StrictMode>
//     );

//     break;
// }


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(//Some logging function)