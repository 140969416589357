import React, {useState, useEffect, useContext} from 'react';
import { Col, Row, Tab, Nav } from 'react-bootstrap';

import {
  infoIcon,
  resourceAddIcon,
  hideContentBtn,
  retractContentBtn,
  expandContentBtn,
  openMenuDownBtn,
} from "./../../assets/images";

import { 
  ProfileLinkedProductContentTab, 
  ProfileIdentityContentTab, 
  ProfileLinkedProductOverviewTab,
  AdminUsersContentTab,
  AdminGroupsContentTab,
  AdminNotificationsContentTab, 
  HighlightedResourceAddButton,
  ProfileNoteContentTab,
  ProjectDetailContentTab,
  ProjectNoteContentTab,
  ProjectLinkedProductContentTab,
  ProjectLinkedProductOverviewTab,
  ProjectProfilesOverviewTab,
  ResourceVaultContentTab,
  ResourceVaultPasswordCheck,
  ProductToolsPanel
} from "./../";

import { 
  variables, 
  api, 
  helpers,
  bsgCrypto,
  authContextHelper 
} from "./../../utilities";





const ProductContentPane: React.FunctionComponent<any> = (props:any) => {
  const { productDetails, mediaControlHandlers, productType } = props;
  const lowercaseProductType = productType && productType.toLowerCase();


  return (
    <>
      {(() => {
        switch (productDetails.state.toUpperCase()) {
          case variables.DEFAULT_PRODUCT_STATES.RUNNING:
            return (
              <>
                <iframe className="k-iFrame d-flex align-self-stretch" 
                  allow="clipboard-read; clipboard-write;camera; microphone;" id={`desktop-iframe-${productDetails.id}`} 
                  src={productDetails.url} title={productDetails.id} 
                  onMouseOver={mediaControlHandlers.handleIframeMouseOver}>
                </iframe>
              </> 
            );
          case variables.DEFAULT_PRODUCT_STATES.BUILDING:
            return (
              <>
                <Row className="justify-content-md-center">
                  <Col md="auto" className="k-iFrameCenter">
                    <Row>
                      <Col className="col-3 pt-2">
                        <img src={infoIcon} alt={"Icon"} className="k-detailHeaderImg animate-flicker k-statusImg"/>
                      </Col>
                      <Col className="ps-0">
                        <h1>Building</h1>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h4>Your {lowercaseProductType} is building!</h4>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            );

          case variables.DEFAULT_PRODUCT_STATES.STOPPING:
            return (
              <>
                <Row className="justify-content-md-center">
                  <Col md="auto" className="k-iFrameCenter">
                    <Row>
                      <Col className="col-3 pt-2">
                        <img src={infoIcon} alt={"Icon"} className="k-detailHeaderImg animate-flicker k-statusImg"/>
                      </Col>
                      <Col className="ps-0">
                        <h1>Stopping</h1>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h4>Your {lowercaseProductType} is stopping!</h4>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            );

          case variables.DEFAULT_PRODUCT_STATES.STOPPED:
            return (
              <>
                <Row className="justify-content-md-center">
                  <Col md="auto" className="k-iFrameCenter">
                    <Row>
                      <Col className="col-3 pt-2">
                        <img src={infoIcon} alt={"Icon"} className="k-detailHeaderImg k-statusImg"/>
                      </Col>
                      <Col className="ps-0">
                        <h1>Stopped</h1>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                      <h4>Your {lowercaseProductType} is not running, <br/>click the start button to resume</h4>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            );

          case variables.DEFAULT_PRODUCT_STATES.STARTING:
            return (
              <>
                <Row className="justify-content-md-center">
                  <Col md="auto" className="k-iFrameCenter">
                    <Row>
                      <Col className="col-3 pt-2">
                        <img src={infoIcon} alt={"Icon"} className="k-detailHeaderImg animate-flicker k-statusImg"/>
                      </Col>
                      <Col className="ps-0">
                        <h1>Starting</h1>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h4>Your {lowercaseProductType} is starting!</h4>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            );

          case variables.DEFAULT_PRODUCT_STATES.HIBERNATING:
            return (
              <>
                <Row className="justify-content-md-center">
                  <Col md="auto" className="k-iFrameCenter">
                    <Row>
                      <Col className="col-3 pt-2">
                        <img src={infoIcon} alt={"Icon"} className="k-detailHeaderImg animate-flicker k-statusImg"/>
                      </Col>
                      <Col className="ps-0">
                        <h1>Hibernating</h1>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                      <h4>Your {lowercaseProductType} is hibernating!</h4>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            );

          case variables.DEFAULT_PRODUCT_STATES.HIBERNATION:
            return (
              <>
                <Row className="justify-content-md-center">
                  <Col md="auto" className="k-iFrameCenter">
                    <Row>
                      <Col className="col-3 pt-2">
                        <img src={infoIcon} alt={"Icon"} className="k-detailHeaderImg k-statusImg"/>
                      </Col>
                      <Col className="ps-0">
                        <h1>Hibernation</h1>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h4>Your {lowercaseProductType} is in hibernation, <br/>click the start button to resume</h4>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            );

          case variables.DEFAULT_PRODUCT_STATES.ERROR:
            return (
              <>
                <Row className="justify-content-md-center">
                  <Col md="auto" className="k-iFrameCenter">
                    <Row>
                      <Col className="col-3 pt-1">
                        <img src={infoIcon} alt={"Icon"} className="k-detailHeaderImg animate-flicker k-statusImg"/>
                      </Col>
                      <Col className="ps-0">
                        <h1>Error</h1>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h4>Humm ... Looks like something has gone wrong ... hold tight we are fixing it</h4>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            );

          default:
            return null
        }
      })()}
    </>
  );
};

const AdminContentPane: React.FunctionComponent<any> = (props: any) => {
  const { 
    users,
    groups,
    notifications,
    selectedObject,
    mediaControlHandlers,
    editUserSubmitError,
    editGroupSubmitError,
    handleAdminContentPaneTabsToggle, 
    adminContentPaneTabsActiveKey, 
    handleShowAddUserModal,
    handleShowAddGroupModal,
    handleUserEditSubmit,
    handleGroupEditSubmit,
    handleAdminSelectObject
  } = props;

  // console.log("Admin Cont Pane: Selected Object : ", selectedObject);
  // const [activeKey, setActiveKey] = useState("")

  // const handleToggle = (event: any) => {
  //   console.log("tab selected event : ", event)
  //   // console.log(typeof(event))
  //   // setTabActiveKey(event);
  //   handleProfileContentPaneTabsToggle(event)
  // }


  return(
    <>
      <Tab.Container id="profile-content-pane" 
        defaultActiveKey="admin.Users"
        activeKey={adminContentPaneTabsActiveKey}
        onSelect={handleAdminContentPaneTabsToggle}
      >
        <Row>
          <Row>
            <Nav className="pb-1 k-tabList">
              <Nav.Item className="">
                <Nav.Link eventKey="admin.Users" className="p-0 k-tabNav">
                  User
                  <span className='k-tabSubHeader'> Admin</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="">
                <Nav.Link eventKey="admin.Groups" className="p-0 k-tabNav">
                  Group
                  <span className='k-tabSubHeader'> Admin</span>
                </Nav.Link>
              </Nav.Item>
              {/*<Nav.Item className="">
                <Nav.Link eventKey="admin.Notifications" className="p-0 k-tabNav">
                  Notifications
                  <span className='k-tabSubHeader'> Display</span>
                </Nav.Link>
              </Nav.Item>*/}
            </Nav>
          </Row>
          <Row className="mt-4 pe-0">
            <Tab.Content >
              <Tab.Pane eventKey="admin.Users">
                <AdminUsersContentTab
                  handleShowAddUserModal={handleShowAddUserModal}
                  users={users}
                  groups={groups}
                  selectedUser={selectedObject}
                  editUserSubmitError={editUserSubmitError}
                  handleUserEditSubmit={handleUserEditSubmit}
                  handleAdminSelectObject={handleAdminSelectObject}
                  mediaControlHandlers={mediaControlHandlers}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="admin.Groups">
                <AdminGroupsContentTab
                  handleShowAddGroupModal={handleShowAddGroupModal}
                  users={users}
                  groups={groups}
                  selectedGroup={selectedObject}
                  editGroupSubmitError={editGroupSubmitError}
                  handleGroupEditSubmit={handleGroupEditSubmit}
                  handleAdminSelectObject={handleAdminSelectObject}
                  mediaControlHandlers={mediaControlHandlers}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="admin.Notifications">
                <AdminNotificationsContentTab
                  notifications={notifications}
                  selectedNotification={selectedObject}
                  handleAdminSelectObject={handleAdminSelectObject}
                />
              </Tab.Pane>
            </Tab.Content>
          </Row>
        </Row>
      </Tab.Container>
    </>
  )
}

const ProfileContentPane: React.FunctionComponent<any> = (props: any) => {
  const { 
    profileContentActiveProducts, 
    handleProfileContentPaneTabsToggle, 
    profileContentPaneTabsActiveKey, 

    profileDetails,
    profileNotes,
    profileProducts,

    profileNoteHandlers,
    profileVaultHandlers,

  } = props;

  console.log("PROFILE VAULT HANDLERS : ", profileVaultHandlers);

  return(
    <>
      <Tab.Container
         id="profile-content-pane" 
        defaultActiveKey="profile.Identity"
        activeKey={profileContentPaneTabsActiveKey}
        onSelect={handleProfileContentPaneTabsToggle}
      >
        <Row className='pt-0 me-3'>
          <Row className='pt-0'>
            <Nav className="pb-1 k-tabList">
              <Nav.Item className="">
                <Nav.Link eventKey="profile.Identity" className="p-0 k-tabNav">
                  Profile
                  <span className='k-tabSubHeader'> Dashboard</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="">
                <Nav.Link eventKey="profile.Vault" className="p-0 k-tabNav">
                  Password
                  <span className='k-tabSubHeader'> Manager</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="">
                <Nav.Link eventKey="profile.Notes" className="p-0 k-tabNav">
                  Note
                  <span className='k-tabSubHeader'> Editor</span>
                </Nav.Link>
              </Nav.Item>
              {profileContentActiveProducts && profileContentActiveProducts.map((dynamicProduct: any, index: number) => {
                return(
                  <Nav.Item key={`profile-content-tab-nav-${index}`}>
                    <Nav.Link eventKey={dynamicProduct.id}  className="p-0 k-tabNav">
                      {dynamicProduct.name}
                      <span className='k-tabSubHeader'> Product</span>
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
            </Nav>
          </Row>
          <Row className="mt-2 pe-0">
            <Tab.Content className='pt-1 pe-0'>
              <Tab.Pane eventKey="profile.Identity">
                <ProfileIdentityContentTab
                  profileDetails={profileDetails}
                  profileProducts={profileProducts}
                />                
              </Tab.Pane>
              <Tab.Pane eventKey="profile.Vault">
                {
                  profileVaultHandlers && profileVaultHandlers.decryptedVaultKey ?
                  <ResourceVaultContentTab
                    vault={profileVaultHandlers.profileVault}
                    vaultHandlers={{
                      ...profileVaultHandlers
                    }}
                  />:
                  <ResourceVaultPasswordCheck
                    vaultPasswordSubmitError={profileVaultHandlers.profileVaultPasswordSubmitError}
                    handleVaultPasswordSubmit={profileVaultHandlers.handleDycryptVaultSubmit}
                  />
                }
              </Tab.Pane>
              <Tab.Pane eventKey="profile.Notes">
                <ProfileNoteContentTab
                  profileID={profileDetails.id}
                  noteHandlers={profileNoteHandlers}
                  profileNotes={profileNotes}
                />
              </Tab.Pane>
              {profileContentActiveProducts && profileContentActiveProducts.map((dynamicProduct: any, index: number) => {
                return(
                  <Tab.Pane key={`profile-content-tab-content-${index}`} eventKey={dynamicProduct.id}>
                    <ProfileLinkedProductContentTab
                      product={dynamicProduct}
                    />
                  </Tab.Pane>
                );
              })}
            </Tab.Content>
          </Row>
        </Row>
      </Tab.Container>
    </>
  )
}

const ProfileLinkedProductsPane: React.FunctionComponent<any> = (props: any) => {
  const [browsers, setBrowsers] = useState<any>([]);
  const [communicates, setCommunicates] = useState<any>([]);
  const [desktops, setDesktops] = useState<any>([]);

  const {
    handleProfileLinkedProductSelected,
    products,

    // Modal Controls: Profile Products
    modalHandlers
  } = props;

  const sortProfileProductsByType = () => {
    let communicateProducts: any = [];
    let browserProducts: any = [];
    let desktopProducts: any = [];

    products && products.forEach((product: any): void => {
      switch (product.product){
        case variables.DEFAULT_PRODUCT_TYPES.CHAT.API_KEY:
          communicateProducts.push(product);
          break;
        
        case variables.DEFAULT_PRODUCT_TYPES.VTC.API_KEY:
          communicateProducts.push(product);
          break;

        case variables.DEFAULT_PRODUCT_TYPES.BROWSER.API_KEY:
          browserProducts.push(product);
          break;

        case variables.DEFAULT_PRODUCT_TYPES.DESKTOP.API_KEY:
          desktopProducts.push(product);
          break;
        
        default:
          break;
      }
    })

    // Update State
    setBrowsers(browserProducts);
    setDesktops(desktopProducts);
    setCommunicates(communicateProducts);
  }

  useEffect(() => {
    sortProfileProductsByType();
  }, [products]);
  
  //console.log("LINKED PRODUCTS : ", linkedProducts)


  return(
    <>
      <Tab.Container id="left-tabs-example" defaultActiveKey="profile.LinkedDesktops">
        <Row className='pt-0 me-3'>
          <Row className='pt-0'>
            <Nav className="pb-1 k-tabList">
              <Nav.Item className="">
                <Nav.Link eventKey="profile.LinkedDesktops" className="p-0 k-tabNav">
                  Desktop
                  <span className='k-tabSubHeader'> List</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="">
                <Nav.Link eventKey="profile.LinkedBrowsers" className="p-0 k-tabNav">
                  Browser
                  <span className='k-tabSubHeader'> List</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="">
                <Nav.Link eventKey="profile.LinkedCommunicates" className="p-0 k-tabNav">
                  VTC
                  <span className='k-tabSubHeader'> List</span>
                </Nav.Link>
              </Nav.Item> 
            </Nav>
          </Row>
          <br/>
          <Row className="pe-0">
            <Tab.Content className="pe-0">
              <Tab.Pane eventKey="profile.LinkedDesktops">
                <ProfileLinkedProductOverviewTab
                  products={desktops}
                  handleProfileLinkedProductSelected={handleProfileLinkedProductSelected}
                />
                <HighlightedResourceAddButton
                  clickHandler={modalHandlers.handleShowAddDesktopModal}
                  header={"Add Desktop"}
                  description={"Create a new desktop"}
                  image={resourceAddIcon}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="profile.LinkedBrowsers">
                <ProfileLinkedProductOverviewTab
                  products={browsers}
                  handleProfileLinkedProductSelected={handleProfileLinkedProductSelected}
                />
                <HighlightedResourceAddButton
                  clickHandler={modalHandlers.handleShowAddBrowserModal}
                  header={"Add Browser"}
                  description={"Create a new browser"}
                  image={resourceAddIcon}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="profile.LinkedCommunicates">
                <ProfileLinkedProductOverviewTab
                  products={communicates}
                  handleProfileLinkedProductSelected={handleProfileLinkedProductSelected}
                />
                <HighlightedResourceAddButton
                  clickHandler={modalHandlers.handleShowAddCommunicateModal}
                  header={"Add VTC"}
                  description={"Create a new comm"}
                  image={resourceAddIcon}
                />
              </Tab.Pane>
            </Tab.Content>
          </Row>
        </Row>
      </Tab.Container>
    </>
  )
}

const ProfileLinkedProductContentPane: React.FunctionComponent<any> = (props:any) => {
  const { 
    productDetails, 
    productType, 
    productState,
    
    mediaControlHandlers, 
  } = props;
  

  const auth = useContext(authContextHelper.AuthContext);

  const [productNotes, setProductNotes] = useState<any>([]);
  const [productVaultPasswordSubmitError, setProductVaultPasswordSubmitError] = useState<any>(null);
  const [productVaultSecretEditSubmitError, setProductVaultSecretEditSubmitError] = useState<any>(null);

  const [productVault, setProductVault] = useState<bsgCrypto.Vault | null>(null);
  const [decryptedVaultKey, setDecryptedVaultKey] = useState<any>(null);
  const [preSelectedSecretId, setPreSelectedSecretId] = useState<string>("")

  const lowercaseProductType: string = "product";


  const getProductNotes = async() => {
    if(productDetails && productDetails.id){
      const productNotesData = await api.getResourceNotes({
        resourceId: productDetails.id, 
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY
      });

      setProductNotes(productNotesData && productNotesData.sort(
        (a: { modified: number; }, b: { modified: number; }) => (a.modified < b.modified) ? 1 : -1)
      );
    }
  }

  const handleGetProductNote = async(event: any) => {
    //const ele = document.getElementById('profile-selectednote-editor');
    const noteData = await api.getResourceNote({
      resourceId: productDetails.id, 
      noteId: event.noteID, 
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY
    });
    
    if(noteData){
      event.editorRef.current.setContent(noteData);
      event.editorRef.current.id = event.noteID;
    }
  }

  const handlePutProductNote = async(event: any) => {
    console.log("LOG NOTE ERROR Event: ", event);
    const noteBody = event.current.getContent();
    const noteTmp = noteBody.replace(/<[^>]*>/g, "")
    const firstLine = noteTmp.split('\n')[0].substring(0, 35);
    const secondLine = noteTmp.split('\n')[1]?.substring(0, 35);

    console.log("LOG NOTE ERROR Body: ", noteBody);
    console.log("LOG NOTE ERROR noteTmp: ", noteTmp);
    console.log("LOG NOTE ERROR firstLine: ", firstLine);
    console.log("LOG NOTE ERROR secondLine: ", secondLine);

    if (noteTmp.split('\n').length > 1) {
      //const secondLine = noteTmp.split('\n')[1].substring(0, 35);

      await api.putResourceNote({
        resourceId: productDetails.id, 
        noteId: event.current.id, 
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY,
        body: noteBody,
        headline: secondLine, 
        title: firstLine
      });
    } else {
      await api.putResourceNote({
        resourceId: productDetails.id, 
        noteId: event.current.id, 
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY,
        body: noteBody,
        title: firstLine
      });
    }
    getProductNotes();
  }

  const handlePostProductNote = async(event: any) => {
    await api.postResourceNote({
      resourceId: productDetails.id,
      body: 'New Note', 
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY, 
      headline: 'New Note', 
      title: 'New Note'
    });

    getProductNotes();
  }

  const handleDeleteProductNote = async(noteID: any) => {

    console.log("LOG NOTE handleDeleteProductNote desktop : ", noteID)

    document.getElementById(`notecard-${noteID}`)?.classList.add('flash');
    await api.deleteResourceNote({
      resourceId: productDetails.id, 
      noteId: noteID,
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY, 
    });

    await getProductNotes();
    document.getElementById(`notecard-${noteID}`)?.classList.remove('flash');
  }

  const getProductVault = async() => {
    console.log("Resource Vault: Refresh: 1: ID:, ", productDetails.id);
    console.log("Resource Vault: Refresh: 1: Dycrypted Vault: ", decryptedVaultKey);

    if(productDetails.id && decryptedVaultKey){
      const productVaultData = await api.getResourceVault({
        resourceId: productDetails.id,
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY,
      });

      console.log("Resource Vault: Refresh: 2: Response: ", productVaultData);
      console.log("Resource Vault: Refresh: 2: Data: ", productVaultData.data);

      const desktopVaultRefreshed = new bsgCrypto.Vault(productVaultData.data, decryptedVaultKey);
      console.log("Resource Vault: Refresh: 3: Data Updated: ", desktopVaultRefreshed);
      
      setProductVault(desktopVaultRefreshed);
    }
  }
  
  const handleDycryptVaultSubmit = async(query: any) => {
    const productVaultData = await api.getResourceVault({
      resourceId: productDetails.id,
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY
    });

    console.log("Resource Vault: 1: ", productVaultData.data);
    const base64EncodedVaultKey = productVaultData.data.key[auth.userProfile.username]!;
    console.log("Resource Vault: 2: ", productVaultData.data);
    const myVaultKey = bsgCrypto.VaultKey.fromBase64(base64EncodedVaultKey);
    console.log("Resource Vault: 3: ", productVaultData.data);
    
    const unwrappedVaultKey = await bsgCrypto.UnwrappedVaultKey.fromWrappedVaultKey(
      myVaultKey,
      query.body.password,
      auth.userKeys!.wrappedPrivateKey
    );

    console.log("Resource Vault: 4: ", productVaultData.data);
    
    if(unwrappedVaultKey){
      setDecryptedVaultKey(unwrappedVaultKey);

      const desktopVaultRefreshed = new bsgCrypto.Vault(productVaultData.data, unwrappedVaultKey);
      console.log("Resource Vault: 5: Data Updated: ", desktopVaultRefreshed);
      
      setProductVault(desktopVaultRefreshed);
    } else {
      setProductVaultPasswordSubmitError("Password Error: Enter Valid Password");
    }
  };

  const handlePostProductVaultPassword = async(event: any) => {
    const postResponse = await api.postResourceVaultPassword({
      resourceId: productDetails.id,
      body: '<h1> New Password </h1>', 
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY, 
      headline: 'New Password', 
      title: 'New Password'
    });

    console.log("Creating new password: 1 : ", postResponse);

    if(postResponse.data && postResponse.data.id){
      const passwordChangeset = await productVault!.addOrUpdateItemInVault(
        "My new password",
        new bsgCrypto.SecretMetadata(postResponse.data.id, "My New Password Title", "Username")
      );

      console.log("Creating new password: 2 : Changeset: ", passwordChangeset); 

      const passwordChangeResponse = await api.patchResourceVaultPassword({
        resourceId: productDetails.id, 
        body: passwordChangeset, 
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY, 
      });

      console.log("Creating new password: 3 : Change Patch: ", passwordChangeResponse); 
    }

    await getProductVault();
  }


  const handleDeleteProductVaultPassword = async(query: any) => {
    console.log("Deleting password: 1 : ", query);

    if(query.body && query.body.secretId){

      const passwordChangeset = await productVault!.removeItemFromVault(query.body.secretId);

      console.log("Deleting password: 2 : Changeset: ", passwordChangeset); 

      const passwordChangeResponse = await api.patchResourceVaultPassword({
        resourceId: productDetails.id, 
        body: passwordChangeset, 
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY, 
      });

      console.log("Deleting password: 3 : Change Patch: ", passwordChangeResponse); 

      if (passwordChangeResponse && passwordChangeResponse.result === "Success") {

        const apiResponse = await api.deleteResourceVaultPassword({
          resourceId: productDetails.id, 
          resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY,
          secretId: query.body.secretId
        });

        console.log("Deleting password: 4 : API Response: ", apiResponse); 
      }
    }

    await getProductVault();
  }


  const handleEditProductVaultPassword = async(query: any) => {
    console.log("Editing password: 1 : ", query);

    if(query.body && query.body.secretId){
      console.log("Deleting password: 2 : ", query); 
      
      setPreSelectedSecretId(query.body.secretId);
      
      const passwordChangeset = await productVault!.addOrUpdateItemInVault(query.password, query.body);

      console.log("Deleting password: 3 : Changeset: ", passwordChangeset); 

      const passwordChangeResponse = await api.patchResourceVaultPassword({
        resourceId: productDetails.id, 
        body: passwordChangeset, 
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY, 
      });

      console.log("Deleting password: 4 : Change Patch: ", passwordChangeResponse); 
      
    }

    await getProductVault();
  }


  useEffect(() => {
    getProductNotes();
  }, []);

  return (
    <>
      <Row className="pt-1">
        <Col>
          <div id={`fullscreen-${productDetails.id}`} className="fullScreenContainer">
            <Row id="aa" xs="auto" className="toolbar">
              <Col className="pe-0"><img id="dd" src={hideContentBtn} alt={"Hide Content"} className="toobarButton pe-1" onClick={helpers.hideContent}/></Col>
              <Col className="ps-1 pe-0"><img id="ee" src={retractContentBtn} alt={"Redact"} className="toobarButton pe-1" onClick={helpers.exitFullScreen}/></Col>
              <Col className="ps-1 pe-0 col-auto me-auto"><img id="ff" src={expandContentBtn} alt={"Expand"} className="toobarButton pe-1" onClick={() => helpers.enterFullScreen(`fullscreen-${productDetails.id}`)}/></Col>
              <Col className="col-auto"><img id="gg" src={openMenuDownBtn} alt={"Dopdown"} className="toobarButton" onClick={() => helpers.showDropDown(`dropdown-${productDetails.id}`)}/></Col>
            </Row>
            <ProductContentPane
              productDetails={productDetails} 
              mediaControlHandlers={mediaControlHandlers} 
              productType={productType}
            />
            <Row id="priv-content" className="overlayBase justify-content-md-center ">
              <Col md="auto" className="k-iFrameCenter">
                <Row>
                  <Col className="col-3 pt-2">
                    <img src={infoIcon} alt={"Icon"} className="k-detailHeaderImg k-statusImg"/>
                  </Col>
                  <Col className="ps-0">
                    <h1>Locked</h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h4>Your {lowercaseProductType} has been locked</h4>
                  </Col>
                </Row>
              </Col>
            </Row>

            <ProductToolsPanel
              productDetails={productDetails}
              notes={productNotes}
              vault={productVault}
              decryptedVaultKey={decryptedVaultKey}
              preSelectedSecretId={preSelectedSecretId}

              vaultPasswordSubmitError={productVaultPasswordSubmitError}
              vaultSecretEditSubmitError={productVaultSecretEditSubmitError}

              vaultHandlers={{
                handleDycryptVaultSubmit: handleDycryptVaultSubmit,
                handlePostProductVaultPassword: handlePostProductVaultPassword,
                handleDeleteProductVaultPassword: handleDeleteProductVaultPassword,
                handleEditProductVaultPassword: handleEditProductVaultPassword
              }}

              noteHandlers={{
                handleGetProductNote,
                handlePostProductNote,
                handlePutProductNote,
                handleDeleteProductNote
              }}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

const ProjectContentPane: React.FunctionComponent<any> = (props: any) => {
  const { 
    projectContentActiveProducts, 
    handleProjectContentPaneTabsToggle, 
    projectContentPaneTabsActiveKey, 

    projectDetails,
    projectNotes,

    projectNoteHandlers,
    projectVaultHandlers,

  } = props;

  //console.log("Project content active products : ", projectContentActiveProducts);


  return(
    <>
      <Tab.Container 
        id="project-content-pane" 
        defaultActiveKey="project.Details"
        activeKey={projectContentPaneTabsActiveKey}
        onSelect={handleProjectContentPaneTabsToggle}
      >
        <Row className='pt-0 me-3'>
          <Row className='pt-0'>
            <Nav className="pb-1 k-tabList">
              <Nav.Item className="">
                <Nav.Link eventKey="project.Details" className="p-0 k-tabNav">
                  Project
                  <span className='k-tabSubHeader'> Dashboard</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="">
                <Nav.Link eventKey="project.Vault" className="p-0 k-tabNav">
                  Password
                  <span className='k-tabSubHeader'> Manager</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="">
                <Nav.Link eventKey="project.Notes" className="p-0 k-tabNav">
                  Note
                  <span className='k-tabSubHeader'> Editor</span>
                </Nav.Link>
              </Nav.Item>
              {projectContentActiveProducts && projectContentActiveProducts.map((dynamicProduct: any, index: number) => {
                return(
                  <Nav.Item key={`project-content-tab-nav-${index}`}>
                    <Nav.Link eventKey={dynamicProduct.id}  className="p-0 k-tabNav">
                      {dynamicProduct.name}
                      <span className='k-tabSubHeader'> Product</span>
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
            </Nav>
          </Row>
          <Row className="mt-4 pe-0">
            <Tab.Content className='pt-1 pe-0'>
              <Tab.Pane eventKey="project.Details">
                <ProjectDetailContentTab
                  projectDetails={projectDetails}
                />                
              </Tab.Pane>
              <Tab.Pane eventKey="project.Vault">
                {
                  projectVaultHandlers && projectVaultHandlers.decryptedVaultKey ?
                  <ResourceVaultContentTab
                    vault={projectVaultHandlers.projectVault}
                    vaultHandlers={{
                      ...projectVaultHandlers
                    }}
                  />:
                  <ResourceVaultPasswordCheck
                    vaultPasswordSubmitError={projectVaultHandlers.projectVaultPasswordSubmitError}
                    handleVaultPasswordSubmit={projectVaultHandlers.handleDycryptVaultSubmit}
                  />
                }
              </Tab.Pane>
              <Tab.Pane eventKey="project.Notes">
                <ProjectNoteContentTab
                  projectID={projectDetails.id}
                  noteHandlers={projectNoteHandlers}
                  projectNotes={projectNotes}
                />
              </Tab.Pane>
              {projectContentActiveProducts && projectContentActiveProducts.map((dynamicProduct: any, index: number) => {
                return(
                  <Tab.Pane key={`project-content-tab-content-${index}`} eventKey={dynamicProduct.id}>
                    <ProjectLinkedProductContentTab
                      product={dynamicProduct}
                    />
                  </Tab.Pane>
                );
              })}
            </Tab.Content>
          </Row>
        </Row>
      </Tab.Container>
    </>
  )
}

const ProjectLinkedProductsPane: React.FunctionComponent<any> = (props: any) => {
  const [browsers, setBrowsers] = useState<any>([]);
  const [communicates, setCommunicates] = useState<any>([]);
  const [desktops, setDesktops] = useState<any>([]);

  const {
    handleProjectLinkedProductSelected,
    handleProjectLinkedProfileSelected,
    products,
    profiles,

    // Modal Handlers
    modalHandlers
  } = props;

  const sortProjectProductsByType = () => {
    let communicateProducts: any = [];
    let browserProducts: any = [];
    let desktopProducts: any = [];

    products && products.forEach((product: any): void => {
      switch (product.product){
        case variables.DEFAULT_PRODUCT_TYPES.VTC.API_KEY:
          communicateProducts.push(product);
          break;

        case variables.DEFAULT_PRODUCT_TYPES.CHAT.API_KEY:
          communicateProducts.push(product);
          break;

        case variables.DEFAULT_PRODUCT_TYPES.BROWSER.API_KEY:
          browserProducts.push(product);
          break;

        case variables.DEFAULT_PRODUCT_TYPES.DESKTOP.API_KEY:
          desktopProducts.push(product);
          break;
        
        default:
          break;
      }
    })

    // Update State
    setBrowsers(browserProducts);
    setDesktops(desktopProducts);
    setCommunicates(communicateProducts);
  }

  useEffect(() => {
    sortProjectProductsByType();

  }, [products, profiles]);
  


  return(
    <>
      <Tab.Container id="left-tabs-example" defaultActiveKey="project.LinkedDesktops">
        <Row className='pt-0 me-3'>
          <Row className='pt-0'>
            <Nav className="pb-1 k-tabList">
              <Nav.Item className="">
                <Nav.Link eventKey="project.LinkedDesktops" className="p-0 k-tabNav">
                  Desktop
                  <span className='k-tabSubHeader'> List</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="">
                <Nav.Link eventKey="project.LinkedBrowsers" className="p-0 k-tabNav">
                  Browser
                  <span className='k-tabSubHeader'> List</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="">
                <Nav.Link eventKey="project.LinkedCommunicates" className="p-0 k-tabNav">
                  VTC
                  <span className='k-tabSubHeader'> List</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="">
                <Nav.Link eventKey="project.LinkedProfiles" className="p-0 k-tabNav">
                  Profile
                  <span className='k-tabSubHeader'> List</span>
                </Nav.Link>
              </Nav.Item>
             
            </Nav>
          </Row>
          <br/>
          <Row className="pe-0">
            <Tab.Content className="pe-0">
              <Tab.Pane eventKey="project.LinkedDesktops">
                <ProjectLinkedProductOverviewTab
                  productType={"DESKTOPS"}
                  products={desktops}
                  handleProjectLinkedResourceSelected={handleProjectLinkedProductSelected}
                />
                <HighlightedResourceAddButton
                  clickHandler={modalHandlers.handleShowAddDesktopModal}
                  header={"Add Desktop"}
                  description={"Create a new desktop"}
                  image={resourceAddIcon}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="project.LinkedBrowsers">
                <ProjectLinkedProductOverviewTab
                  products={browsers}
                  handleProjectLinkedResourceSelected={handleProjectLinkedProductSelected}
                />
                <HighlightedResourceAddButton
                  clickHandler={modalHandlers.handleShowAddBrowserModal}
                  header={"Add Browser"}
                  description={"Create a new browser"}
                  image={resourceAddIcon}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="project.LinkedCommunicates">
                <ProjectLinkedProductOverviewTab
                  products={communicates}
                  handleProjectLinkedResourceSelected={handleProjectLinkedProductSelected}
                />
                <HighlightedResourceAddButton
                  clickHandler={modalHandlers.handleShowAddCommunicateModal}
                  header={"Add Comms"}
                  description={"Create a new comm"}
                  image={resourceAddIcon}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="project.LinkedProfiles">
                  <ProjectProfilesOverviewTab
                    profiles={profiles}
                    handleProjectLinkedResourceSelected={handleProjectLinkedProfileSelected}
                  />
                  <HighlightedResourceAddButton
                    clickHandler={modalHandlers.handleShowAddProfileModal}
                    header={"Add Profile"}
                    description={"Create a new profile"}
                    image={resourceAddIcon}
                  />
                </Tab.Pane>
            </Tab.Content>
          </Row>
        </Row>
      </Tab.Container>
    </>
  )
}

const ProjectLinkedProductContentPane: React.FunctionComponent<any> = (props:any) => {
  const { 
    productDetails, 
    productType, 
    productState,
    
    mediaControlHandlers, 
  } = props;

  const auth = useContext(authContextHelper.AuthContext);

  const [productNotes, setProductNotes] = useState<any>([]);
  const [productVaultPasswordSubmitError, setProductVaultPasswordSubmitError] = useState<any>(null);
  const [productVaultSecretEditSubmitError, setProductVaultSecretEditSubmitError] = useState<any>(null);

  const [productVault, setProductVault] = useState<bsgCrypto.Vault | null>(null);
  const [decryptedVaultKey, setDecryptedVaultKey] = useState<any>(null);
  const [preSelectedSecretId, setPreSelectedSecretId] = useState<string>("")

  const lowercaseProductType: string = "product";


  const getProductNotes = async() => {
    if(productDetails && productDetails.id){
      const productNotesData = await api.getResourceNotes({
        resourceId: productDetails.id, 
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY
      });

      setProductNotes(productNotesData && productNotesData.sort(
        (a: { modified: number; }, b: { modified: number; }) => (a.modified < b.modified) ? 1 : -1)
      );
    }
  }

  const handleGetProductNote = async(event: any) => {
    //const ele = document.getElementById('profile-selectednote-editor');
    const noteData = await api.getResourceNote({
      resourceId: productDetails.id, 
      noteId: event.noteID, 
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY
    });
    
    if(noteData){
      event.editorRef.current.setContent(noteData);
      event.editorRef.current.id = event.noteID;
    }
  }

  const handlePutProductNote = async(event: any) => {
    console.log("LOG NOTE ERROR Event: ", event);
    const noteBody = event.current.getContent();
    const noteTmp = noteBody.replace(/<[^>]*>/g, "")
    const firstLine = noteTmp.split('\n')[0].substring(0, 35);
    const secondLine = noteTmp.split('\n')[1]?.substring(0, 35);

    console.log("LOG NOTE ERROR Body: ", noteBody);
    console.log("LOG NOTE ERROR noteTmp: ", noteTmp);
    console.log("LOG NOTE ERROR firstLine: ", firstLine);
    console.log("LOG NOTE ERROR secondLine: ", secondLine);

    if (noteTmp.split('\n').length > 1) {
      //const secondLine = noteTmp.split('\n')[1].substring(0, 35);

      await api.putResourceNote({
        resourceId: productDetails.id, 
        noteId: event.current.id, 
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY,
        body: noteBody,
        headline: secondLine, 
        title: firstLine
      });
    } else {
      await api.putResourceNote({
        resourceId: productDetails.id, 
        noteId: event.current.id, 
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY,
        body: noteBody,
        title: firstLine
      });
    }
    getProductNotes();
  }

  const handlePostProductNote = async(event: any) => {
    await api.postResourceNote({
      resourceId: productDetails.id,
      body: 'New Note', 
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY, 
      headline: 'New Note', 
      title: 'New Note'
    });

    getProductNotes();
  }

  const handleDeleteProductNote = async(noteID: any) => {

    console.log("LOG NOTE handleDeleteProductNote desktop : ", noteID)

    document.getElementById(`notecard-${noteID}`)?.classList.add('flash');
    await api.deleteResourceNote({
      resourceId: productDetails.id, 
      noteId: noteID,
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY, 
    });

    await getProductNotes();
    document.getElementById(`notecard-${noteID}`)?.classList.remove('flash');
  }

  const getProductVault = async() => {
    console.log("Resource Vault: Refresh: 1: ID:, ", productDetails.id);
    console.log("Resource Vault: Refresh: 1: Dycrypted Vault: ", decryptedVaultKey);

    if(productDetails.id && decryptedVaultKey){
      const productVaultData = await api.getResourceVault({
        resourceId: productDetails.id,
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY,
      });

      console.log("Resource Vault: Refresh: 2: Response: ", productVaultData);
      console.log("Resource Vault: Refresh: 2: Data: ", productVaultData.data);

      const desktopVaultRefreshed = new bsgCrypto.Vault(productVaultData.data, decryptedVaultKey);
      console.log("Resource Vault: Refresh: 3: Data Updated: ", desktopVaultRefreshed);
      
      setProductVault(desktopVaultRefreshed);
    }
  }
  
  const handleDycryptVaultSubmit = async(query: any) => {
    const productVaultData = await api.getResourceVault({
      resourceId: productDetails.id,
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY
    });

    console.log("Resource Vault: 1: ", productVaultData.data);
    const base64EncodedVaultKey = productVaultData.data.key[auth.userProfile.username]!;
    console.log("Resource Vault: 2: ", productVaultData.data);
    const myVaultKey = bsgCrypto.VaultKey.fromBase64(base64EncodedVaultKey);
    console.log("Resource Vault: 3: ", productVaultData.data);
    
    const unwrappedVaultKey = await bsgCrypto.UnwrappedVaultKey.fromWrappedVaultKey(
      myVaultKey,
      query.body.password,
      auth.userKeys!.wrappedPrivateKey
    );

    console.log("Resource Vault: 4: ", productVaultData.data);
    
    if(unwrappedVaultKey){
      setDecryptedVaultKey(unwrappedVaultKey);

      const desktopVaultRefreshed = new bsgCrypto.Vault(productVaultData.data, unwrappedVaultKey);
      console.log("Resource Vault: 5: Data Updated: ", desktopVaultRefreshed);
      
      setProductVault(desktopVaultRefreshed);
    } else {
      setProductVaultPasswordSubmitError("Password Error: Enter Valid Password");
    }
  };

  const handlePostProductVaultPassword = async(event: any) => {
    const postResponse = await api.postResourceVaultPassword({
      resourceId: productDetails.id,
      body: '<h1> New Password </h1>', 
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY, 
      headline: 'New Password', 
      title: 'New Password'
    });

    console.log("Creating new password: 1 : ", postResponse);

    if(postResponse.data && postResponse.data.id){
      const passwordChangeset = await productVault!.addOrUpdateItemInVault(
        "My new password",
        new bsgCrypto.SecretMetadata(postResponse.data.id, "My New Password Title", "Username")
      );

      console.log("Creating new password: 2 : Changeset: ", passwordChangeset); 

      const passwordChangeResponse = await api.patchResourceVaultPassword({
        resourceId: productDetails.id, 
        body: passwordChangeset, 
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY, 
      });

      console.log("Creating new password: 3 : Change Patch: ", passwordChangeResponse); 
    }

    await getProductVault();
  }


  const handleDeleteProductVaultPassword = async(query: any) => {
    console.log("Deleting password: 1 : ", query);

    if(query.body && query.body.secretId){

      const passwordChangeset = await productVault!.removeItemFromVault(query.body.secretId);

      console.log("Deleting password: 2 : Changeset: ", passwordChangeset); 

      const passwordChangeResponse = await api.patchResourceVaultPassword({
        resourceId: productDetails.id, 
        body: passwordChangeset, 
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY, 
      });

      console.log("Deleting password: 3 : Change Patch: ", passwordChangeResponse); 

      if (passwordChangeResponse && passwordChangeResponse.result === "Success") {

        const apiResponse = await api.deleteResourceVaultPassword({
          resourceId: productDetails.id, 
          resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY,
          secretId: query.body.secretId
        });

        console.log("Deleting password: 4 : API Response: ", apiResponse); 
      }
    }

    await getProductVault();
  }


  const handleEditProductVaultPassword = async(query: any) => {
    console.log("Editing password: 1 : ", query);

    if(query.body && query.body.secretId){
      console.log("Deleting password: 2 : ", query); 
      
      setPreSelectedSecretId(query.body.secretId);
      
      const passwordChangeset = await productVault!.addOrUpdateItemInVault(query.password, query.body);

      console.log("Deleting password: 3 : Changeset: ", passwordChangeset); 

      const passwordChangeResponse = await api.patchResourceVaultPassword({
        resourceId: productDetails.id, 
        body: passwordChangeset, 
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY, 
      });

      console.log("Deleting password: 4 : Change Patch: ", passwordChangeResponse); 
      
    }

    await getProductVault();
  }


  useEffect(() => {
    getProductNotes();
  }, []);

  return (
    <>
      <Row className="pt-1">
        <Col>
          <div id={`fullscreen-${productDetails.id}`} className="fullScreenContainer">
            <Row id="aa" xs="auto" className="toolbar">
              <Col className="pe-0"><img id="dd" src={hideContentBtn} alt={"Hide Content"} className="toobarButton pe-1" onClick={helpers.hideContent}/></Col>
              <Col className="ps-1 pe-0"><img id="ee" src={retractContentBtn} alt={"Redact"} className="toobarButton pe-1" onClick={helpers.exitFullScreen}/></Col>
              <Col className="ps-1 pe-0 col-auto me-auto"><img id="ff" src={expandContentBtn} alt={"Expand"} className="toobarButton pe-1" onClick={() => helpers.enterFullScreen(`fullscreen-${productDetails.id}`)}/></Col>
              <Col className="col-auto"><img id="gg" src={openMenuDownBtn} alt={"Dopdown"} className="toobarButton" onClick={() => helpers.showDropDown(`dropdown-${productDetails.id}`)}/></Col>
            </Row>
            <ProductContentPane
              productDetails={productDetails} 
              mediaControlHandlers={mediaControlHandlers} 
              productType={productType}
            />
            <Row id="priv-content" className="overlayBase justify-content-md-center ">
              <Col md="auto" className="k-iFrameCenter">
                <Row>
                  <Col className="col-3 pt-2">
                    <img src={infoIcon} alt={"Icon"} className="k-detailHeaderImg k-statusImg"/>
                  </Col>
                  <Col className="ps-0">
                    <h1>Locked</h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h4>Your {lowercaseProductType} has been locked</h4>
                  </Col>
                </Row>
              </Col>
            </Row>

            <ProductToolsPanel
              productDetails={productDetails}
              notes={productNotes}
              vault={productVault}
              decryptedVaultKey={decryptedVaultKey}
              preSelectedSecretId={preSelectedSecretId}

              vaultPasswordSubmitError={productVaultPasswordSubmitError}
              vaultSecretEditSubmitError={productVaultSecretEditSubmitError}

              vaultHandlers={{
                handleDycryptVaultSubmit: handleDycryptVaultSubmit,
                handlePostProductVaultPassword: handlePostProductVaultPassword,
                handleDeleteProductVaultPassword: handleDeleteProductVaultPassword,
                handleEditProductVaultPassword: handleEditProductVaultPassword
              }}

              noteHandlers={{
                handleGetProductNote,
                handlePostProductNote,
                handlePutProductNote,
                handleDeleteProductNote
              }}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};


export {
  ProductContentPane,
  AdminContentPane,

  ProfileContentPane,
  ProfileLinkedProductsPane,
  ProfileLinkedProductContentPane,

  ProjectContentPane,
  ProjectLinkedProductsPane,
  ProjectLinkedProductContentPane

};