import React, { useState, useEffect } from 'react';
import { 
  Row, 
  Col, 
  Modal, 
  Button, 
  Container, 
  Form, 
  Dropdown 
} from 'react-bootstrap';

import {
  oneIcon,
  twoIcon,
  threeIcon,
  fourIcon,
  modalDesktopCreateBackground,
  modalBrowserCreateBackground,
  desktopIcon,
  modalBrowserCreateHeaderIcon,
  resourceAddIcon,
  userProfileNavIcon,
  productMediaDeleteIcon
} from "./../../assets/images";

import {
  AdminGroupAddUsersDropdownMenu,
  AdminGroupAddUsersDropdownToggle
} from "./../";

import {
  variables,
  helpers
} from "./../../utilities";


/**
 * Generic Product Add Modal
 *  - Renders Form to allow users to add new generic products
 * @param props 
 * @returns 
 */
const ProductGenericAddModal: React.FunctionComponent<any> = (props: any) => {
  const [modalFormBody, setModalFormBody] = useState<any>({});
  const [isProductNameValid, setProductNameValid] = useState(false);
  const [isProductUsernameValid, setProductUsernameValid] = useState(false);
  const [isProductDescriptionValid, setProductDescriptionValid] = useState(true);
  const [isProductLocationValid, setProductLocationValid] = useState(false);
  const [isProductSystemValid, setProdctSystemValid] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  
  const [isProfileDisabled, setProfileDisabled] = useState(false);
  const [isProjectDisabled, setProjectDisabled] = useState(false);

  const [isFormValid, setFormValid] = useState(false);

  const { 
    handleClose, 
    show, 
    handleModalFormSubmit, 
    submitError, 
    linkedProfileId,
    linkedProjectId,
    projects,
    securityGroups,
    profiles 
  } = props;

  
  useEffect(() => {
    setModalFormBody({
      profile: linkedProfileId,
      project: linkedProjectId,
    });
    setProductNameValid(false);
    setProductDescriptionValid(true);
    setProductLocationValid(false);
    setProdctSystemValid(false);
    setProductUsernameValid(false);
  }, [show])

  const handleInputChange = (event: any) => {
    if(event.target.name === "name") {
      // event.target.value = event.target.value.split(" ").join("");
      // event.target.value = event.target.value.replace(" ", "");
      if (variables.DEFAULT_FORM_VALIDATION.PRODUCT.NAME.test(event.target.value)) {
        setProductNameValid(true);
      } else {
        setProductNameValid(false);
      }
    };

    if(event.target.name === "productUsername") {
      // event.target.value = event.target.value.split(" ").join("");
      event.target.value = event.target.value.replace(" ", "");
      if (variables.DEFAULT_FORM_VALIDATION.PRODUCT.USERNAME.test(event.target.value)) {
        setProductUsernameValid(true);
      } else {
        setProductUsernameValid(false);
      }
    };

    if(event.target.name === "description") {
      // event.target.value = event.target.value.split(" ").join("");
      // event.target.value = event.target.value.replace(" ", "");
      if (variables.DEFAULT_FORM_VALIDATION.PRODUCT.DESCRIPTION.test(event.target.value) || event.target.value === "") {
        setProductDescriptionValid(true);
      } else {
        setProductDescriptionValid(false);
      }
    };

    if(event.target.name === "location") {
      // event.target.value = event.target.value.split(" ").join("");
      // event.target.value = event.target.value.replace(" ", "");
      if ( event.target.value !== "") {
        setProductLocationValid(true);
      } else {
        setProductLocationValid(false);
      }
    };

    if(event.target.name === "system") {
      if (event.target.value !== "") {
        setProdctSystemValid(true);
      } else {
        setProdctSystemValid(false);
      }
    };

    switch(event.target.name){
      case "profile":
        if (event.target.value !== "") {
          setProjectDisabled(true);

          setModalFormBody({
            ...helpers.filterObjectByRemovingKeys(modalFormBody, ["project"]),
            [event.target.name]: event.target.value,
          });
        } else if (event.target.value === ""){
          setProjectDisabled(false);

          setModalFormBody({
            ...helpers.filterObjectByRemovingKeys(modalFormBody, ["profile"])
          })
        }
        
        break;

      case "project":
        if (event.target.value !== "") {
          setProfileDisabled(true);

          setModalFormBody({
            ...helpers.filterObjectByRemovingKeys(modalFormBody, ["profile"]),
            [event.target.name]: event.target.value,
          })
        } else if (event.target.value === ""){
          setProfileDisabled(false);

          setModalFormBody({
            ...helpers.filterObjectByRemovingKeys(modalFormBody, ["project"])
          })
        }

        break;
      
      default:
        setModalFormBody({
          ...modalFormBody,
          [event.target.name]: event.target.value,
        });

        break;
    }


    if (isProductNameValid && isProductDescriptionValid && isProductLocationValid && isProductSystemValid && isProductUsernameValid){
      setFormValid(true)
    } else {
      setFormValid(false)
    }

  }

  const validateForm = () => {
    if(modalFormBody["system"] && modalFormBody["system"] !== "" ){
      setProdctSystemValid(true)
    } else {
      setProdctSystemValid(false)
    }

    if(modalFormBody["location"] && modalFormBody["location"] !== "" ){
      setProductLocationValid(true)
    } else {
      setProductLocationValid(false)
    }
  }

  const handleFormSubmit = async(event: any) => {
    event.preventDefault();

    validateForm();

    // handleModalFormSubmit({body: {
    //   ...modalFormBody
    // }})

    if (isProductNameValid && isProductDescriptionValid && isProductLocationValid && isProductSystemValid && isProductUsernameValid){
      // setModalFormBody({});
      console.log("PRODUCT ADD MODAL: ", modalFormBody);
      
      setSubmitClicked(true);
      await handleModalFormSubmit({body: {
        ...modalFormBody
      }});
      setSubmitClicked(false);

    } else {
      console.log("FORM NOT VALID... Won't make it so");
    }
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} contentClassName="k-mc-right" dialogClassName="kuro-modal" tabIndex={-1} size="lg" className="k-modal k-m-right" >
        <Form onSubmit={handleFormSubmit}>

          <Row className="k-modalHeader p-0 m-0">
                <img alt="name icon" src={modalDesktopCreateBackground} className="k-modalTitleImg p-0"/>
                <Row className="k-center ps-3">
                  <Col className="col-2 p-0">
                    <img alt="name icon" src={desktopIcon} className="k-modalIcon pe-1 pt-2"/>
                  </Col>
                  <Col className="col-10 ps-3 k-pt-2">
                    <Row className="k-headerText">Create Desktop</Row>
                    <Row className="k-subHeaderText">Configure - Create - Capitalise</Row>
                  </Col>
                </Row>
          </Row>
          
          <Modal.Body className="k-modalBody">
            <Container className="pe-0">
              <Row className="">
                <Col className="col-12 pb-3 k-bb">
                    <Row>
                      <Col className="px-0 col-1">
                        <img alt="name icon" src={oneIcon} className="k-modalIconSM pe-1"/>
                      </Col>
                      <Col className="k-inputText18 col-11 ps-0">
                        Enter a name and description
                      </Col>
                    </Row>
                    <Row className="k-inputTextLarge">
                      <Col className="px-0 col-1"></Col>
                      <Col className="col-11 ps-0 ">
                        <Form.Group className="p-0 " controlId="modalDesktopForm.ControlInput1">
                          <Form.Control className="p-0 k-inputText k-inputText22" type="text" placeholder="Desktop Name" name='name'  onChange={handleInputChange}/>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="k-inputTextMedium">
                      <Col className="px-0 col-1"></Col>
                      <Col className="col-11 ps-0 ">
                        <Form.Group className="p-0 " controlId="modalDesktopForm.ControlInput2">
                          <Form.Control className="p-0 k-inputText k-inputText15" type="text" placeholder="Desktop description"  name='description' onChange={handleInputChange} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      {isProductNameValid ?  null: <small style={{color: "red"}}>Enter a valid Desktop Name. {"(5 - 25 Alphabets and Numbers only accepted)"}</small>}
                      {isProductDescriptionValid ?  null : <small style={{color: "red"}}>Enter a valid Desktop Description. {"(Alphabets, Numbers, space and charcaters[-., ()/] accepted)"}</small>}
                    </Row>
                </Col>
              </Row>
              
              <Row className="py-3 k-bb">
                <Row>
                  <Col className="px-0 col-1">
                    <img alt="name icon" src={twoIcon} className="k-modalIconSM pe-1"/>
                  </Col>
                  <Col className="k-inputText18 col-11 ps-0">
                    Link or share your desktop
                  </Col>
                </Row>
                <Row className="pe-0">
                  <Col className="px-0 col-1"></Col>
                  <Col className="col-11 py-0 ">
                    <Row>
                      <Col>
                        <Row className="k-inputText15">
                          Linked Project
                        </Row>
                        {/* <Row className="disabled-text">
                          ----
                        </Row> */}
                        {(projects && !isProjectDisabled)? 
                          <Row>
                            <Form.Select aria-label="Select a project" id="modalDesktopForm.project" className="k-inputSelect k-inputText18" name='project' onChange={handleInputChange} >
                              <option value="" selected > Select project </option>
                              {projects && projects.map((project: any, index: number) => (<option key={`Projects-${index}`} value={project.id} >{project.name}</option>))}
                            </Form.Select>
                          </Row>
                          :
                          <Row className="disabled-text">
                            ----
                          </Row>
                        }
                      </Col>
                      <Col >
                        <Row className="k-inputText15">
                          Linked Profile
                        </Row>
                        {(profiles && !isProfileDisabled)? 
                          <Row>
                            <Form.Select aria-label="Select a profile" id="modalDesktopForm.profile" className="k-inputSelect k-inputText18" name='profile' onChange={handleInputChange} >
                              <option value="" selected > Select profile </option>
                              {profiles && profiles.map((profile: any, index: number) => (<option key={`Groups-${index}`} value={profile.id} >{profile.name}</option>))}
                            </Form.Select>
                          </Row>
                          :
                          <Row className="disabled-text">
                            ----
                          </Row>
                        }
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="pe-0">
                  <Col className="px-0 col-1"></Col>
                  <Col className="col-11 py-0 ">
                    <Row>
                      <Col>
                        <Row className="k-inputText15">
                          Security Group
                        </Row>
                        {securityGroups? 
                          <Row>
                            <Form.Select aria-label="Select a desktop" id="modalDesktopForm.securityGroup" className="k-inputSelect k-inputText18" name='security' onChange={handleInputChange} >
                              <option disabled value="" selected > Select group </option>
                              {securityGroups && securityGroups.map((group: any, index: number) => (<option key={`Groups-${index}`} value={group.id} >{group.name}</option>))}
                            </Form.Select>
                          </Row>
                          :
                          <Row className="disabled-text">
                            ----
                          </Row>
                        }
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  {(projects && profiles) ?  <small style={{color: "orange"}}>Select EITHER Project OR Profile </small>: null}
                </Row>
              </Row>

              
              <Row className="py-3 k-bb">
                <Row>
                  <Col className="px-0 col-1">
                    <img alt="name icon" src={threeIcon} className="k-modalIconSM pe-1"/>
                  </Col>
                  <Col className="k-inputText18 col-11 ps-0">
                    Configure your desktop
                  </Col>
                </Row>
                <Row className="pe-0">
                  <Col className="px-0 col-1"></Col>
                  <Col className="col-11 py-0 ">
                    <Row>
                      <Col>
                        <Row className="k-inputText15 pt-2">
                          Type
                        </Row>
                        <Row>
                          
                          <Col>
                            <Row>
                              <Form.Select aria-label="Select a desktop" id="modalDesktopForm.system" className="k-inputSelect k-inputText18" name='system' onChange={handleInputChange} >
                                <option disabled value="" selected >Select desktop</option>
                                <option value="windows" >Windows</option>
                                <option value="kali">Kali</option>
                                <option value="ubuntu">Ubuntu</option>
                                <option disabled value="macos">MacOS</option>
                              </Form.Select>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row className="k-inputText15 pt-2">
                          Location
                        </Row>
                        <Row>
                          
                          <Col>
                            <Row>
                              <Form.Select aria-label="Select a location" id="modalDesktopForm.location" className="k-inputSelect k-inputText18" name='location' onChange={handleInputChange} >
                                <option disabled value="" selected>Choose location</option>
                                <option value="eu-north-1">Stockholm</option>
                                <option value="eu-west-2">London</option>
                                <option value="ap-southeast-2">Sydney</option>
                                <option value="us-east-2">Ohio</option>
                              </Form.Select>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Row className="k-inputText15 pt-2">
                          Duration
                        </Row>
                        <Row>
                          
                          <Col>
                            <Row>
                              <Form.Select aria-label="Select a duration" id="modalDesktopForm.duration" className="k-inputSelect k-inputText18" name='duration' onChange={handleInputChange} >
                                <option disabled value="" >Select a duration</option>
                                <option selected value="1">Perpetual</option>
                                <option disabled value="2">1 Hour</option>
                                <option disabled value="3">4 Hours</option>
                                <option disabled value="3">24 Hours</option>
                              </Form.Select>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row className="k-inputText15 pt-2">
                          Username
                        </Row>
                        <Row>
                          <Form.Group className="p-0 " controlId="modalDesktopForm.ControlInput6">
                            <Form.Control className="p-0 k-inputText k-inputText18" type="text" placeholder="JaneDoe" name='productUsername' onChange={handleInputChange} />
                          </Form.Group>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  {isProductSystemValid ?  null: <small style={{color: "red"}}>Select your Desktop Type</small>}
                  {isProductLocationValid ?  null : <small style={{color: "red"}}>Select your Desktop Location</small>}
                  {isProductUsernameValid ?  null : <small style={{color: "red"}}>Enter a valid Desktop Username. {"(4 - 15 lowercase alphabets only accepted)"}</small>}
                </Row>
              </Row>

              <Row className="py-3">
                <Row>
                  <Col className="px-0 col-1">
                    <img alt="name icon" src={fourIcon} className="k-modalIconSM pe-1"/>
                  </Col>
                  <Col className="k-inputText18 col-11 ps-0">
                    Launch your desktop
                  </Col>
                </Row>
                <Row className="pe-0">
                  <Col className="px-0 col-1"></Col>
                  <Col className="col-11 p-0 ">
                    <div className="pt-2">
                      <Button variant="secondary" 
                        type="submit"
                        disabled={!isProductNameValid || !isProductDescriptionValid || !isProductLocationValid || !isProductSystemValid || !isProductUsernameValid || submitClicked}
                         size="sm" className="k-btn">
                        Make it so
                      </Button>
                    </div>
                  </Col>
                </Row>
                {submitError? (<>
                  <small style={{color: "red"}}>{submitError}</small>
                </>): null}
              </Row>
            </Container>
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
}



/**
 * Desktop Add Modal
 *  - Renders Form to allow users to add a new desktop
 * @param props 
 * @returns 
 */
const DesktopAddModal: React.FunctionComponent<any> = (props: any) => {
  const [modalFormBody, setModalFormBody] = useState<any>({});
  const [isProductNameValid, setProductNameValid] = useState(false);
  const [isProductUsernameValid, setProductUsernameValid] = useState(false);
  const [isProductDescriptionValid, setProductDescriptionValid] = useState(true);
  const [isProductLocationValid, setProductLocationValid] = useState(false);
  const [isProductSystemValid, setProductSystemValid] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);

  const [isProfileDisabled, setProfileDisabled] = useState(false);
  const [isProjectDisabled, setProjectDisabled] = useState(false);
  

  const [isFormValid, setFormValid] = useState(false);

  const { 
    handleClose, 
    show, 
    handleModalFormSubmit, 
    submitError, 
    linkedProfileId,
    linkedProjectId,
    securityGroups, 
    profiles,
    projects
  } = props;


  useEffect(() => {
    setModalFormBody({
      profile: linkedProfileId,
      project: linkedProjectId
    });
    setProductNameValid(false);
    setProductDescriptionValid(true);
    setProductLocationValid(false);
    setProductSystemValid(false);
    setProductUsernameValid(false);
    setProfileDisabled(false);
    setProjectDisabled(false);
    setSubmitClicked(false);
  }, [show])

  const handleInputChange = (event: any) => {
    if(event.target.name === "name") {
      // event.target.value = event.target.value.split(" ").join("");
      // event.target.value = event.target.value.replace(" ", "");
      if (variables.DEFAULT_FORM_VALIDATION.PRODUCT.NAME.test(event.target.value)) {
        setProductNameValid(true);
      } else {
        setProductNameValid(false);
      }
    };

    if(event.target.name === "productUsername") {
      // event.target.value = event.target.value.split(" ").join("");
      event.target.value = event.target.value.replace(" ", "");
      if (variables.DEFAULT_FORM_VALIDATION.PRODUCT.USERNAME.test(event.target.value)) {
        setProductUsernameValid(true);
      } else {
        setProductUsernameValid(false);
      }
    };

    if(event.target.name === "description") {
      // event.target.value = event.target.value.split(" ").join("");
      // event.target.value = event.target.value.replace(" ", "");
      if (variables.DEFAULT_FORM_VALIDATION.PRODUCT.DESCRIPTION.test(event.target.value) || event.target.value === "") {
        setProductDescriptionValid(true);
      } else {
        setProductDescriptionValid(false);
      }
    };

    if(event.target.name === "location") {
      // event.target.value = event.target.value.split(" ").join("");
      // event.target.value = event.target.value.replace(" ", "");
      if ( event.target.value !== "") {
        setProductLocationValid(true);
      } else {
        setProductLocationValid(false);
      }
    };

    if(event.target.name === "system") {
      if (event.target.value !== "") {
        setProductSystemValid(true);
      } else {
        setProductSystemValid(false);
      }
    };

    switch(event.target.name){
      case "profile":
        if (event.target.value !== "") {
          setProjectDisabled(true);

          setModalFormBody({
            ...helpers.filterObjectByRemovingKeys(modalFormBody, ["project"]),
            [event.target.name]: event.target.value,
          });
        } else if (event.target.value === ""){
          setProjectDisabled(false);

          setModalFormBody({
            ...helpers.filterObjectByRemovingKeys(modalFormBody, ["profile"])
          })
        }
        
        break;

      case "project":
        if (event.target.value !== "") {
          setProfileDisabled(true);

          setModalFormBody({
            ...helpers.filterObjectByRemovingKeys(modalFormBody, ["profile"]),
            [event.target.name]: event.target.value,
          })
        } else if (event.target.value === ""){
          setProfileDisabled(false);

          setModalFormBody({
            ...helpers.filterObjectByRemovingKeys(modalFormBody, ["project"])
          })
        }

        break;
      
      default:
        setModalFormBody({
          ...modalFormBody,
          [event.target.name]: event.target.value,
        });

        break;
    }

    
    if (isProductNameValid && isProductDescriptionValid && isProductLocationValid && isProductSystemValid && isProductUsernameValid){
      setFormValid(true)
    } else {
      setFormValid(false)
    }

  }

  const validateForm = () => {
    console.log("validating form");
    if(modalFormBody["system"] && modalFormBody["system"] !== "" ){
      setProductSystemValid(true)
    } else {
      setProductSystemValid(false)
    }

    if(modalFormBody["location"] && modalFormBody["location"] !== "" ){
      setProductLocationValid(true)
    } else {
      setProductLocationValid(false)
    }
  }

  const handleFormSubmit = async(event: any) => {
    event.preventDefault();

    console.log("attempting aubmit : modal : ", modalFormBody);

    validateForm();

    if (isProductNameValid && isProductDescriptionValid && isProductLocationValid && isProductSystemValid && isProductUsernameValid){
      // setModalFormBody({});
      console.log("DESKTOP ADD MODAL: ", modalFormBody);
      setSubmitClicked(true);
      
      await handleModalFormSubmit({
        productType: variables.DEFAULT_PRODUCT_TYPES.DESKTOP.API_KEY,
        body: {
        ...modalFormBody
      }});

      setSubmitClicked(false);


    } else {
      console.log("FORM NOT VALID... Won't make it so");

    }
  }

  // console.log("DESKTOP ADD MODAL: Linked Profile ID: ", linkedProfileId);
  // console.log("DESKTOP ADD MODAL: Security Groups: ", securityGroups);


  return (
    <>
      <Modal show={show} onHide={handleClose} contentClassName="k-mc-right" dialogClassName="kuro-modal" tabIndex={-1} size="lg" className="k-modal k-m-right" >
        <Form onSubmit={handleFormSubmit}>

          <Row className="k-modalHeader p-0 m-0">
            <img alt="name icon" src={modalDesktopCreateBackground} className="k-modalTitleImg p-0"/>
            <Row className="k-center ps-3">
              <Col className="col-2 p-0">
                <img alt="name icon" src={desktopIcon} className="k-modalIcon pe-1 pt-2"/>
              </Col>
              <Col className="col-10 ps-3 k-pt-2">
                <Row className="k-headerText">Create Desktop</Row>
                <Row className="k-subHeaderText">Configure - Create - Capitalise</Row>
              </Col>
            </Row>
          </Row>
          
          <Modal.Body className="k-modalBody">
            <Container className="pe-0">
              <Row className="">
                <Col className="col-12 pb-3 k-bb">
                    <Row>
                      <Col className="px-0 col-1">
                        <img alt="name icon" src={oneIcon} className="k-modalIconSM pe-1"/>
                      </Col>
                      <Col className="k-inputText18 col-11 ps-0">
                        Enter a name and description
                      </Col>
                    </Row>
                    <Row className="k-inputTextLarge">
                      <Col className="px-0 col-1"></Col>
                      <Col className="col-11 ps-0 ">
                        <Form.Group className="p-0 " controlId="modalDesktopForm.ControlInput1">
                          <Form.Control className="p-0 k-inputText k-inputText22" type="text" placeholder="Desktop Name" name='name'  onChange={handleInputChange}/>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="k-inputTextMedium">
                      <Col className="px-0 col-1"></Col>
                      <Col className="col-11 ps-0 ">
                        <Form.Group className="p-0 " controlId="modalDesktopForm.ControlInput2">
                          <Form.Control className="p-0 k-inputText k-inputText15" type="text" placeholder="Desktop description"  name='description' onChange={handleInputChange} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      {isProductNameValid ?  null: <small style={{color: "red"}}>Enter a valid Desktop Name. {"(5 - 25 Alphabets and Numbers only accepted)"}</small>}
                      {isProductDescriptionValid ?  null : <small style={{color: "red"}}>Enter a valid Desktop Description. {"(Alphabets, Numbers, space and charcaters[-., ()/] accepted)"}</small>}
                    </Row>
                </Col>
              </Row>
              
              <Row className="py-3 k-bb">
                <Row>
                  <Col className="px-0 col-1">
                    <img alt="name icon" src={twoIcon} className="k-modalIconSM pe-1"/>
                  </Col>
                  <Col className="k-inputText18 col-11 ps-0">
                    {(linkedProfileId || linkedProjectId) ? "Select a security group": "Link or share your desktop"}
                  </Col>
                </Row>
                {(linkedProfileId || linkedProjectId) ? "":
                  <Row className="pe-0">
                    <Col className="px-0 col-1"></Col>
                    <Col className="col-11 py-0 ">
                      <Row>
                        <Col>
                          <Row className="k-inputText15">
                            Linked Project
                          </Row>
                          {(projects && !isProjectDisabled)? 
                            <Row>
                              <Form.Select aria-label="Select a project" id="modalDesktopForm.project" className="k-inputSelect k-inputText18" name='project' onChange={handleInputChange} >
                                <option value="" selected > Select project </option>
                                {projects && projects.map((project: any, index: number) => (<option key={`Projects-${index}`} value={project.id} >{project.name}</option>))}
                              </Form.Select>
                            </Row>
                            :
                            <Row className="disabled-text">
                              ----
                            </Row>
                          }
                        </Col>
                        <Col >
                          <Row className="k-inputText15">
                            Linked Profile
                          </Row>
                          {(profiles && !isProfileDisabled)? 
                            <Row>
                              <Form.Select aria-label="Select a profile" id="modalDesktopForm.profile" className="k-inputSelect k-inputText18" name='profile' onChange={handleInputChange} >
                                <option value="" selected > Select profile </option>
                                {profiles && profiles.map((profile: any, index: number) => (<option key={`Groups-${index}`} value={profile.id} >{profile.name}</option>))}
                              </Form.Select>
                            </Row>
                            :
                            <Row className="disabled-text">
                              ----
                            </Row>
                          }
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                }
                <Row className="pe-0">
                  <Col className="px-0 col-1"></Col>
                  <Col className="col-11 py-0 ">
                    <Row>
                      <Col>
                        <Row className="k-inputText15">
                          Security Group
                        </Row>
                        {securityGroups? 
                          <Row>
                            <Form.Select aria-label="Select a group" id="modalDesktopForm.securityGroup" className="k-inputSelect k-inputText18" name='security' onChange={handleInputChange} >
                              <option disabled value="" selected > Select group </option>
                              {securityGroups && securityGroups.map((group: any, index: number) => (<option key={`Groups-${index}`} value={group.id} >{group.name}</option>))}
                            </Form.Select>
                          </Row>
                          :
                          <Row className="disabled-text">
                            ----
                          </Row>
                        }
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  {(projects && profiles) ?  <small style={{color: "orange"}}>Select EITHER Project OR Profile </small>: null}
                </Row>
              </Row>

              
              <Row className="py-3 k-bb">
                <Row>
                  <Col className="px-0 col-1">
                    <img alt="name icon" src={threeIcon} className="k-modalIconSM pe-1"/>
                  </Col>
                  <Col className="k-inputText18 col-11 ps-0">
                    Configure your desktop
                  </Col>
                </Row>
                <Row className="pe-0">
                  <Col className="px-0 col-1"></Col>
                  <Col className="col-11 py-0 ">
                    <Row>
                      <Col>
                        <Row className="k-inputText15 pt-2">
                          Type
                        </Row>
                        <Row>
                          
                          <Col>
                            <Row>
                              <Form.Select aria-label="Select a desktop" id="modalDesktopForm.system" className="k-inputSelect k-inputText18" name='system' onChange={handleInputChange} >
                                <option disabled value="" selected >Select desktop</option>
                                <option value="windows" >Windows</option>
                                <option value="kali">Kali</option>
                                <option value="ubuntu">Ubuntu</option>
                                <option disabled value="macos">MacOS</option>
                              </Form.Select>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row className="k-inputText15 pt-2">
                          Location
                        </Row>
                        <Row>
                          
                          <Col>
                            <Row>
                              <Form.Select aria-label="Select a location" id="modalDesktopForm.location" className="k-inputSelect k-inputText18" name='location' onChange={handleInputChange} >
                                <option disabled value="" selected>Choose location</option>
                                <option value="eu-north-1">Stockholm</option>
                                <option value="eu-west-2">London</option>
                                <option value="ap-southeast-2">Sydney</option>
                                <option value="us-east-2">Ohio</option>
                              </Form.Select>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Row className="k-inputText15 pt-2">
                          Duration
                        </Row>
                        <Row>
                          
                          <Col>
                            <Row>
                              <Form.Select aria-label="Select a duration" id="modalDesktopForm.duration" className="k-inputSelect k-inputText18" name='duration' onChange={handleInputChange} >
                                <option disabled value="" >Select a duration</option>
                                <option selected value="1">Perpetual</option>
                                <option disabled value="2">1 Hour</option>
                                <option disabled value="3">4 Hours</option>
                                <option disabled value="3">24 Hours</option>
                              </Form.Select>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row className="k-inputText15 pt-2">
                          Username
                        </Row>
                        <Row>
                          <Form.Group className="p-0 " controlId="modalDesktopForm.ControlInput6">
                            <Form.Control className="p-0 k-inputText k-inputText18" type="text" placeholder="JaneDoe" name='productUsername' onChange={handleInputChange} />
                          </Form.Group>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  {isProductSystemValid ?  null: <small style={{color: "red"}}>Select your Desktop Type</small>}
                  {isProductLocationValid ?  null : <small style={{color: "red"}}>Select your Desktop Location</small>}
                  {isProductUsernameValid ?  null : <small style={{color: "red"}}>Enter a valid Desktop Username. {"(4 - 15 lowercase alphabets only accepted)"}</small>}
                </Row>
              </Row>

              <Row className="py-3">
                <Row>
                  <Col className="px-0 col-1">
                    <img alt="name icon" src={fourIcon} className="k-modalIconSM pe-1"/>
                  </Col>
                  <Col className="k-inputText18 col-11 ps-0">
                    Launch your desktop
                  </Col>
                </Row>
                <Row className="pe-0">
                  <Col className="px-0 col-1"></Col>
                  <Col className="col-11 p-0 ">
                    <div className="pt-2">
                      <Button variant="secondary" 
                        type="submit"
                        disabled={!isProductNameValid || !isProductDescriptionValid || !isProductLocationValid || !isProductSystemValid || !isProductUsernameValid || submitClicked}
                         size="sm" className="k-btn">
                        Make it so
                      </Button>
                    </div>
                  </Col>
                </Row>
                {submitError? (<>
                  <small style={{color: "red"}}>{submitError}</small>
                </>): null}
              </Row>
            </Container>
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
}


/**
 * Desktop Add Modal
 *  - Renders Form to allow users to add a new desktop
 * @param props 
 * @returns 
 */
const CommunicateAddModal: React.FunctionComponent<any> = (props: any) => {
  const [modalFormBody, setModalFormBody] = useState<any>({});
  const [isProductNameValid, setProductNameValid] = useState(false);
  const [isProductUsernameValid, setProductUsernameValid] = useState(false);
  const [isProductDescriptionValid, setProductDescriptionValid] = useState(true);
  const [isProductLocationValid, setProductLocationValid] = useState(false);
  const [isProductSystemValid, setProductSystemValid] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);

  const [isProfileDisabled, setProfileDisabled] = useState(false);
  const [isProjectDisabled, setProjectDisabled] = useState(false);
  

  const [isFormValid, setFormValid] = useState(false);

  const { 
    handleClose, 
    show, 
    handleModalFormSubmit,
    submitError, 
    linkedProfileId,
    linkedProjectId,
    securityGroups, 
    profiles,
    projects
  } = props;


  useEffect(() => {
    setModalFormBody({
      profile: linkedProfileId,
      project: linkedProjectId
    });
    setProductNameValid(false);
    setProductDescriptionValid(true);
    setProductLocationValid(false);
    setProductSystemValid(false);
    setProductUsernameValid(false);
    setProfileDisabled(false);
    setProjectDisabled(false);
  }, [show])

  const handleInputChange = (event: any) => {
    if(event.target.name === "name") {
      // event.target.value = event.target.value.split(" ").join("");
      // event.target.value = event.target.value.replace(" ", "");
      if (variables.DEFAULT_FORM_VALIDATION.PRODUCT.NAME.test(event.target.value)) {
        setProductNameValid(true);
      } else {
        setProductNameValid(false);
      }
    };

    if(event.target.name === "description") {
      // event.target.value = event.target.value.split(" ").join("");
      // event.target.value = event.target.value.replace(" ", "");
      if (variables.DEFAULT_FORM_VALIDATION.PRODUCT.DESCRIPTION.test(event.target.value) || event.target.value === "") {
        setProductDescriptionValid(true);
      } else {
        setProductDescriptionValid(false);
      }
    };

    if(event.target.name === "location") {
      // event.target.value = event.target.value.split(" ").join("");
      // event.target.value = event.target.value.replace(" ", "");
      if ( event.target.value !== "") {
        setProductLocationValid(true);
      } else {
        setProductLocationValid(false);
      }
    };

    if(event.target.name === "system") {
      if (event.target.value !== "") {
        setProductSystemValid(true);
      } else {
        setProductSystemValid(false);
      }
    };

    switch(event.target.name){
      case "profile":
        if (event.target.value !== "") {
          setProjectDisabled(true);

          setModalFormBody({
            ...helpers.filterObjectByRemovingKeys(modalFormBody, ["project"]),
            [event.target.name]: event.target.value,
          });
        } else if (event.target.value === ""){
          setProjectDisabled(false);

          setModalFormBody({
            ...helpers.filterObjectByRemovingKeys(modalFormBody, ["profile"])
          })
        }
        
        break;

      case "project":
        if (event.target.value !== "") {
          setProfileDisabled(true);

          setModalFormBody({
            ...helpers.filterObjectByRemovingKeys(modalFormBody, ["profile"]),
            [event.target.name]: event.target.value,
          })
        } else if (event.target.value === ""){
          setProfileDisabled(false);

          setModalFormBody({
            ...helpers.filterObjectByRemovingKeys(modalFormBody, ["project"])
          })
        }

        break;
      
      default:
        setModalFormBody({
          ...modalFormBody,
          [event.target.name]: event.target.value,
        });

        break;
    }

    
    if (isProductNameValid && isProductDescriptionValid && isProductLocationValid && isProductSystemValid){
      setFormValid(true)
    } else {
      setFormValid(false)
    }

  }

  const validateForm = () => {
    console.log("validating form");
    if(modalFormBody["system"] && modalFormBody["system"] !== "" ){
      setProductSystemValid(true)
    } else {
      setProductSystemValid(false)
    }

    if(modalFormBody["location"] && modalFormBody["location"] !== "" ){
      setProductLocationValid(true)
    } else {
      setProductLocationValid(false)
    }
  }

  const handleFormSubmit = async(event: any) => {
    event.preventDefault();
    let communicateType = "";

    console.log("attempting submit : modal : ", modalFormBody);

    validateForm();

    if (isProductNameValid && isProductDescriptionValid && isProductLocationValid && isProductSystemValid){
      // setModalFormBody({});
      console.log("Communicate ADD MODAL: ", modalFormBody);

      if (modalFormBody.system === "jitsi") {
        communicateType = variables.DEFAULT_PRODUCT_TYPES.VTC.API_KEY;
      } else if (modalFormBody.system === "rocket.chat"){
        communicateType = variables.DEFAULT_PRODUCT_TYPES.CHAT.API_KEY;
      }

      setSubmitClicked(true);
      
      await handleModalFormSubmit({
        productType: communicateType, 
        body: {
        ...modalFormBody
      }});
      
      setSubmitClicked(false);

    } else {
      console.log("FORM NOT VALID... Won't make it so");
    }
  }

  // console.log("DESKTOP ADD MODAL: Linked Profile ID: ", linkedProfileId);
  // console.log("DESKTOP ADD MODAL: Security Groups: ", securityGroups);


  return (
    <>
      <Modal show={show} onHide={handleClose} contentClassName="k-mc-right" dialogClassName="kuro-modal" tabIndex={-1} size="lg" className="k-modal k-m-right" >
        <Form onSubmit={handleFormSubmit}>

          <Row className="k-modalHeader p-0 m-0">
                <img alt="name icon" src={modalDesktopCreateBackground} className="k-modalTitleImg p-0"/>
                <Row className="k-center ps-3">
                  <Col className="col-2 p-0">
                    <img alt="name icon" src={desktopIcon} className="k-modalIcon pe-1 pt-2"/>
                  </Col>
                  <Col className="col-10 ps-3 k-pt-2">
                    <Row className="k-headerText">Communicate</Row>
                    <Row className="k-subHeaderText">Configure - Create - Communicate</Row>
                  </Col>
                </Row>
          </Row>
          
          <Modal.Body className="k-modalBody">
            <Container className="pe-0">
              <Row className="">
                <Col className="col-12 pb-3 k-bb">
                    <Row>
                      <Col className="px-0 col-1">
                        <img alt="name icon" src={oneIcon} className="k-modalIconSM pe-1"/>
                      </Col>
                      <Col className="k-inputText18 col-11 ps-0">
                        Enter a name and description
                      </Col>
                    </Row>
                    <Row className="k-inputTextLarge">
                      <Col className="px-0 col-1"></Col>
                      <Col className="col-11 ps-0 ">
                        <Form.Group className="p-0 " controlId="modalDesktopForm.ControlInput1">
                          <Form.Control className="p-0 k-inputText k-inputText22" type="text" placeholder="Service Name" name='name'  onChange={handleInputChange}/>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="k-inputTextMedium">
                      <Col className="px-0 col-1"></Col>
                      <Col className="col-11 ps-0 ">
                        <Form.Group className="p-0 " controlId="modalDesktopForm.ControlInput2">
                          <Form.Control className="p-0 k-inputText k-inputText15" type="text" placeholder="Service description"  name='description' onChange={handleInputChange} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      {isProductNameValid ?  null: <small style={{color: "red"}}>Enter a valid Service Name. {"(5 - 25 Alphabets and Numbers only accepted)"}</small>}
                      {isProductDescriptionValid ?  null : <small style={{color: "red"}}>Enter a valid Service Description. {"(Alphabets, Numbers, space and charcaters[-., ()/] accepted)"}</small>}
                    </Row>
                </Col>
              </Row>
              
              <Row className="py-3 k-bb">
                <Row>
                  <Col className="px-0 col-1">
                    <img alt="name icon" src={twoIcon} className="k-modalIconSM pe-1"/>
                  </Col>
                  <Col className="k-inputText18 col-11 ps-0">
                    Link or share your service
                  </Col>
                </Row>
                {(linkedProfileId || linkedProjectId) ? null:
                <Row className="pe-0">
                  <Col className="px-0 col-1"></Col>
                  <Col className="col-11 py-0 ">
                    <Row>
                      <Col>
                        <Row className="k-inputText15">
                          Linked Project
                        </Row>
                        {(projects && !isProjectDisabled)? 
                          <Row>
                            <Form.Select aria-label="Select a project" id="modalDesktopForm.project" className="k-inputSelect k-inputText18" name='project' onChange={handleInputChange} >
                              <option value="" selected > Select project </option>
                              {projects && projects.map((project: any, index: number) => (<option key={`Projects-${index}`} value={project.id} >{project.name}</option>))}
                            </Form.Select>
                          </Row>
                          :
                          <Row className="disabled-text">
                            ----
                          </Row>
                        }
                      </Col>
                      <Col >
                        <Row className="k-inputText15">
                          Linked Profile
                        </Row>
                        {(profiles && !isProfileDisabled)? 
                          <Row>
                            <Form.Select aria-label="Select a profile" id="modalDesktopForm.profile" className="k-inputSelect k-inputText18" name='profile' onChange={handleInputChange} >
                              <option value="" selected > Select profile </option>
                              {profiles && profiles.map((profile: any, index: number) => (<option key={`Groups-${index}`} value={profile.id} >{profile.name}</option>))}
                            </Form.Select>
                          </Row>
                          :
                          <Row className="disabled-text">
                            ----
                          </Row>
                        }
                      </Col>
                    </Row>
                  </Col>
                </Row>
                }
                <Row className="pe-0">
                  <Col className="px-0 col-1"></Col>
                  <Col className="col-11 py-0 ">
                    <Row>
                      <Col>
                        <Row className="k-inputText15">
                          Security Group
                        </Row>
                        {securityGroups? 
                          <Row>
                            <Form.Select aria-label="Select a group" id="modalDesktopForm.securityGroup" className="k-inputSelect k-inputText18" name='security' onChange={handleInputChange} >
                              <option disabled value="" selected > Select group </option>
                              {securityGroups && securityGroups.map((group: any, index: number) => (<option key={`Groups-${index}`} value={group.id} >{group.name}</option>))}
                            </Form.Select>
                          </Row>
                          :
                          <Row className="disabled-text">
                            ----
                          </Row>
                        }
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  {(projects && profiles) ?  <small style={{color: "orange"}}>Select EITHER Project OR Profile </small>: null}
                </Row>
              </Row>

              
              <Row className="py-3 k-bb">
                <Row>
                  <Col className="px-0 col-1">
                    <img alt="name icon" src={threeIcon} className="k-modalIconSM pe-1"/>
                  </Col>
                  <Col className="k-inputText18 col-11 ps-0">
                    Configure your service
                  </Col>
                </Row>
                <Row className="pe-0">
                  <Col className="px-0 col-1"></Col>
                  <Col className="col-11 py-0 ">
                    <Row>
                      <Col>
                        <Row className="k-inputText15 pt-2">
                          Type
                        </Row>
                        <Row>
                          
                          <Col>
                            <Row>
                              <Form.Select aria-label="Select a desktop" id="modalDesktopForm.system" className="k-inputSelect k-inputText18" name='system' onChange={handleInputChange} >
                                <option disabled value="" selected >Select service</option>
                                <option value="jitsi" >Jitsi</option>
                                <option value="rocket.chat">RocketChat</option>
                              </Form.Select>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row className="k-inputText15 pt-2">
                          Location
                        </Row>
                        <Row>
                          
                          <Col>
                            <Row>
                              <Form.Select aria-label="Select a location" id="modalDesktopForm.location" className="k-inputSelect k-inputText18" name='location' onChange={handleInputChange} >
                                <option disabled value="" selected>Choose location</option>
                                <option value="eu-north-1">Stockholm</option>
                                <option value="eu-west-2">London</option>
                                <option value="ap-southeast-2">Sydney</option>
                                <option value="us-east-2">Ohio</option>
                              </Form.Select>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Row className="k-inputText15 pt-2">
                          Duration
                        </Row>
                        <Row>
                          
                          <Col>
                            <Row>
                              <Form.Select aria-label="Select a duration" id="modalDesktopForm.duration" className="k-inputSelect k-inputText18" name='duration' onChange={handleInputChange} >
                                <option disabled value="" >Select a duration</option>
                                <option selected value="1">Perpetual</option>
                                <option disabled value="2">1 Hour</option>
                                <option disabled value="3">4 Hours</option>
                                <option disabled value="3">24 Hours</option>
                              </Form.Select>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  {isProductSystemValid ?  null: <small style={{color: "red"}}>Select your Service Type</small>}
                  {isProductLocationValid ?  null : <small style={{color: "red"}}>Select your Service Location</small>}
                </Row>
              </Row>

              <Row className="py-3">
                <Row>
                  <Col className="px-0 col-1">
                    <img alt="name icon" src={fourIcon} className="k-modalIconSM pe-1"/>
                  </Col>
                  <Col className="k-inputText18 col-11 ps-0">
                    Launch your service
                  </Col>
                </Row>
                <Row className="pe-0">
                  <Col className="px-0 col-1"></Col>
                  <Col className="col-11 p-0 ">
                    <div className="pt-2">
                      <Button variant="secondary" 
                        type="submit"
                        disabled={!isProductNameValid || !isProductDescriptionValid || !isProductLocationValid || !isProductSystemValid || submitClicked}
                         size="sm" className="k-btn">
                        Make it so
                      </Button>
                    </div>
                  </Col>
                </Row>
                {submitError? (<>
                  <small style={{color: "red"}}>{submitError}</small>
                </>): null}
              </Row>
            </Container>
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
}



/**
 * Browser Add Modal
 *  - Renders Form to allow users to add a new browser
 * @param props 
 * @returns 
 */
const BrowserAddModal: React.FunctionComponent<any> = (props: any) => {
  const [modalFormBody, setModalFormBody] = useState<any>({});
  const [isProductNameValid, setProductNameValid] = useState(false);
  // const [isProductUsernameValid, setProductUsernameValid] = useState(false);
  const [isProductDescriptionValid, setProductDescriptionValid] = useState(true);
  const [isProductLocationValid, setProductLocationValid] = useState(false);
  const [isProductSystemValid, setProductSystemValid] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  
  const [isProfileDisabled, setProfileDisabled] = useState(false);
  const [isProjectDisabled, setProjectDisabled] = useState(false);

  const [isFormValid, setFormValid] = useState(false);

  const { 
    handleClose, 
    show, 
    handleModalFormSubmit, 
    submitError, 
    linkedProfileId,
    linkedProjectId,
    securityGroups,
    projects,
    profiles 
  } = props;

  
  useEffect(() => {
    setModalFormBody({
      profile: linkedProfileId,
      project: linkedProjectId
    });
    setProductNameValid(false);
    setProductDescriptionValid(true);
    setProductLocationValid(false);
    setProductSystemValid(false);
    // setProductUsernameValid(false);
  }, [show])

  const handleInputChange = (event: any) => {
    if(event.target.name === "name") {
      // event.target.value = event.target.value.split(" ").join("");
      //event.target.value = event.target.value.replace(" ", "");
      if (variables.DEFAULT_FORM_VALIDATION.PRODUCT.NAME.test(event.target.value)) {
        setProductNameValid(true);
      } else {
        setProductNameValid(false);
      }
    };

    // if(event.target.name === "productUsername") {
    //   // event.target.value = event.target.value.split(" ").join("");
    //   event.target.value = event.target.value.replace(" ", "");
    //   if (validProductUsernameExpression.test(event.target.value)) {
    //     setProductUsernameValid(true);
    //   } else {
    //     setProductUsernameValid(false);
    //   }
    // };

    if(event.target.name === "description") {
      // event.target.value = event.target.value.split(" ").join("");
      // event.target.value = event.target.value.replace(" ", "");
      if (variables.DEFAULT_FORM_VALIDATION.PRODUCT.DESCRIPTION.test(event.target.value) || event.target.value === "") {
        setProductDescriptionValid(true);
      } else {
        setProductDescriptionValid(false);
      }
    };

    if(event.target.name === "location") {
      // event.target.value = event.target.value.split(" ").join("");
      // event.target.value = event.target.value.replace(" ", "");
      if ( event.target.value !== "") {
        setProductLocationValid(true);
      } else {
        setProductLocationValid(false);
      }
    };

    if(event.target.name === "system") {
      if (event.target.value !== "") {
        setProductSystemValid(true);
      } else {
        setProductSystemValid(false);
      }
    };

    switch(event.target.name){
      case "profile":
        if (event.target.value !== "") {
          setProjectDisabled(true);

          setModalFormBody({
            ...helpers.filterObjectByRemovingKeys(modalFormBody, ["project"]),
            [event.target.name]: event.target.value,
          });
        } else if (event.target.value === ""){
          setProjectDisabled(false);

          setModalFormBody({
            ...helpers.filterObjectByRemovingKeys(modalFormBody, ["profile"])
          })
        }
        
        break;

      case "project":
        if (event.target.value !== "") {
          setProfileDisabled(true);

          setModalFormBody({
            ...helpers.filterObjectByRemovingKeys(modalFormBody, ["profile"]),
            [event.target.name]: event.target.value,
          })
        } else if (event.target.value === ""){
          setProfileDisabled(false);

          setModalFormBody({
            ...helpers.filterObjectByRemovingKeys(modalFormBody, ["project"])
          })
        }

        break;
      
      default:
        setModalFormBody({
          ...modalFormBody,
          [event.target.name]: event.target.value,
        });

        break;
    }


    if (isProductNameValid && isProductDescriptionValid && isProductLocationValid && isProductSystemValid ){
      setFormValid(true)
    } else {
      setFormValid(false)
    }

  }

  const validateForm = () => {
    if(modalFormBody["system"] && modalFormBody["system"] !== "" ){
      setProductSystemValid(true)
    } else {
      setProductSystemValid(false)
    }

    if(modalFormBody["location"] && modalFormBody["location"] !== "" ){
      setProductLocationValid(true)
    } else {
      setProductLocationValid(false)
    }
  }

  const handleFormSubmit = async(event: any) => {
    event.preventDefault();

    validateForm();

    if (isProductNameValid && isProductDescriptionValid && isProductLocationValid && isProductSystemValid ){
      // setModalFormBody({});
      console.log("BROWSER ADD MODAL: ", modalFormBody);

      setSubmitClicked(true);
      await handleModalFormSubmit({
        productType: variables.DEFAULT_PRODUCT_TYPES.BROWSER.API_KEY,
        body: {
        ...modalFormBody
      }})
      setSubmitClicked(false);

    } else {
      console.log("FORM NOT VALID... Won't make it so");
    }
  }

  // console.log("BROWSER ADD MODAL: Linked Profile ID: ", linkedProfileId);
  // console.log("BROWSER ADD MODAL: Security Groups: ", securityGroups);


  return (
    <>
      <Modal show={show} onHide={handleClose} contentClassName="k-mc-right" dialogClassName="kuro-modal" tabIndex={-1} size="lg" className="k-modal k-m-right" >
        <Form onSubmit={handleFormSubmit}>

          <Row className="k-modalHeader p-0 m-0">
                <img alt="name icon" src={modalBrowserCreateBackground} className="k-modalTitleImg p-0"/>
                <Row className="k-center ps-3">
                  <Col className="col-2 p-0">
                    <img alt="name icon" src={modalBrowserCreateHeaderIcon} className="k-modalIcon pe-1 pt-2"/>
                  </Col>
                  <Col className="col-10 ps-3 k-pt-2">
                    <Row className="k-headerText">Create Browser</Row>
                    <Row className="k-subHeaderText">Configure - Create - Browse</Row>
                  </Col>
                </Row>
          </Row>
          
          <Modal.Body className="k-modalBody">
            <Container className="pe-0">
              <Row className="">
                <Col className="col-12 pb-3 k-bb">
                    <Row>
                      <Col className="px-0 col-1">
                        <img alt="name icon" src={oneIcon} className="k-modalIconSM pe-1"/>
                      </Col>
                      <Col className="k-inputText18 col-11 ps-0">
                        Enter a name and description
                      </Col>
                    </Row>
                    <Row className="k-inputTextLarge">
                      <Col className="px-0 col-1"></Col>
                      <Col className="col-11 ps-0 ">
                        <Form.Group className="p-0 " controlId="modalBrowserForm.ControlInput1">
                          <Form.Control className="p-0 k-inputText k-inputText22" type="text" placeholder="Browser Name" name='name'  onChange={handleInputChange}/>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="k-inputTextMedium">
                      <Col className="px-0 col-1"></Col>
                      <Col className="col-11 ps-0 ">
                        <Form.Group className="p-0 " controlId="modalBrowserForm.ControlInput2">
                          <Form.Control className="p-0 k-inputText k-inputText15" type="text" placeholder="Browser description"  name='description' onChange={handleInputChange} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      {isProductNameValid ?  null: <small style={{color: "red"}}>Enter a valid Browser Name. {"(5 - 25 Alphabets and Numbers only accepted)"}</small>}
                      {isProductDescriptionValid ?  null : <small style={{color: "red"}}>Enter a valid Browser Description. {"(Alphabets, Numbers, space and charcaters[-., ()/] accepted)"}</small>}
                    </Row>
                </Col>
              </Row>
              
              <Row className="py-3 k-bb">
                <Row>
                  <Col className="px-0 col-1">
                    <img alt="name icon" src={twoIcon} className="k-modalIconSM pe-1"/>
                  </Col>
                  <Col className="k-inputText18 col-11 ps-0">
                    {(linkedProfileId || linkedProjectId) ? "Select a security group": "Link or share your browser"}
                  </Col>
                </Row>
                {(linkedProfileId || linkedProjectId) ? "":
                  <Row className="pe-0">
                    <Col className="px-0 col-1"></Col>
                    <Col className="col-11 py-0 ">
                      <Row>
                        <Col>
                          <Row className="k-inputText15">
                            Linked Project
                          </Row>
                          {/* <Row className="disabled-text">
                            ----
                          </Row> */}
                          {(projects && !isProjectDisabled)? 
                            <Row>
                              <Form.Select aria-label="Select a project" id="modalBrowserForm.project" className="k-inputSelect k-inputText18" name='project' onChange={handleInputChange} >
                                <option value="" selected > Select project </option>
                                {projects && projects.map((project: any, index: number) => (<option key={`Projects-${index}`} value={project.id} >{project.name}</option>))}
                              </Form.Select>
                            </Row>
                            :
                            <Row className="disabled-text">
                              ----
                            </Row>
                          }
                        </Col>
                        <Col >
                          <Row className="k-inputText15">
                            Linked Profile
                          </Row>
                          {(profiles && !isProfileDisabled)? 
                            <Row>
                              <Form.Select aria-label="Select a profile" id="modalBrowserForm.profile" className="k-inputSelect k-inputText18" name='profile' onChange={handleInputChange} >
                                <option value="" selected > Select profile </option>
                                {profiles && profiles.map((profile: any, index: number) => (<option key={`Profiles-${index}`} value={profile.id} >{profile.name}</option>))}
                              </Form.Select>
                            </Row>
                            :
                            <Row className="disabled-text">
                              ----
                            </Row>
                          }
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                }
                <Row className="pe-0">
                  <Col className="px-0 col-1"></Col>
                  <Col className="col-11 py-0 ">
                    <Row>
                      <Col>
                        <Row className="k-inputText15">
                          Security Group
                        </Row>
                        {securityGroups? 
                          <Row>
                            <Form.Select aria-label="Select a group" id="modalBrowserForm.securityGroup" className="k-inputSelect k-inputText18" name='security' onChange={handleInputChange} >
                              <option disabled value="" selected > Select group </option>
                              {securityGroups && securityGroups.map((group: any, index: number) => (<option key={`Groups-${index}`} value={group.id} >{group.name}</option>))}
                            </Form.Select>
                          </Row>
                          :
                          <Row className="disabled-text">
                            ----
                          </Row>
                        }
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  {(projects && profiles) ?  <small style={{color: "orange"}}>Select EITHER Project OR Profile </small>: null}
                </Row>
              </Row>

              
              <Row className="py-3 k-bb">
                <Row>
                  <Col className="px-0 col-1">
                    <img alt="name icon" src={threeIcon} className="k-modalIconSM pe-1"/>
                  </Col>
                  <Col className="k-inputText18 col-11 ps-0">
                    Configure your browser
                  </Col>
                </Row>
                <Row className="pe-0">
                  <Col className="px-0 col-1"></Col>
                  <Col className="col-11 py-0 ">
                    <Row>
                      <Col>
                        <Row className="k-inputText15 pt-2">
                          Type
                        </Row>
                        <Row>
                          
                          <Col>
                            <Row>
                              <Form.Select aria-label="Select a browser" id="modalBrowserForm.system" className="k-inputSelect k-inputText18" name='system' onChange={handleInputChange} >
                                <option disabled value="" selected >Select browser</option>
                                <option value="edge" >MS Edge</option>
                                {/* <option disabled value="ubuntu">Ubuntu</option>
                                <option disabled value="macos">MacOS</option> */}
                              </Form.Select>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row className="k-inputText15 pt-2">
                          Location
                        </Row>
                        <Row>
                          
                          <Col>
                            <Row>
                              <Form.Select aria-label="Select a location" id="modalBrowserForm.location" className="k-inputSelect k-inputText18" name='location' onChange={handleInputChange} >
                                <option disabled value="" selected>Choose location</option>
                                <option value="eu-north-1">Stockholm</option>
                                <option value="eu-west-2">London</option>
                                <option value="ap-southeast-2">Sydney</option>
                                <option value="us-east-2">Ohio</option>
                              </Form.Select>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Row className="k-inputText15 pt-2">
                          Duration
                        </Row>
                        <Row>
                          
                          <Col>
                            <Row>
                              <Form.Select aria-label="Select duration" id="modalBrowserForm.duration" className="k-inputSelect k-inputText18" name='duration' onChange={handleInputChange} >
                                <option disabled value="" >Select a duration</option>
                                <option selected value="1">Perpetual</option>
                                <option disabled value="2">1 Hour</option>
                                <option disabled value="3">4 Hours</option>
                                <option disabled value="3">24 Hours</option>
                              </Form.Select>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      {/* <Col>
                        <Row className="k-inputText15 pt-2">
                          Username
                        </Row>
                        <Row>
                          <Form.Group className="p-0 " controlId="modalBrowserForm.ControlInput6">
                            <Form.Control className="p-0 k-inputText k-inputText18" type="text" placeholder="JaneDoe" name='productUsername' onChange={handleInputChange} />
                          </Form.Group>
                        </Row>
                      </Col> */}
                    </Row>
                  </Col>
                </Row>
                <Row>
                  {isProductSystemValid ?  null: <small style={{color: "red"}}>Select your Browser Type</small>}
                  {isProductLocationValid ?  null : <small style={{color: "red"}}>Select your Browser's Location</small>}
                  {/* {isProductUsernameValid ?  null : <small style={{color: "red"}}>Enter a valid Browser Username. {"(4 - 15 lowercase alphabets only accepted)"}</small>} */}
                </Row>
              </Row>

              <Row className="py-3">
                <Row>
                  <Col className="px-0 col-1">
                    <img alt="name icon" src={fourIcon} className="k-modalIconSM pe-1"/>
                  </Col>
                  <Col className="k-inputText18 col-11 ps-0">
                    Launch your browser
                  </Col>
                </Row>
                <Row className="pe-0">
                  <Col className="px-0 col-1"></Col>
                  <Col className="col-11 p-0 ">
                    <div className="pt-2">
                      <Button variant="secondary" 
                        type="submit"
                        disabled={!isProductNameValid || !isProductDescriptionValid || !isProductLocationValid || !isProductSystemValid || submitClicked}
                         size="sm" className="k-btn">
                        Make it so
                      </Button>
                    </div>
                  </Col>
                </Row>
                {submitError? (<>
                  <small style={{color: "red"}}>{submitError}</small>
                </>): null}
              </Row>
            </Container>
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
}



/**
 * Profile Add Modal
 *  - Renders Form to allow users to add a new profile
 * @param props 
 * @returns 
 */
const ProfileAddModal: React.FunctionComponent<any> = (props: any) => {
  const { 
    handleClose, 
    show, 
    handleModalFormSubmit, 
    submitError,
    groups,
    projects,
    linkedProjectId, 
  } = props;

  const [modalFormBody, setModalFormBody] = useState<any>({});
  const [profileCustomCharcateristics, setProfileCustomCharcateristics] = useState<any>([{customKey: "", customValue: ""}]);
  const [isProfileNameValid, setProfileNameValid] = useState(false);
  const [isProfileDescriptionValid, setProfileDescriptionValid] = useState(true);
  const [submitClicked, setSubmitClicked] = useState(false);

  const [isFormValid, setFormValid] = useState(false);

  
  useEffect(() => {
    setModalFormBody({
      project: linkedProjectId
    });
    setProfileNameValid(false);
    setProfileDescriptionValid(true);
    setProfileCustomCharcateristics([{customKey: "", customValue: ""}])
  }, [show])

  const handleInputChange = (event: any) => {
    if(event.target.name === "name") {
      // event.target.value = event.target.value.split(" ").join("");
      // event.target.value = event.target.value.replace(" ", "");
      if (variables.DEFAULT_FORM_VALIDATION.PROFILE.NAME.test(event.target.value)) {
        setProfileNameValid(true);
      } else {
        setProfileNameValid(false);
      }
    };

    if(event.target.name === "description") {
      // event.target.value = event.target.value.split(" ").join("");
      // event.target.value = event.target.value.replace(" ", "");
      if (variables.DEFAULT_FORM_VALIDATION.PROFILE.DESCRIPTION.test(event.target.value) || event.target.value === "") {
        setProfileDescriptionValid(true);
      } else {
        setProfileDescriptionValid(false);
      }
    };

    setModalFormBody({
      ...modalFormBody,
      [event.target.name]: event.target.value,
    });


    if (isProfileNameValid && isProfileDescriptionValid){
      setFormValid(true)
    } else {
      setFormValid(false)
    }

  }

  const addProfileCustomCharacteristicRow = (event: any) => {
    event.preventDefault();

    setProfileCustomCharcateristics([
      ...profileCustomCharcateristics,
      {key: "", value: ""}
    ])
  }

  const removeProfileCustomCharacteristicRow = (event: any, itemIndex: number) => {
    event.preventDefault();

    let customRows: any = [];

    for(let count = 0; count < profileCustomCharcateristics.length; count ++){

      if(count !== itemIndex){
        customRows.push(profileCustomCharcateristics[count])
      }
    }
      
    setProfileCustomCharcateristics([...customRows]);
    
  }

  const handleCustomCharcateristicsInputChange = (event: any, i: number) => {
    event.preventDefault();

    let customValues = [...profileCustomCharcateristics];
    customValues[i][event.target.name] = event.target.value;
    
    setProfileCustomCharcateristics([...customValues]);
  }


  const validateForm = () => {
    // if(modalFormBody["system"] && modalFormBody["system"] !== "" ){
    //   setProductSystemValid(true)
    // } else {
    //   setProductSystemValid(false)
    // }

    // if(modalFormBody["location"] && modalFormBody["location"] !== "" ){
    //   setProductLocationValid(true)
    // } else {
    //   setProductLocationValid(false)
    // }
    return
  }

  const handleFormSubmit = async(event: any) => {
    event.preventDefault();

    validateForm();

    if (isProfileNameValid ){
      console.log("PROFILE ADD MODAL: BODY: ", modalFormBody);
      
      setSubmitClicked(true);
      await handleModalFormSubmit({body: {
        ...modalFormBody
      }})
      setSubmitClicked(false);

    } else {
      console.log("FORM NOT VALID... Won't make it so");
    }
  }


  return (
    <>
      <Modal show={show} onHide={handleClose} contentClassName="k-mc-right" dialogClassName="kuro-modal" tabIndex={-1} size="lg" className="k-modal k-m-right" >
        <Form onSubmit={handleFormSubmit}>

          <Row className="k-modalHeader p-0 m-0">
                <img alt="name icon" src={modalDesktopCreateBackground} className="k-modalTitleImg p-0"/>
                <Row className="k-center ps-3">
                  <Col className="col-2 p-0">
                    <img alt="name icon" src={desktopIcon} className="k-modalIcon pe-1 pt-2"/>
                  </Col>
                  <Col className="col-10 ps-3 k-pt-2">
                    <Row className="k-headerText">Create Profile</Row>
                    <Row className="k-subHeaderText">Configure - Create - Profile</Row>
                  </Col>
                </Row>
          </Row>
          
          <Modal.Body className="k-modalBody">
            <Container className="pe-0">
              <Row className="">
                <Col className="col-12 pb-3 k-bb">
                    <Row>
                      <Col className="px-0 col-1">
                        <img alt="name icon" src={oneIcon} className="k-modalIconSM pe-1"/>
                      </Col>
                      <Col className="k-inputText18 col-11 ps-0">
                        Enter a name and description
                      </Col>
                    </Row>
                    <Row className="k-inputTextLarge">
                      <Col className="px-0 col-1"></Col>
                      <Col className="col-11 ps-0 ">
                        <Form.Group className="p-0 " controlId="modalProfileForm.ControlInput1">
                          <Form.Control className="p-0 k-inputText k-inputText22" type="text" placeholder="Profile Name" name='name'  onChange={handleInputChange}/>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="k-inputTextMedium">
                      <Col className="px-0 col-1"></Col>
                      <Col className="col-11 ps-0 ">
                        <Form.Group className="p-0 " controlId="modalProfileForm.ControlInput2">
                          <Form.Control className="p-0 k-inputText k-inputText15" type="text" placeholder="Profile description"  name='description' onChange={handleInputChange} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      {isProfileNameValid ?  null: <small style={{color: "red"}}>Enter a valid Profile Name. {"(5 - 25 Alphabets and Numbers only accepted)"}</small>}
                      {isProfileDescriptionValid ?  null : <small style={{color: "red"}}>Enter a valid Profile Description. {"(Alphabets, Numbers, space and charcaters[-., ()/] accepted)"}</small>}
                    </Row>
                </Col>
              </Row>
              
              <Row className="py-3 k-bb">
                <Row>
                  <Col className="px-0 col-1">
                    <img alt="name icon" src={twoIcon} className="k-modalIconSM pe-1"/>
                  </Col>
                  <Col className="k-inputText18 col-11 ps-0">
                    Link or share your profile
                  </Col>
                </Row>
                <Row className="pe-0">
                  <Col className="px-0 col-1"></Col>
                  <Col className="col-11 py-0 ">
                    <Row>
                      {(linkedProjectId) ? null:
                      <Col>
                        <Row className="k-inputText15">
                          Linked Project
                        </Row>
                        {projects ? 
                          <Row>
                            <Form.Select aria-label="Select a group" id="modalProfileForm.securityGroup" className="k-inputSelect k-inputText18" name='project' onChange={handleInputChange} >
                              <option disabled value="" selected > Select project </option>
                              {projects && projects.map((project: any, index: number) => (<option key={`Groups-${index}`} value={project.id} >{project.name}</option>))}
                            </Form.Select>
                          </Row>
                          :
                          <Row className="disabled-text">
                            ----
                          </Row>
                        }
                      </Col>
                      }
                      <Col >
                        <Row className="k-inputText15">
                          Security Group
                        </Row>
                        {groups ? 
                          <Row>
                            <Form.Select aria-label="Select a group" id="modalProfileForm.securityGroup" className="k-inputSelect k-inputText18" name='security' onChange={handleInputChange} >
                              <option disabled value="" selected > Select group </option>
                              {groups && groups.map((group: any, index: number) => (<option key={`Groups-${index}`} value={group.id} >{group.name}</option>))}
                            </Form.Select>
                          </Row>
                          :
                          <Row className="disabled-text">
                            ----
                          </Row>
                        }
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Row>

              
              {/*<Row className="py-3 k-bb">
                <Row>
                  <Col className="px-0 col-1">
                    <img alt="name icon" src={threeIcon} className="k-modalIconSM pe-1"/>
                  </Col>
                  <Col className="k-inputText18 col-11 ps-0">
                    Add a characteristic
                  </Col>
                </Row>
                
                <Row className="pe-0">
                  <Col className="px-0 col-1"></Col>
                  <Col className="col-11 py-0 ">
                    <Row>
                      <Row >
                        <Col className="k-inputText15 pt-2">
                          Name
                        </Col>
                        <Col className="k-inputText15 pt-2">
                          Value
                        </Col>
                      </Row>

                      {profileCustomCharcateristics && profileCustomCharcateristics.map((item: any, index: number) => {

                        return(
                          <Row key={`modalProfileCustomCharacteristics.Item-${index}`}>
                            <Col className="col-10" >
                              <Row>
                                <Col className="k-inputTextLarge col-5">
                                  <Form.Group className="p-0 " controlId={`modalProfileCustomCharacteristics.Key-${index}`}>
                                    <Form.Control className="p-0 k-inputText k-inputText15" type="text" placeholder="Enter key" name='customKey'   onChange={(event: any) => handleCustomCharcateristicsInputChange(event, index)} value={item.customKey || ""}/>
                                  </Form.Group>
                                </Col>
                                <Col className="k-inputText15 col-5">
                                  <Form.Group className="p-0 " controlId={`modalProfileCustomCharacteristics.Value-${index}`}>
                                    <Form.Control className="p-0 k-inputText k-inputText15" type="text" placeholder="Enter value" name='customValue'  onChange={(event: any) => handleCustomCharcateristicsInputChange(event, index)} value={item.customValue || ""}/>
                                  </Form.Group>
                                </Col>
                              </Row>
                            </Col>
                            <Col className="col-2" >
                              <img alt="name icon" style={{ cursor: "pointer"}} onClick={(event: any) => removeProfileCustomCharacteristicRow(event, index)} src={resourceAddIcon} className="k-modalIconSM pe-1"/>
                            </Col>
                          </Row>
                        )

                      })}
                    </Row>

                    <Row className="pe-0">
                      <Col className="px-0 col-1"></Col>
                      <Col className="col-11 p-0 ">
                        <Row style={{ cursor: "pointer"}} onClick={addProfileCustomCharacteristicRow}>
                          <Col className="px-0 col-1">
                            <img alt="name icon" src={resourceAddIcon} className="k-modalIconSM pe-1"/>
                          </Col>
                          <Col className="col-11 p-0 ">
                            Add another characteristic
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                  </Col>
                </Row>
              </Row>
              */}
              <Row className="py-3">
                <Row>
                  <Col className="px-0 col-1">
                    <img alt="name icon" src={threeIcon} className="k-modalIconSM pe-1"/>
                  </Col>
                  <Col className="k-inputText18 col-11 ps-0">
                    Create your profile
                  </Col>
                </Row>
                <Row className="pe-0">
                  <Col className="px-0 col-1"></Col>
                  <Col className="col-11 p-0 ">
                    <div className="pt-2">
                      <Button variant="secondary" 
                        type="submit"
                        disabled={!isProfileNameValid || !isProfileDescriptionValid || submitClicked}
                         size="sm" className="k-btn">
                        Make it so
                      </Button>
                    </div>
                  </Col>
                </Row>
                {submitError? (<>
                  <small style={{color: "red"}}>{submitError}</small>
                </>): null}
              </Row>
            </Container>
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
}



/**
 * Project Add Modal
 *  - Renders Form to allow users to add a new project
 * @param props 
 * @returns 
 */
const ProjectAddModal: React.FunctionComponent<any> = (props: any) => {
  const { handleClose, show, groups, handleModalFormSubmit, submitError } = props;
  const [modalFormBody, setModalFormBody] = useState<any>({});
  // const [profileCustomCharcateristics, setProfileCustomCharcateristics] = useState<any>([{customKey: "", customValue: ""}]);
  const [isProjectNameValid, setProjectNameValid] = useState(false);
  const [isProjectDescriptionValid, setProjectDescriptionValid] = useState(true);
  const [submitClicked, setSubmitClicked] = useState(false);

  const [isFormValid, setFormValid] = useState(false);

  
  useEffect(() => {
    setModalFormBody({});
    setProjectNameValid(false);
    setProjectDescriptionValid(true);
    // setProfileCustomCharcateristics([{customKey: "", customValue: ""}])
  }, [show])

  const handleInputChange = (event: any) => {
    if(event.target.name === "name") {
      // event.target.value = event.target.value.split(" ").join("");
      // event.target.value = event.target.value.replace(" ", "");
      if (variables.DEFAULT_FORM_VALIDATION.PROJECT.NAME.test(event.target.value)) {
        setProjectNameValid(true);
      } else {
        setProjectNameValid(false);
      }
    };

    if(event.target.name === "description") {
      // event.target.value = event.target.value.split(" ").join("");
      // event.target.value = event.target.value.replace(" ", "");
      if (variables.DEFAULT_FORM_VALIDATION.PROJECT.DESCRIPTION.test(event.target.value) || event.target.value === "") {
        setProjectDescriptionValid(true);
      } else {
        setProjectDescriptionValid(false);
      }
    };

    setModalFormBody({
      ...modalFormBody,
      [event.target.name]: event.target.value,
    });


    if (isProjectNameValid && isProjectDescriptionValid){
      setFormValid(true)
    } else {
      setFormValid(false)
    }

  }

  // const addProfileCustomCharacteristicRow = (event: any) => {
  //   event.preventDefault();

  //   setProfileCustomCharcateristics([
  //     ...profileCustomCharcateristics,
  //     {key: "", value: ""}
  //   ])
  // }

  // const removeProfileCustomCharacteristicRow = (event: any, itemIndex: number) => {
  //   event.preventDefault();

  //   let customRows: any = [];

  //   for(let count = 0; count < profileCustomCharcateristics.length; count ++){

  //     if(count !== itemIndex){
  //       customRows.push(profileCustomCharcateristics[count])
  //     }
  //   }
      
  //   setProfileCustomCharcateristics([...customRows]);
    
  // }

  // const handleCustomCharcateristicsInputChange = (event: any, i: number) => {
  //   event.preventDefault();

  //   let customValues = [...profileCustomCharcateristics];
  //   customValues[i][event.target.name] = event.target.value;
    
  //   setProfileCustomCharcateristics([...customValues]);
  // }


  const validateForm = () => {
    // if(modalFormBody["system"] && modalFormBody["system"] !== "" ){
    //   setProductSystemValid(true)
    // } else {
    //   setProductSystemValid(false)
    // }

    // if(modalFormBody["location"] && modalFormBody["location"] !== "" ){
    //   setProductLocationValid(true)
    // } else {
    //   setProductLocationValid(false)
    // }
    return
  }

  const handleFormSubmit = async(event: any) => {
    event.preventDefault();

    validateForm();

    if (isProjectNameValid ){

      setSubmitClicked(true);
      await handleModalFormSubmit({body: {
        ...modalFormBody
      }})
      setSubmitClicked(false);

    } else {
      console.log("FORM NOT VALID... Won't make it so");
    }
  }


  return (
    <>
      <Modal show={show} onHide={handleClose} contentClassName="k-mc-right" dialogClassName="kuro-modal" tabIndex={-1} size="lg" className="k-modal k-m-right" >
        <Form onSubmit={handleFormSubmit}>

          <Row className="k-modalHeader p-0 m-0">
                <img alt="name icon" src={modalDesktopCreateBackground} className="k-modalTitleImg p-0"/>
                <Row className="k-center ps-3">
                  <Col className="col-2 p-0">
                    <img alt="name icon" src={desktopIcon} className="k-modalIcon pe-1 pt-2"/>
                  </Col>
                  <Col className="col-10 ps-3 k-pt-2">
                    <Row className="k-headerText">Create Project</Row>
                    <Row className="k-subHeaderText">Configure - Create - Project</Row>
                  </Col>
                </Row>
          </Row>
          
          <Modal.Body className="k-modalBody">
            <Container className="pe-0">
              <Row className="">
                <Col className="col-12 pb-3 k-bb">
                    <Row>
                      <Col className="px-0 col-1">
                        <img alt="name icon" src={oneIcon} className="k-modalIconSM pe-1"/>
                      </Col>
                      <Col className="k-inputText18 col-11 ps-0">
                        Enter a name and description
                      </Col>
                    </Row>
                    <Row className="k-inputTextLarge">
                      <Col className="px-0 col-1"></Col>
                      <Col className="col-11 ps-0 ">
                        <Form.Group className="p-0 " controlId="modalProjectForm.ControlInput1">
                          <Form.Control className="p-0 k-inputText k-inputText22" type="text" placeholder="Project Name" name='name'  onChange={handleInputChange}/>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="k-inputTextMedium">
                      <Col className="px-0 col-1"></Col>
                      <Col className="col-11 ps-0 ">
                        <Form.Group className="p-0 " controlId="modalProjectForm.ControlInput2">
                          <Form.Control className="p-0 k-inputText k-inputText15" type="text" placeholder="Project description"  name='description' onChange={handleInputChange} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      {isProjectNameValid ?  null: <small style={{color: "red"}}>Enter a valid Project Name. {"(5 - 25 Alphabets and Numbers only accepted)"}</small>}
                      {isProjectDescriptionValid ?  null : <small style={{color: "red"}}>Enter a valid Project Description. {"(Alphabets, Numbers, space and charcaters[-., ()/] accepted)"}</small>}
                    </Row>
                </Col>
              </Row>
              
              <Row className="py-3 k-bb">
                <Row>
                  <Col className="px-0 col-1">
                    <img alt="name icon" src={twoIcon} className="k-modalIconSM pe-1"/>
                  </Col>
                  <Col className="k-inputText18 col-11 ps-0">
                    Link or share your project
                  </Col>
                </Row>
                <Row className="pe-0">
                  <Col className="px-0 col-1"></Col>
                  <Col className="col-11 py-0 ">
                    <Row>
                      <Col>
                        <Row className="k-inputText15">
                          Linked Project
                        </Row>
                        <Row className="disabled-text">
                          ----
                        </Row>
                      </Col>
                      <Col >
                        <Row className="k-inputText15">
                          Security Group
                        </Row>
                        <Row>
                          <Col>
                            <Row>
                              <Form.Select 
                                aria-label="Select a security group" 
                                id="modalProjectForm.security" 
                                className="k-inputSelect k-inputText18" 
                                name='security'
                                onChange={handleInputChange} 
                                defaultValue=""
                              >
                                <option disabled value="" selected >Select security</option>
                                {groups && groups.map((group: any, index: number) => 
                                  <option value={group.id} >{group.name}</option>
                                )}
                              </Form.Select>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Row>

              
              {/* <Row className="py-3 k-bb">
                <Row>
                  <Col className="px-0 col-1">
                    <img alt="name icon" src={threeIcon} className="k-modalIconSM pe-1"/>
                  </Col>
                  <Col className="k-inputText18 col-11 ps-0">
                    Add a characteristic
                  </Col>
                </Row>
                
                <Row className="pe-0">
                  <Col className="px-0 col-1"></Col>
                  <Col className="col-11 py-0 ">
                    <Row>
                      <Row >
                        <Col className="k-inputText15 pt-2">
                          Name
                        </Col>
                        <Col className="k-inputText15 pt-2">
                          Value
                        </Col>
                      </Row>

                      {profileCustomCharcateristics && profileCustomCharcateristics.map((item: any, index: number) => {

                        return(
                          <Row key={`modalProfileCustomCharacteristics.Item-${index}`}>
                            <Col className="col-10" >
                              <Row>
                                <Col className="k-inputTextLarge col-5">
                                  <Form.Group className="p-0 " controlId={`modalProfileCustomCharacteristics.Key-${index}`}>
                                    <Form.Control className="p-0 k-inputText k-inputText15" type="text" placeholder="Enter key" name='customKey'   onChange={(event: any) => handleCustomCharcateristicsInputChange(event, index)} value={item.customKey || ""}/>
                                  </Form.Group>
                                </Col>
                                <Col className="k-inputText15 col-5">
                                  <Form.Group className="p-0 " controlId={`modalProfileCustomCharacteristics.Value-${index}`}>
                                    <Form.Control className="p-0 k-inputText k-inputText15" type="text" placeholder="Enter value" name='customValue'  onChange={(event: any) => handleCustomCharcateristicsInputChange(event, index)} value={item.customValue || ""}/>
                                  </Form.Group>
                                </Col>
                              </Row>
                            </Col>
                            <Col className="col-2" >
                              <img alt="name icon" style={{ cursor: "pointer"}} onClick={(event: any) => removeProfileCustomCharacteristicRow(event, index)} src={resourceAddIcon} className="k-modalIconSM pe-1"/>
                            </Col>
                          </Row>
                        )

                      })}
                    </Row>

                    <Row className="pe-0">
                      <Col className="px-0 col-1"></Col>
                      <Col className="col-11 p-0 ">
                        <Row style={{ cursor: "pointer"}} onClick={addProfileCustomCharacteristicRow}>
                          <Col className="px-0 col-1">
                            <img alt="name icon" src={resourceAddIcon} className="k-modalIconSM pe-1"/>
                          </Col>
                          <Col className="col-11 p-0 ">
                            Add another characteristic
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                  </Col>
                </Row>
              </Row> */}

              <Row className="py-3">
                <Row>
                  <Col className="px-0 col-1">
                    <img alt="name icon" src={threeIcon} className="k-modalIconSM pe-1"/>
                  </Col>
                  <Col className="k-inputText18 col-11 ps-0">
                    Create your project
                  </Col>
                </Row>
                <Row className="pe-0">
                  <Col className="px-0 col-1"></Col>
                  <Col className="col-11 p-0 ">
                    <div className="pt-2">
                      <Button variant="secondary" 
                        type="submit"
                        disabled={!isProjectNameValid || !isProjectDescriptionValid || submitClicked}
                         size="sm" className="k-btn">
                        Make it so
                      </Button>
                    </div>
                  </Col>
                </Row>
                {submitError? (<>
                  <small style={{color: "red"}}>{submitError}</small>
                </>): null}
              </Row>
            </Container>
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
}



/**
 * Product Delete[Generic] Modal
 *  - Renders Modal to allow users to delete any chosen product
 * @param props 
 * @returns 
 */
const ProductDeleteModal: React.FunctionComponent<any> = (props: any) => {
  const { handleClose, show, handleModalFormSubmit, name, productType } = props;

  const handleConfirmDelete = (event: any) => {
    event.preventDefault();

    handleModalFormSubmit();
  }


  return (
    <>
      <Modal show={show} onHide={handleClose} contentClassName="k-mc-center" tabIndex={-1}>
        <Modal.Header closeButton className="px-2 py-1">
          <Modal.Title>
            Confirm
              {productType && productType.replace(/\w\S*/g, (productTypeText: string) => { 
                return " " + productTypeText.charAt(0).toUpperCase() + productTypeText.substring(1).toLowerCase() + " "; 
              })} 
            Deletion
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you really want to delete {name}? <br/> This process cannot be undone. </Modal.Body>
        <Modal.Footer className="px-2 py-1">
          <Button variant="secondary" onClick={handleClose} className="p-1">
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmDelete} className="p-1">
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


/**
 * Profile Delete[Generic] Modal
 *  - Renders Modal to allow users to delete any chosen profile
 * @param props 
 * @returns 
 */
const ProfileDeleteModal: React.FunctionComponent<any> = (props: any) => {

  const { handleClose, show, handleModalFormSubmit, name } = props;

  const handleConfirmDelete = (event: any) => {
    event.preventDefault();

    handleModalFormSubmit();
  }


  return (
    <>
      <Modal show={show} onHide={handleClose} contentClassName="k-mc-center" tabIndex={-1}>
        <Modal.Header closeButton className="px-2 py-1">
          <Modal.Title>
            Confirm Profile Deletion
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you really want to delete {name}? <br/> This process cannot be undone. </Modal.Body>
        <Modal.Footer className="px-2 py-1">
          <Button variant="secondary" onClick={handleClose} className="p-1">
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmDelete} className="p-1">
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


/**
 * Project Delete[Generic] Modal
 *  - Renders Modal to allow users to delete any chosen profile
 * @param props 
 * @returns 
 */
const ProjectDeleteModal: React.FunctionComponent<any> = (props: any) => {

  const { handleClose, show, handleModalFormSubmit, name } = props;

  const handleConfirmDelete = (event: any) => {
    event.preventDefault();

    handleModalFormSubmit();
  }


  return (
    <>
      <Modal show={show} onHide={handleClose} contentClassName="k-mc-center" tabIndex={-1}>
        <Modal.Header closeButton className="px-2 py-1">
          <Modal.Title>
            Confirm Project Deletion
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you really want to delete {name}? <br/> This process cannot be undone. </Modal.Body>
        <Modal.Footer className="px-2 py-1">
          <Button variant="secondary" onClick={handleClose} className="p-1">
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmDelete} className="p-1">
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}



/**
 * User Delete Modal
 *  - Renders Modal to allow users to delete any chosen profile
 * @param props 
 * @returns 
 */
const UserDeleteModal: React.FunctionComponent<any> = (props: any) => {

  const { handleClose, show, handleModalFormSubmit, name } = props;

  const handleConfirmDelete = (event: any) => {
    event.preventDefault();

    handleModalFormSubmit();
  }


  return (
    <>
      <Modal show={show} onHide={handleClose} contentClassName="k-mc-center" tabIndex={-1}>
        <Modal.Header closeButton className="px-2 py-1">
          <Modal.Title>
            Confirm User Deletion
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you really want to delete {name}? <br/> This process cannot be undone. </Modal.Body>
        <Modal.Footer className="px-2 py-1">
          <Button variant="secondary" onClick={handleClose} className="p-1">
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmDelete} className="p-1">
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}



/**
 * Group Delete Modal
 *  - Renders Modal to allow users to delete any chosen profile
 * @param props 
 * @returns 
 */
const GroupDeleteModal: React.FunctionComponent<any> = (props: any) => {

  const { handleClose, show, handleModalFormSubmit, name } = props;

  const handleConfirmDelete = (event: any) => {
    event.preventDefault();

    handleModalFormSubmit();
  }


  return (
    <>
      <Modal show={show} onHide={handleClose} contentClassName="k-mc-center" tabIndex={-1}>
        <Modal.Header closeButton className="px-2 py-1">
          <Modal.Title>
            Confirm Group Deletion
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you really want to delete {name}? <br/> This process cannot be undone. </Modal.Body>
        <Modal.Footer className="px-2 py-1">
          <Button variant="secondary" onClick={handleClose} className="p-1">
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmDelete} className="p-1">
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}




/**
 * User Add Modal
 *  - Renders Form to allow users to add new generic products
 * @param props 
 * @returns 
 */
const UserAddModal: React.FunctionComponent<any> = (props: any) => {
  const [modalFormBody, setModalFormBody] = useState<any>({});
  const [isUsernameValid, setUsernameValid] = useState(false);
  const [isDisplayNameValid, setDisplayNameValid] = useState(true);
  const [isRoleValid, setRoleValid] = useState(false);
  const [isUserBioValid, setUserBioValid] = useState(true);
  const [isUserPasswordValid, setUserPasswordValid] = useState(false);
  const [isUserPasswordConfirmValid, setUserPasswordConfirmValid] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  

  const [isFormValid, setFormValid] = useState(false);

  const { handleClose, show, handleModalFormSubmit, submitError } = props;

  
  useEffect(() => {
    setModalFormBody({});
    setUsernameValid(false);
    setDisplayNameValid(true)
    setUserBioValid(true);
    setRoleValid(false);
    setUserPasswordValid(false);
    setUserPasswordConfirmValid(false);
  }, [show])

  const handleInputChange = (event: any) => {
    if(event.target.name === "username") {
      // event.target.value = event.target.value.split(" ").join("");
      event.target.value = event.target.value.replace(" ", "");
      if (variables.DEFAULT_FORM_VALIDATION.USER.USERNAME.test(event.target.value)) {
        setUsernameValid(true);
      } else {
        setUsernameValid(false);
      }
    };

    if(event.target.name === "displayName") {
      // event.target.value = event.target.value.split(" ").join("");
      event.target.value = event.target.value.replace(" ", "");
      if (variables.DEFAULT_FORM_VALIDATION.USER.DISPLAY_NAME.test(event.target.value)) {
        setDisplayNameValid(true);
      } else {
        setDisplayNameValid(false);
      }
    };

    if(event.target.name === "password") {
      if (variables.DEFAULT_FORM_VALIDATION.USER.PASSWORD.test(event.target.value)) {
        setUserPasswordValid(true);
      } else {
        setUserPasswordValid(false);
      }
    };

    if(event.target.name === "passwordConfirm") {
      if (modalFormBody["password"] === event.target.value) {
        setUserPasswordConfirmValid(true);
      } else {
        setUserPasswordConfirmValid(false);
      }
    };

    if(event.target.name === "role") {
      if ( event.target.value !== "") {
        setRoleValid(true);
      } else {
        setRoleValid(false);
      }
    };

    if(event.target.name === "bio") {
      if (variables.DEFAULT_FORM_VALIDATION.USER.BIO.test(event.target.value) || event.target.value === "") {
        setUserBioValid(true);
      } else {
        setUserBioValid(false);
      }
    };

    setModalFormBody({
      ...modalFormBody,
      [event.target.name]: event.target.value,
    });


    if (isUsernameValid && isDisplayNameValid && isUserBioValid && isRoleValid && isUserPasswordValid && isUserPasswordConfirmValid){
      setFormValid(true)
    } else {
      setFormValid(false)
    }

  }

  const validateForm = () => {
    return
  }

  const handleFormSubmit = async(event: any) => {
    event.preventDefault();
    validateForm();

    // handleModalFormSubmit({body: {
    //   ...modalFormBody
    // }})
    console.log("User Add body: ", modalFormBody)

    if (isUsernameValid && isDisplayNameValid && isUserBioValid && isRoleValid && isUserPasswordValid && isUserPasswordConfirmValid){
      setSubmitClicked(true);
      await handleModalFormSubmit({body: {
        ...modalFormBody
      }})
      setSubmitClicked(false);

    } else {
      console.log("FORM NOT VALID... Won't make it so");
    }
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} contentClassName="k-mc-right" dialogClassName="kuro-modal" tabIndex={-1} size="lg" className="k-modal k-m-right" >
        <Form onSubmit={handleFormSubmit}>

          <Row className="k-modalHeader p-0 m-0">
                <img alt="name icon" src={modalDesktopCreateBackground} className="k-modalTitleImg p-0"/>
                <Row className="k-center ps-3">
                  <Col className="col-2 p-0">
                    <img alt="name icon" src={userProfileNavIcon} className="k-modalIcon pe-1 pt-2"/>
                  </Col>
                  <Col className="col-10 ps-3 k-pt-2">
                    <Row className="k-headerText">Create User</Row>
                    <Row className="k-subHeaderText">Authorise - Create - Capitalise</Row>
                  </Col>
                </Row>
          </Row>
          
          <Modal.Body className="k-modalBody">
            <Container className="pe-0">
              <Row className="">
                <Col className="col-12 pb-3 k-bb">
                    <Row>
                      <Col className="px-0 col-1">
                        <img alt="name icon" src={oneIcon} className="k-modalIconSM pe-1"/>
                      </Col>
                      <Col className="k-inputText18 col-11 ps-0">
                        Name and Description
                      </Col>
                    </Row>
                    <Row className="k-inputTextLarge">
                      <Col className="px-0 col-1"></Col>
                      <Col className="col-11 ps-0 ">
                        <Form.Group className="p-0 " controlId="modalUserAddForm.Username">
                          <Form.Control className="p-0 k-inputText k-inputText15" type="text" placeholder="Username" name='username'  onChange={handleInputChange}/>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="k-inputTextLarge">
                      <Col className="px-0 col-1"></Col>
                      <Col className="col-11 ps-0 ">
                        <Form.Group className="p-0 " controlId="modalUserAddForm.DisplayName">
                          <Form.Control className="p-0 k-inputText k-inputText15" type="text" placeholder="Display Name" name='displayName'  onChange={handleInputChange}/>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="k-inputTextMedium">
                      <Col className="px-0 col-1"></Col>
                      <Col className="col-11 ps-0 ">
                        <Form.Group className="p-0 " controlId="modalUserAddForm.Bio">
                          <Form.Control className="p-0 k-inputText k-inputText18" type="text" size='lg' placeholder="User bio"  name='bio' onChange={handleInputChange} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      {isUsernameValid ?  null: <small style={{color: "red"}}>Enter a valid User Name. {"(5 - 25 Alphabets and Numbers only accepted)"}</small>}
                      {isDisplayNameValid ?  null: <small style={{color: "red"}}>Enter a valid Display Name. {"(5 - 25 Alphabets and Numbers only accepted)"}</small>}
                      {isUserBioValid ?  null : <small style={{color: "red"}}>Enter a valid User Description. {"(Alphabets, Numbers, space and charcaters[-., ()/] accepted)"}</small>}
                    </Row>
                </Col>
              </Row>
              
              <Row className="py-3 k-bb">
                <Row>
                  <Col className="px-0 col-1">
                    <img alt="name icon" src={twoIcon} className="k-modalIconSM pe-1"/>
                  </Col>
                  <Col className="k-inputText18 col-11 ps-0">
                    Security
                  </Col>
                </Row>
                
                <Row className="k-inputTextLarge">
                  <Col className="px-0 col-1"></Col>
                  <Col className="col-11 ps-0 ">
                    <Form.Group className="p-0 " controlId="modalUserAddForm.Password">
                      <Form.Control className="p-0 k-inputText k-inputText15" type="password" placeholder="Password" name='password'  onChange={handleInputChange}/>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="k-inputTextLarge">
                  <Col className="px-0 col-1"></Col>
                  <Col className="col-11 ps-0 ">
                    <Form.Group className="p-0 " controlId="modalUserAddForm.PasswordConfirm">
                      <Form.Control className="p-0 k-inputText k-inputText15" type="password" placeholder="Re-enter Password" name='passwordConfirm'  onChange={handleInputChange}/>
                    </Form.Group>
                  </Col>
                </Row>

                <br/>

                <Row className="pe-0">
                  <Col className="px-0 col-1"></Col>
                  <Col className="col-11 py-0 ">
                    <Row>
                      <Col>
                        <Row className="k-inputText15">
                          MFA
                        </Row>
                        <Row>
                          
                          <Col>
                            <Row>
                              <Form.Group className="p-0 " controlId="modalUserAddForm.EnforceMFA">
                                <Form.Check className="k-inputText15" type="checkbox" label="Tick To Enforce" name='isEnforceMFA' onChange={handleInputChange} />
                              </Form.Group>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col >
                        <Row className="k-inputText15">
                          Security Group
                        </Row>
                        <Row className="disabled-text">
                          ----
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  {isUserPasswordValid ?   null : <small style={{color: "red"}}>Enter a valid User Password. {"(Alphabets, Numbers, and charcaters[-.,()/] accepted)"}</small>}
                  {isUserPasswordConfirmValid ?   null : <small style={{color: "red"}}>Re-Enter User Password to Confirm. {"(Alphabets, Numbers, and charcaters[-.,()/] accepted)"}</small>}
                </Row>
              </Row>

              
              <Row className="py-3 k-bb">
                <Row>
                  <Col className="px-0 col-1">
                    <img alt="name icon" src={threeIcon} className="k-modalIconSM pe-1"/>
                  </Col>
                  <Col className="k-inputText18 col-11 ps-0">
                    Access Control
                  </Col>
                </Row>
                <Row className="pe-0">
                  <Col className="px-0 col-1"></Col>
                  <Col className="col-11 py-0 ">
                    <Row>
                      <Col>
                        <Row className="k-inputText15 pt-2">
                          Role
                        </Row>
                        <Row>
                          
                          <Col>
                            <Row>
                              <Form.Group className="p-0 " controlId="modalUserAddForm.Role">
                                <Form.Select aria-label="Select a role" className="k-inputSelect k-inputText18" name='role' defaultValue="" onChange={handleInputChange} >
                                  <option disabled value="" >Select Role</option>
                                  <option value="user" >Analyst</option>
                                  <option disabled value="manager">Project Manager</option>
                                  <option disabled value="administrator">Admin</option>
                                </Form.Select>
                              </Form.Group>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row className="k-inputText15 pt-2">
                          Groups
                        </Row>
                        <Row>
                          
                          <Col>
                            <Row>
                              <Form.Group className="p-0 " controlId="modalUserAddForm.SecurityGroups">
                                <Form.Select aria-label="Select a groups" disabled className="k-inputSelect k-inputText18" name='groups' defaultValue="" onChange={handleInputChange} >
                                  <option disabled value="" >Select Groups</option>
                                </Form.Select>
                              </Form.Group>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  {isRoleValid ?  null: <small style={{color: "red"}}>Select User's Role</small>}
                </Row>
              </Row>

              <Row className="py-3">
                <Row>
                  <Col className="px-0 col-1">
                    <img alt="name icon" src={fourIcon} className="k-modalIconSM pe-1"/>
                  </Col>
                  <Col className="k-inputText18 col-11 ps-0">
                    Create new user
                  </Col>
                </Row>
                <Row className="pe-0">
                  <Col className="px-0 col-1"></Col>
                  <Col className="col-11 p-0 ">
                    <div className="pt-2">
                      <Button variant="secondary" 
                        type="submit"
                        disabled={!isUsernameValid || !isDisplayNameValid || !isRoleValid || !isUserBioValid || !isRoleValid || !isUserPasswordValid || !isUserPasswordConfirmValid || submitClicked}
                         size="sm" className="k-btn">
                        Make it so
                      </Button>
                    </div>
                  </Col>
                </Row>
                {submitError? (<>
                  <small style={{color: "red"}}>{submitError}</small>
                </>): null}
              </Row>
            </Container>
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
}





/**
 * Group Add Modal
 *  - Renders Form to allow users to add new generic products
 * @param props 
 * @returns 
 */
const GroupAddModal: React.FunctionComponent<any> = (props: any) => {
  const [modalFormBody, setModalFormBody] = useState<any>({});
  const [isGroupNameValid, setGroupNameValid] = useState(false);
  const [isGroupDescriptionValid, setGroupDescriptionValid] = useState(true);
  const [groupUsers, setGroupUsers] = useState<any>([]);
  const [submitClicked, setSubmitClicked] = useState(false);


  const [isFormValid, setFormValid] = useState(false);

  const { handleClose, show, handleModalFormSubmit, submitError, users } = props;

  
  useEffect(() => {
    setModalFormBody({});
    setGroupNameValid(false);
    setGroupDescriptionValid(true);
    setGroupUsers([]);
  }, [show])

  const handleInputChange = (event: any) => {
    if(event.target.name === "name") {
      // event.target.value = event.target.value.split(" ").join("");
      // event.target.value = event.target.value.replace(" ", "");
      if (variables.DEFAULT_FORM_VALIDATION.GROUP.NAME.test(event.target.value)) {
        setGroupNameValid(true);
      } else {
        setGroupNameValid(false);
      }
    };

    if(event.target.name === "description") {
      // event.target.value = event.target.value.split(" ").join("");
      // event.target.value = event.target.value.replace(" ", "");
      if (variables.DEFAULT_FORM_VALIDATION.GROUP.DESCRIPTION.test(event.target.value) || event.target.value === "") {
        setGroupDescriptionValid(true);
      } else {
        setGroupDescriptionValid(false);
      }
    };


    setModalFormBody({
      ...modalFormBody,
      [event.target.name]: event.target.value,
    });


    if (isGroupNameValid && isGroupDescriptionValid ){
      setFormValid(true)
    } else {
      setFormValid(false)
    }
  }


  const addGroupUser = (event: any, userId: any) => {
    event.preventDefault();

    setGroupUsers([
      ...groupUsers,
      userId
    ]);

  }

  const removeGroupUser = (event: any, userId: number) => {
    event.preventDefault();
      
    setGroupUsers([...groupUsers.filter((user: any) => user !== userId)]);
    
  }


  const validateForm = () => {
    return;
  }

  const handleFormSubmit = async(event: any) => {
    event.preventDefault();

    validateForm();

    console.log("Group Add body: ", modalFormBody)

    if (isGroupNameValid && isGroupDescriptionValid){
      setSubmitClicked(true);
      await handleModalFormSubmit({body: {
        ...modalFormBody,
        users: [
          ...groupUsers
        ]
      }});
      setSubmitClicked(false);

    } else {
      console.log("FORM NOT VALID... Won't make it so");
    }
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} contentClassName="k-mc-right" dialogClassName="kuro-modal" tabIndex={-1} size="lg" className="k-modal k-m-right" >
        <Form onSubmit={handleFormSubmit}>

          <Row className="k-modalHeader p-0 m-0">
                <img alt="name icon" src={modalDesktopCreateBackground} className="k-modalTitleImg p-0"/>
                <Row className="k-center ps-3">
                  <Col className="col-2 p-0">
                    <img alt="name icon" src={desktopIcon} className="k-modalIcon pe-1 pt-2"/>
                  </Col>
                  <Col className="col-10 ps-3 k-pt-2">
                    <Row className="k-headerText">Create Group</Row>
                    <Row className="k-subHeaderText">Regulate - Create - Manifest</Row>
                  </Col>
                </Row>
          </Row>
          
          <Modal.Body className="k-modalBody">
            <Container className="pe-0">
              <Row className="">
                <Col className="col-12 pb-3 k-bb">
                    <Row>
                      <Col className="px-0 col-1">
                        <img alt="name icon" src={oneIcon} className="k-modalIconSM pe-1"/>
                      </Col>
                      <Col className="k-inputText18 col-11 ps-0">
                        Enter a name and description
                      </Col>
                    </Row>
                    <Row className="k-inputTextLarge">
                      <Col className="px-0 col-1"></Col>
                      <Col className="col-11 ps-0 ">
                        <Form.Group className="p-0 " controlId="modalGroupAddForm.Name">
                          <Form.Control className="p-0 k-inputText k-inputText22" type="text" placeholder="Group Name" name='name'  onChange={handleInputChange}/>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="k-inputTextMedium">
                      <Col className="px-0 col-1"></Col>
                      <Col className="col-11 ps-0 ">
                        <Form.Group className="p-0 " controlId="modalGroupAddForm.Description">
                          <Form.Control className="p-0 k-inputText k-inputText15" type="text" placeholder="Group description"  name='description' onChange={handleInputChange} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      {isGroupNameValid ?  null: <small style={{color: "red"}}>Enter a valid Group Name. {"(5 - 25 Alphabets and Numbers only accepted)"}</small>}
                      {isGroupDescriptionValid ?  null : <small style={{color: "red"}}>Enter a valid Group Description. {"(Alphabets, Numbers, space and charcaters[-., ()/] accepted)"}</small>}
                    </Row>
                </Col>
              </Row>
              
              <Row className="py-3 k-bb">
                <Row>
                  <Col className="px-0 col-1">
                    <img alt="name icon" src={twoIcon} className="k-modalIconSM pe-1"/>
                  </Col>
                  <Col className="k-inputText18 col-11 ps-0">
                    Configure Group
                  </Col>
                </Row>
                <Row className="pe-0">
                  <Col className="px-0 col-1"></Col>
                  <Col className="col-11 py-0 ">
                    <Row>
                      <Col>
                        <Row className="k-inputText15">
                          Linked Projects
                        </Row>
                        <Row className="disabled-text">
                          ----
                        </Row>
                      </Col>
                      <Col >
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Row>

              
              <Row className="py-3 k-bb">
                <Row>
                  <Col className="px-0 col-1">
                    <img alt="name icon" src={threeIcon} className="k-modalIconSM pe-1"/>
                  </Col>
                  <Col className="k-inputText18 col-11 ps-0">
                    Add Users
                  </Col>
                </Row>
                <Row className="pe-0">
                  {/* <Col className="px-0 col-1"></Col>
                  <Col className="col-11 py-0 ">
                    <Row>
                      <Col>
                        <Row className="k-inputText15 pt-2">
                          Type
                        </Row>
                        <Row>
                          
                          <Col>
                            <Row>
                              <Form.Select aria-label="Select a desktop" id="modalGroupAddForm.users" className="k-inputSelect k-inputText12" name='users' defaultValue="" onChange={handleInputChange} >
                                <option disabled value="" >Select </option>
                              </Form.Select>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col> */}

                  <Row>
                    <Col className="col-1"/>
                    <Col className="col-8">
                        Username
                    </Col>
                    <Col className="col-1"/>
                  </Row>

                  {groupUsers && groupUsers.map((user: any, index: number) => (
                  <Row key={`paneGroupEditForm.Users.${index}`}>
                    <Col className="col-1"/>
                    <Col className="col-4 k-inputText k-inputText15">
                        {user}
                    </Col>
                    <Col className="col-1">
                      <div style={{ cursor: "pointer"}} onClick={(event: any) => removeGroupUser(event, user)}>
                        <span>
                          <img className="k-ctrlImg" src={productMediaDeleteIcon} alt={"Delete Icon"}/>
                        </span>
                      </div>
                    </Col>
                  </Row>))}
                  <Row>
                    <Col className="col-1"/>
                    <Col className="col-8 p-0 ">
                      <Row style={{ cursor: "pointer"}}>
                        
                      </Row>

                      <Dropdown>
                        <Dropdown.Toggle as={AdminGroupAddUsersDropdownToggle}>
                          <Row>
                            <Col className="px-0 col-1">
                            <img alt="name icon" src={resourceAddIcon} className="k-modalIconSM pe-1"/>
                          </Col>
                          <Col className="col-11 p-0 ">
                            Add user
                          </Col>
                          </Row>
                        </Dropdown.Toggle>

                        <Dropdown.Menu as={AdminGroupAddUsersDropdownMenu} >
                          {users && users.map((user: any, index: number) => {
                            return (groupUsers && groupUsers.includes(user.id)) ?   
                              null :
                              (<Dropdown.Item key={`paneGroupEditForm.Dropdown.${index}`} onClick={(event: any) => addGroupUser(event, user.id)}>{user.id}</Dropdown.Item>)
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Row>

                  
                </Row>
              </Row>

              <Row className="py-3">
                <Row>
                  <Col className="px-0 col-1">
                    <img alt="name icon" src={fourIcon} className="k-modalIconSM pe-1"/>
                  </Col>
                  <Col className="k-inputText18 col-11 ps-0">
                    Launch your group
                  </Col>
                </Row>
                <Row className="pe-0">
                  <Col className="px-0 col-1"></Col>
                  <Col className="col-11 p-0 ">
                    <div className="pt-2">
                      <Button variant="secondary" 
                        type="submit"
                        disabled={!isGroupNameValid || !isGroupDescriptionValid || submitClicked}
                         size="sm" className="k-btn">
                        Make it so
                      </Button>
                    </div>
                  </Col>
                </Row>
                {submitError? (<>
                  <small style={{color: "red"}}>{submitError}</small>
                </>): null}
              </Row>
            </Container>
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
}



export {
  ProductGenericAddModal,
  DesktopAddModal,
  BrowserAddModal,
  ProfileAddModal,
  ProjectAddModal,
  CommunicateAddModal,

  UserAddModal,
  GroupAddModal,

  ProductDeleteModal,
  ProfileDeleteModal,
  ProjectDeleteModal,
  UserDeleteModal,
  GroupDeleteModal
  
};