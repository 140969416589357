import React, { useState, useEffect, useContext } from 'react';
import { Route, Switch, RouteProps, RouteComponentProps, Redirect, useHistory } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import {
  SignIn, 
  SignUp,
  VerifyCode,
  RequestCode,
  ForgotPassword,
  ChangePassword,

  // Error
  NotFound,

  // Dash Pages
  Dashboard,
  Projects,
  Desktops,
  Browsers,
  Communicates,
  Profiles,

  // Admin Pages
  AdminDashboard,

} from './../pages';

import {
  PreLoader,
  SearchNavBar,
  Sidebar,
  ProductGenericAddModal,
} from './../components';


import { 
  authContextHelper, 
  api,
  bsgCrypto, 
  variables
} from './../utilities'

let appRouterHistory = createBrowserHistory();


interface KuroRouteProps extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
};





const OpenRoute: React.FunctionComponent<KuroRouteProps> = ({
  component: Component,
  ...rest}) => {
  const [loaded, setLoaded] = useState(false);


  // const [urlPrameters, setUrlPrameters] = useState(new URLSearchParams(props.location.search));



  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route
      {...rest}
      render={props =>  {
        
        let propsWithParams = {
          ...props,
          urlParameters: new URLSearchParams(props.location.search)
        }
        return(<Component {...propsWithParams} />)
      }
        // true ? ( //put your authenticate logic here
        //   <Component {...props} />
        // ) : (
        //   <Redirect
        //     to={{
        //       pathname: "/signin"
        //     }}
        //   />
        // )
      }
    />
    // <Route {...rest} render={props => ( <> <PreLoader show={loaded ? false : true} /> {Component} </> ) } />
  );
};


const ProtectedRoute: React.FunctionComponent<KuroRouteProps> = ({
  component: Component,
  ...rest}) => {
  
  const auth = useContext(authContextHelper.AuthContext);
  const currentHistory = useHistory();
  const [loaded, setLoaded] = useState(false);
  const [isShowAddProductModal, setAddProductModalShow] = useState(false);
  const [addProductModalSubmitError, setAddProductModalSubmitError] = useState<any>(null);
  // const [urlPrameters, setUrlPrameters] = useState(new URLSearchParams());

  const handleShowAddProductModal = (event: any) => {
   
    // event.preventDefault();
    setAddProductModalShow(true)
  };

  const handleCloseAddProductModal = (event: any) => {
    // event.preventDefault();
    setAddProductModalShow(false)
  };

  

  const handleProductAddSubmit = async(query: any) => {
    const vaultKeys = await bsgCrypto.VaultKey.createVaultKey([{ 
      username: auth.userProfile.username, 
      publicKey: auth.userKeys!.publicKey
    }]);
    
    const submitResponse =  await api.postProduct({
      body: query.body, 
      productType: variables.DEFAULT_PRODUCT_TYPES.DESKTOP.API_KEY,
      vaultKeys: vaultKeys
    });

    if ( submitResponse.result === "Failed"){
      setAddProductModalSubmitError(submitResponse.error);

    } else if ( submitResponse.result === "Success"){
      handleCloseAddProductModal(null);

      const handleRedirectAfterSubmission = () => {
        currentHistory.push(`/desktops?id=${submitResponse.id}`);
      }

      handleRedirectAfterSubmission()
    } else {

      console.log("ERROR RESPONSE: PRODUCTS PUT: ", submitResponse)

    }
  }

 


  useEffect(() => {

    const forceRefresh = async () => {
      await auth.refreshSessionInfo()
    }
    
    forceRefresh();

    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, [currentHistory]);

  // const localStorageIsSettingsVisible = () => {
  //   return localStorage.getItem('settingsVisible') === 'false' ? false : true
  // }

  // const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  // const toggleSettings = () => {
  //   setShowSettings(!showSettings);
  //   localStorage.setItem('settingsVisible', `${!showSettings}`);
  // }

  return (
    <Route
      {...rest}
      render = { props =>  
        {
        
          let protectedGlobalProps = {
            ...props,
            urlParameters: new URLSearchParams(props.location.search),
            isShowAddProductModal,
            handleShowAddProductModal,
            handleCloseAddProductModal,
            setLoaded,
            currentHistory,
          }

          return(
            <>
              <ProductGenericAddModal
                show={isShowAddProductModal}
                submitError={addProductModalSubmitError}
                handleClose={handleCloseAddProductModal}
                handleModalFormSubmit={handleProductAddSubmit}
              />
              
              <PreLoader show={loaded ? false : true} />
              <Sidebar />
              <main className="content">
                <SearchNavBar {...protectedGlobalProps}/>
                <Component {...protectedGlobalProps}/>
              </main>
            </>
          )
        }
        //   <>
        //    <PreLoader show={loaded ? false : true} />
        //   <Sidebar />
        //   <main className="content">
        //     <SearchNavBar />
        //     <Component {...props}/>
        //   </main>
        // </>
      
      }
    />
    
  );
};


const OpenRoutes: React.FunctionComponent = () => (
  // <Router history={appRouterHistory}>
    <Switch>
      <OpenRoute exact path="/signin" component={SignIn} />
      <OpenRoute exact path="/signup" component={SignUp} />
      <OpenRoute exact path="/verify" component={VerifyCode} />
      <OpenRoute exact path="/requestcode" component={RequestCode} />
      <OpenRoute exact path="/forgotpassword" component={ForgotPassword} />
      {/* <OpenRoute exact path="/" component={Landing} /> */}
      <Route exact path="/error" component={NotFound} />
      <Redirect exact path="/" to="/signin" />
      <Redirect exact path="/*" to="/error" />
    </Switch>
  // </Router>
)

const AuthenticatedRoutes: React.FunctionComponent = () => (
  // <Router history={appRouterHistory}>
    <Switch>
      <ProtectedRoute exact path="/changepassword" component={ChangePassword} />
      <ProtectedRoute exact path="/" component={Dashboard} />
      <ProtectedRoute exact path="/projects" component={Projects} />
      <ProtectedRoute exact path="/desktops" component={Desktops} />
      <ProtectedRoute exact path="/browsers" component={Browsers} />
      <ProtectedRoute exact path="/communicate" component={Communicates} /> 
      <ProtectedRoute exact path="/profiles" component={Profiles} />

      <ProtectedRoute exact path="/admin" component={AdminDashboard} />
      
      {/* <Route exact path="/" component={Dashboard} /> */}
      <Route exact path="/error" component={NotFound} />
      <Redirect exact path="/*" to="/error" />
      
    </Switch>
  // </Router>
)


export {
  OpenRoutes,
  AuthenticatedRoutes,
  appRouterHistory,
}