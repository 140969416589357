import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { 
  HighlightedProfile, 
  HighlightedProfileAddButton, 
  Profile, 
  ProfileDeleteModal,
  ProfileAddModal,
  ProfilesTable,
  DesktopAddModal,
  BrowserAddModal,
  CommunicateAddModal 
} from "./../../components";


import { 
  authContextHelper, 
  api, 
  bsgCrypto,
  variables,
  helpers
} from './../../utilities';

import {
  resourceAddIcon,
  identityIconTwo
} from "./../../assets/images";


const Profiles: React.FunctionComponent = (props: any) => {
  const defaultContentPaneTabKeys: string[] = ["profile.Identity", "profile.Vault", "profile.Notes"]
  const { setLoaded, urlParameters, currentHistory } = props;
  const id = urlParameters.get("id");
  const auth = useContext(authContextHelper.AuthContext);
  const [profiles, setProfiles] = useState<any>([]);
  const [groups, setGroups] = useState<any>([]);
  const [projects, setProjects] = useState<any>([]);
  const [selectedProfile, setSelectedProfile] = useState<any>(null);
  const [selectedProfileNotes, setSelectedProfileNotes] = useState<any>([]);


  const [selectedProfileVault, setSelectedProfileVault] = useState<bsgCrypto.Vault | null>(null);
  const [decryptedVaultKey, setDecryptedVaultKey] = useState<any>(null);

  const [preSelectedSecretId, setPreSelectedSecretId] = useState<string>("")

  const [selectedProfileLinkedProducts, setSelectedProfileLinkedProducts] = useState<any>(null);

  const [profilesListDeleteObject, setProfilesListDeleteObject] = useState<any>(null);

  const [isShowDeleteProfileModal, setDeleteProfileModalShow] = useState(false);
  const [isShowAddProfileModal, setAddProfileModalShow] = useState(false);
  const [isShowProfileAddDesktopModal, setShowProfileAddDesktopModal] = useState(false);
  const [isShowProfileAddBrowserModal, setShowProfileAddBrowserModal] = useState(false);
  const [isShowProfileAddCommunicateModal, setShowProfileAddCommunicateModal] = useState(false);


  const [addProfileModalSubmitError, setAddProfileModalSubmitError] = useState<any>(null);
  const [profileVaultPasswordSubmitError, setProfileVaultPasswordSubmitError] = useState<any>(null);
  const [profileAddDesktopModalSubmitError, setProfileAddDesktopModalSubmitError] = useState<any>(null);
  const [profileAddBrowserModalSubmitError, setProfileAddBrowserModalSubmitError] = useState<any>(null);
  const [profileAddCommunicateModalSubmitError, setProfileAddCommunicateModalSubmitError] = useState<any>(null);

  const [profileVaultSecretEditSubmitError, setProfileVaultSecretEditSubmitError] = useState<any>(null);

  const [profileContentActiveProducts, setProfileContentActiveProducts] = useState<any>([]);
  const [profileContentPaneTabsActiveKey, setProfileContentPaneTabsActiveKey] = useState<string>("profile.Identity");


  const handleProfileEditSecurityGroup = async(event: any, query: any) => {
    event.preventDefault();

    const submitResponse =  await api.putResourceSecurityGroup({
      resourceId: selectedProfile.id, 
      securityGroupID: query.id,
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PROFILE.API_KEY
    });

    if ( submitResponse.result === "Failed"){
      toast.warn(submitResponse.error, {
        position: "top-right",
        autoClose: 8000,
        theme: "light",
        });
    } else if ( submitResponse.result === "Success"){
      // const newName = query.name;
      // setGroupName(newName);
      await getProfiles();
      toast.success('Security Group has been changed successfully', {
        position: "top-right",
        autoClose: 5000,
        theme: "light",
        });
    } else {
      toast.warn(submitResponse.error, {
        position: "top-right",
        autoClose: 8000,
        theme: "light",
        });
    }
  }

  const handleProfileEditProject = async(event: any, query: any) => {
    event.preventDefault();

    // Not Yet Implemented
    console.log("Attempting to Updated Profile's project: TBD...");

    toast.success('Project has been changed successfully', {
      position: "top-right",
      autoClose: 5000,
      theme: "light",
    });
  }

  const handleProfileLinkedProductSelected = (event: any, productID: string) => {
    event.preventDefault();
    const productWithIdLength = profileContentActiveProducts.filter((product: any) => product.id === productID).length;

    if(productWithIdLength === 0){
      const activeProductToAdd =  selectedProfileLinkedProducts.filter((product: any) => product.id === productID)[0];

      setProfileContentActiveProducts([...profileContentActiveProducts, activeProductToAdd]);
    }

    setProfileContentPaneTabsActiveKey(productID)
  }

  const handleProfileContentPaneTabsToggle = (activeKey: string) => {
    // check if product  exists
    const productCheckLength = profileContentActiveProducts.filter((product: any) => product.id === activeKey).length;

    if (productCheckLength === 1 || defaultContentPaneTabKeys.includes(activeKey)){
      setProfileContentPaneTabsActiveKey(activeKey)
    }
  }

  const handleProfileDelete = (event: any) => {
    event.preventDefault();

    setDeleteProfileModalShow(true)
  }

  const handleProfilesListDelete = (event: any, profilesListDeleteObjectItem: any) => {
    event.preventDefault();

    setDeleteProfileModalShow(true)
    setProfilesListDeleteObject(profilesListDeleteObjectItem)
  }

  const handleCloseDeleteProfileModal = (event: any) => {
    // event.preventDefault();
    setDeleteProfileModalShow(false)
  };

  const handleCloseProfileAddProductModal = (event: any) => {
    // event.preventDefault();
    setShowProfileAddBrowserModal(false);
    setShowProfileAddDesktopModal(false);
    setShowProfileAddCommunicateModal(false);
  };

  const handleShowProfileAddDesktopModal = (event: any) => {
    // event.preventDefault();
    setShowProfileAddDesktopModal(true)
  };

  const handleShowProfileAddBrowserModal = (event: any) => {
    // event.preventDefault();
    setShowProfileAddBrowserModal(true)
  };

  const handleShowProfileAddCommunicateModal = (event: any) => {
    // event.preventDefault();
    setShowProfileAddCommunicateModal(true)
  };

  const handleProfileDeleteSubmit = async() => {
    // setLoaded(false);
    const sessionTokens = await auth.getApiSessionTokens();

    const { result } =  await api.deleteProfile({
      body: {
        id: id
      }, 
      user: sessionTokens
    });

    if (result === "Success"){
      handleCloseDeleteProfileModal(true);
      currentHistory.push("/");
    }
    // setLoaded(true);
  }

  const handleProfilesListDeleteSubmit = async() => {
    // setLoaded(false);
    const sessionTokens = await auth.getApiSessionTokens();
    console.log("delete object: ", profilesListDeleteObject);
    const { result } =  await api.deleteProfile({
      body: {
        id: profilesListDeleteObject && profilesListDeleteObject.id
      }, 
      user: sessionTokens
    });

    if (result === "Success"){
      handleCloseDeleteProfileModal(true);
      // currentHistory.push("/profiles");
      window.location.reload();
    }
    // setLoaded(true);
  }

  const getProfiles = async() => {
    setLoaded(false);
    //const sessionTokens = await auth.getApiSessionTokens();

    if(id){
      const profileData = await api.getProfile({
        body: {
          id: id
        }
      });

      setSelectedProfile(profileData);

      // Get Selected profile linked products 
      // Set products in state if profile valid
      const profileProductsData = await api.getProducts({
        filter: profileData.id
      });
      
      setSelectedProfileLinkedProducts(profileProductsData.sort((a: { modified: number; }, b: { modified: number; }) => (a.modified < b.modified) ? 1 : -1));

    }

    const profilesData = await api.getProfiles({});
    const groupsData  = await api.getSecurityGroups({});
    
    setGroups(groupsData);
    setProfiles(profilesData);

    setLoaded(true);
  }

  const getSelectedProfileNotes = async() => {
    if(id){
      const profileNotesData = await api.getResourceNotes({resourceId: id, resourceType: variables.DEFAULT_RESOURCE_TYPES.PROFILE.API_KEY});
      setSelectedProfileNotes(
        profileNotesData && profileNotesData.sort((a: { modified: number; }, b: { modified: number; }) => (a.modified < b.modified) ? 1 : -1)
      );
    }
  }

  const getSelectedProfileVault = async() => {
    console.log("Resource Vault: Refresh: 1: ID:, ", id);
    console.log("Resource Vault: Refresh: 1: Dycrypted Vault: ", decryptedVaultKey);

    if(id && decryptedVaultKey){
      const profileVaultData = await api.getResourceVault({
        resourceId: id,
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PROFILE.API_KEY,
      });

      console.log("Resource Vault: Refresh: 2: Response: ", profileVaultData);
      console.log("Resource Vault: Refresh: 2: Data: ", profileVaultData.data);

      const profileVaultRefreshed = new bsgCrypto.Vault(profileVaultData.data, decryptedVaultKey);
      console.log("Resource Vault: Refresh: 3: Data Updated: ", profileVaultRefreshed);

      if(selectedProfile.security && selectedProfile.security !== "NULL" ) {
        await helpers.checkAndAddMissingUserVaultKeys(id, variables.DEFAULT_RESOURCE_TYPES.PROFILE.API_KEY, profileVaultRefreshed);  
      }

      setSelectedProfileVault(profileVaultRefreshed);
    }
  }

  const getProjects = async() => {
    setLoaded(false);

    const projectsData = await api.getProjects({});

    setProjects(projectsData);
    setLoaded(true);  
  }

  const handleShowAddProfileModal = (event: any) => {
    // event.preventDefault();
    setAddProfileModalShow(true)
  };

  const handleCloseAddProfileModal = (event: any) => {
    // event.preventDefault();
    setAddProfileModalShow(false)
  };

  const handleProfileAddProductSubmit = async(query: any) => {
    console.log("PROFILE: ADD PRODUCT MODAL: Query: ", query);
    console.log("PROFILE: ADD PRODUCT MODAL: PRODUCT Type: ", query.productType);

    const vaultKeys = query.body.security ? 
      await helpers.createVaultKeysForSecurityGroup(query.body.security) : 
      await bsgCrypto.VaultKey.createVaultKey([{username: auth.userProfile.username, publicKey: auth.userKeys!.publicKey}]);

    const submitResponse =  await api.postProduct({
      body: query.body,  
      productType: query.productType,
      vaultKey: vaultKeys
    });

    if ( submitResponse.result === "Failed"){
      switch(query.productType){
        case variables.DEFAULT_PRODUCT_TYPES.DESKTOP.API_KEY:
          return setProfileAddDesktopModalSubmitError(submitResponse.error);

        case variables.DEFAULT_PRODUCT_TYPES.BROWSER.API_KEY:
          return setProfileAddBrowserModalSubmitError(submitResponse.error);
        
        case variables.DEFAULT_PRODUCT_TYPES.VTC.API_KEY:
          return setProfileAddCommunicateModalSubmitError(submitResponse.error);
        
        case variables.DEFAULT_PRODUCT_TYPES.CHAT.API_KEY:
          return setProfileAddCommunicateModalSubmitError(submitResponse.error);
        
        default:
          return console.log("PROFILE: ADD PRODUCT MODAL: ERROR: ", submitResponse.error);

      }
    } else if ( submitResponse.result === "Success"){

      handleCloseProfileAddProductModal(null);

      toast.success(`${query.body.name} has been created successfully`, {
        position: "top-right",
        autoClose: 5000,
        theme: "light",
      });

      // currentHistory.push(`/profiles?id=${selectedProfile.id}`);
      window.location.reload();

    } else {

      console.log("ERROR RESPONSE: PROFILE PRODUCTS POST: ", submitResponse);

    }
  }

  const handleProfileAddSubmit = async(query: any) => {
    // const sessionTokens = await auth.getApiSessionTokens();

    console.log("attempting profile submit : ", query);

    // Generate Profile Vault
    const vaultKeys = query.body.security ? 
      await helpers.createVaultKeysForSecurityGroup(query.body.security) : 
      await bsgCrypto.VaultKey.createVaultKey([{username: auth.userProfile.username, publicKey: auth.userKeys!.publicKey}]);
    
    console.log("Vauly keys Creation: ", vaultKeys);

    const submitResponse =  await api.postProfile({
      body: query.body, 
      user: auth.userProfile.username,
      vaultKeys: vaultKeys
    });

    console.log("submit response: ", submitResponse)

    if ( submitResponse.result === "Failed"){
      setAddProfileModalSubmitError(submitResponse.error);

    } else if ( submitResponse.result === "Success"){
      
      handleCloseAddProfileModal(null);

      currentHistory.push(`/profiles?id=${submitResponse.id}`);

    } else {

      console.log("ERROR RESPONSE: PROFILES PUT: ", submitResponse);

    }
  }

  const handleGetProfileNote = async(event: any) => {
    //const ele = document.getElementById('profile-selectednote-editor');
    const noteData = await api.getResourceNote({
      resourceId: id, 
      noteId: event.noteID, 
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PROFILE.API_KEY
    });
    
    if(noteData){
      event.editorRef.current.setContent(noteData);
      event.editorRef.current.id = event.noteID;
    }
  }

  const handlePutProfileNote = async(event: any) => {
    console.log("LOG NOTE: handlePutProfileNote profiles event : ", event);

    await api.putResourceNote({
      resourceId: id, 
      noteId: event.noteId, 
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PROFILE.API_KEY,
      body: event.body,
      headline: event.headline, 
      title: event.title
    });

    getSelectedProfileNotes();
  }

  const handlePostProfileNote = async(event: any) => {
    await api.postResourceNote({
      resourceId: id,
      body: 'New Note', 
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PROFILE.API_KEY, 
      headline: 'New Note', 
      title: 'New Note'
    });

    getSelectedProfileNotes();
  }

  const handleDeleteProfileNote = async(query: any) => {
    console.log("LOG NOTE JDD handleDeleteProfileNote : ", query);

    document.getElementById(`notecard-${query}`)?.classList.add('flash');
    await api.deleteResourceNote({
      resourceId: id, 
      noteId: query,
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PROFILE.API_KEY, 
    });

    await getSelectedProfileNotes();
    document.getElementById(`notecard-${query}`)?.classList.remove('flash');
  }


  const handleDycryptVaultSubmit = async(query: any) => {
    const profileVaultData = await api.getResourceVault({
      resourceId: id,
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PROFILE.API_KEY,
    });

    console.log("Resource Vault: 1: ", profileVaultData.data);
    const base64EncodedVaultKey = profileVaultData.data.key[auth.userProfile.username]!;
    console.log("Resource Vault: 2: ", profileVaultData.data);
    const myVaultKey = bsgCrypto.VaultKey.fromBase64(base64EncodedVaultKey);
    console.log("Resource Vault: 3: ", profileVaultData.data);
    
    const unwrappedVaultKey = await bsgCrypto.UnwrappedVaultKey.fromWrappedVaultKey(
      myVaultKey,
      query.body.password,
      auth.userKeys!.wrappedPrivateKey
    );

    console.log("Resource Vault: 4: ", profileVaultData.data);
    
    if(unwrappedVaultKey){
      setDecryptedVaultKey(unwrappedVaultKey);

      const profileVaultRefreshed = new bsgCrypto.Vault(profileVaultData.data, unwrappedVaultKey);
      console.log("Resource Vault: 5: Data Updated: ", profileVaultRefreshed);

      if(selectedProfile.security && selectedProfile.security !== "NULL" ) {
        await helpers.checkAndAddMissingUserVaultKeys(id, variables.DEFAULT_RESOURCE_TYPES.PROFILE.API_KEY, profileVaultRefreshed);  
      }
      
      setSelectedProfileVault(profileVaultRefreshed);
    } else {
      setProfileVaultPasswordSubmitError("Password Error: Enter Valid Password");
    }
  };

  const handlePostProfileVaultPassword = async(event: any) => {
    const postResponse = await api.postResourceVaultPassword({
      resourceId: id,
      body: '<h1> New Password </h1>', 
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PROFILE.API_KEY, 
      headline: 'New Password', 
      title: 'New Password'
    });

    console.log("Creating new password: 1 : ", postResponse);

    if(postResponse.data && postResponse.data.id){
      const passwordChangeset = await selectedProfileVault!.addOrUpdateItemInVault(
        "My new password",
        new bsgCrypto.SecretMetadata(postResponse.data.id, "My New Password Title", "Username")
      );

      console.log("Creating new password: 2 : Changeset: ", passwordChangeset); 

      const passwordChangeResponse = await api.patchResourceVaultPassword({
        resourceId: id, 
        body: passwordChangeset, 
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PROFILE.API_KEY, 
      });

      console.log("Creating new password: 3 : Change Patch: ", passwordChangeResponse); 
    }

    await getSelectedProfileVault();
  }


  const handleDeleteProfileVaultPassword = async(query: any) => {
    console.log("Deleting password: 1 : ", query);

    if(query.body && query.body.secretId){

      const passwordChangeset = await selectedProfileVault!.removeItemFromVault(query.body.secretId);

      console.log("Deleting password: 2 : Changeset: ", passwordChangeset); 

      const passwordChangeResponse = await api.patchResourceVaultPassword({
        resourceId: id, 
        body: passwordChangeset, 
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PROFILE.API_KEY, 
      });

      console.log("Deleting password: 3 : Change Patch: ", passwordChangeResponse); 

      if (passwordChangeResponse && passwordChangeResponse.result === "Success") {

        const apiResponse = await api.deleteResourceVaultPassword({
          resourceId: id, 
          resourceType: variables.DEFAULT_RESOURCE_TYPES.PROFILE.API_KEY,
          secretId: query.body.secretId
        });

        console.log("Deleting password: 4 : API Response: ", apiResponse); 
      }
    }

    await getSelectedProfileVault();
  }


  const handleEditProfileVaultPassword = async(query: any) => {
    console.log("Editing password: 1 : ", query);

    if(query.body && query.body.secretId){
      console.log("Deleting password: 2 : ", query); 
      
      setPreSelectedSecretId(query.body.secretId);
      
      const passwordChangeset = await selectedProfileVault!.addOrUpdateItemInVault(query.password, query.body);

      console.log("Deleting password: 3 : Changeset: ", passwordChangeset); 

      const passwordChangeResponse = await api.patchResourceVaultPassword({
        resourceId: id, 
        body: passwordChangeset, 
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PROFILE.API_KEY,
      });

      console.log("Deleting password: 4 : Change Patch: ", passwordChangeResponse); 
      
    }

    await getSelectedProfileVault();
  }

  const handleProfilesListToggleFavorite = async(event: any) => {
    const toggleResponse = await api.toggleResourceFavourite({
      resourceId: event.resourceId, 
      toggleType: event.toggleType, 
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PROFILE.API_KEY
    });
    console.log("BROWSER: Toggle : ", toggleResponse);

    await getProfiles();

    toast.success('Favorites have been updated successfully', {
      position: "top-right",
      autoClose: 3000,
      theme: "light",
    });
  }


  // On mount: hook effect
  useEffect(() =>{

    // Fetch All Data
    getProfiles();
    getSelectedProfileNotes();
    getProjects();
    setProfilesListDeleteObject(null);
  },[id]);


  console.log("PROFILES: USER KEYS FROM CONTEXT: ", auth.userKeys);

  if(id){
    // Check if Selected profile state value is not null
    // Render Selected profile with success res object as props, else redirect to 404 page
    return (
      <>
        {(selectedProfile) && (
          <>
            <ProfileDeleteModal
                show={isShowDeleteProfileModal}
                handleClose={handleCloseDeleteProfileModal}
                handleModalFormSubmit={handleProfileDeleteSubmit}
                name={selectedProfile.name}
              />
            
            <DesktopAddModal
              show={isShowProfileAddDesktopModal}
              submitError={profileAddDesktopModalSubmitError}
              handleClose={handleCloseProfileAddProductModal}
              handleModalFormSubmit={(query: any) => handleProfileAddProductSubmit(query)}

              linkedProfileId={selectedProfile.id}
              securityGroups={groups}
            />

            <BrowserAddModal
              show={isShowProfileAddBrowserModal}
              submitError={profileAddBrowserModalSubmitError}
              handleClose={handleCloseProfileAddProductModal}
              handleModalFormSubmit={(query: any) => handleProfileAddProductSubmit(query)}

              linkedProfileId={selectedProfile.id}
              securityGroups={groups}
            />

            <CommunicateAddModal
              show={isShowProfileAddCommunicateModal}
              submitError={profileAddCommunicateModalSubmitError}
              handleClose={handleCloseProfileAddProductModal}
              handleModalFormSubmit={(query: any) => handleProfileAddProductSubmit(query)}

              linkedProfileId={selectedProfile.id}
              securityGroups={groups}
            />

            <Profile
              profileDetails={selectedProfile} 
              mediaControlHandlers={{
                handleProfileDelete: handleProfileDelete,
              }}

              groups={groups}
              projects={projects}
              profileNotes={selectedProfileNotes}
              
              profileLinkedProducts={selectedProfileLinkedProducts}
              
              profileContentPaneTabsActiveKey={profileContentPaneTabsActiveKey}
              profileContentActiveProducts={profileContentActiveProducts}
              
              handleProfileEditSecurityGroup={handleProfileEditSecurityGroup}
              handleProfileEditProject={handleProfileEditProject}
              handleProfileLinkedProductSelected={handleProfileLinkedProductSelected}
              handleProfileContentPaneTabsToggle={handleProfileContentPaneTabsToggle}
              

              // Modal Controls: Profile Products
              profileModalHandlers={{
                handleShowAddDesktopModal: handleShowProfileAddDesktopModal,
                handleShowAddBrowserModal: handleShowProfileAddBrowserModal,
                handleShowAddCommunicateModal: handleShowProfileAddCommunicateModal,
              }}

              // Note handlers
              profileNoteHandlers={{
                handleGetProfileNote: handleGetProfileNote,
                handlePutProfileNote: handlePutProfileNote,
                handlePostProfileNote: handlePostProfileNote,
                handleDeleteProfileNote: handleDeleteProfileNote,
                handleGetProfileNotes: getSelectedProfileNotes
              }}

              // Vault Handlers
              profileVaultHandlers={{
                handleDycryptVaultSubmit: handleDycryptVaultSubmit,
                handlePostVaultPassword: handlePostProfileVaultPassword,
                handleDeleteVaultPassword: handleDeleteProfileVaultPassword,
                handleEditVaultPassword: handleEditProfileVaultPassword,

                setPreSelectedSecretId: setPreSelectedSecretId,

                vaultPasswordSubmitError: profileVaultPasswordSubmitError,
                vaultSecretEditSubmitError: profileVaultSecretEditSubmitError,
                decryptedVaultKey: decryptedVaultKey,
                preSelectedSecretId: preSelectedSecretId,

                profileVault: selectedProfileVault
              }}
            />
        </>
        
        )}
      </>
    )
  } else {
    return (
      <>
        <ProfileDeleteModal
          show={isShowDeleteProfileModal}
          handleClose={handleCloseDeleteProfileModal}
          handleModalFormSubmit={handleProfilesListDeleteSubmit}
          name={profilesListDeleteObject && profilesListDeleteObject.name}
        />
        <ProfileAddModal
          show={isShowAddProfileModal}
          submitError={addProfileModalSubmitError}
          handleClose={handleCloseAddProfileModal}
          handleModalFormSubmit={handleProfileAddSubmit}
          groups={groups}
          projects={projects}
        />
        <div>
          <Row>
            <Col md={4}><h1> Profile Overview </h1></Col>
          </Row>
          {/* <h1> Profiles </h1> */}
          <h5>Hello {auth.userProfile.username}, all of your profiles are shown below.</h5>
        </div>

        <Container className="ps-3 ms-0 pt-3"> 
          <div className="pt-0">
            <div>
              <Row>
                {profiles && profiles.map((profile: any, index: number) => {
                  return (
                    <HighlightedProfile key={`HR-${index}`}
                      header={profile.name}
                      description={profile.description}
                      image={identityIconTwo}
                      linkPath={`/profiles?id=${profile.id}`}
                      status={profile.state}
                    />
                  )
                })}

              </Row>
              <Row>
                <HighlightedProfileAddButton
                  clickHandler={handleShowAddProfileModal}
                  header={"Add Profile"}
                  description={"Create a new profile"}
                  image={resourceAddIcon}
                />
              </Row>
              <br/>
              <Row>
                <Row>
                  <div className="ps-0">
                    <h6> All Profiles </h6>
                  </div>
                </Row>
                <Row>
                  <ProfilesTable 
                    profiles={profiles} 
                    mediaControlHandlers={{
                      handleProfilesListDelete: handleProfilesListDelete,
                      handleProfilesListToggleFavorite: handleProfilesListToggleFavorite
                    }}
                />
                </Row>
              </Row>
            </div>
          </div>
        </Container>
      </>
    );
  }
};








export default Profiles;