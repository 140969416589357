import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { 
  HighlightedResourceAddButton, 
  HighlightedProduct,
  SelectedBrowser, 
  ProductDeleteModal,
  BrowserAddModal,
  BrowsersTable 
} from "./../../components";


import { 
  authContextHelper, 
  api, 
  helpers,
  bsgCrypto,
  variables
} from './../../utilities';

import {
  resourceAddIcon
} from "./../../assets/images";




const Browsers: React.FunctionComponent = (props: any) => {
  const DEFAULT_PRODUCT_TYPE = "browser";
  const { setLoaded, urlParameters, currentHistory } = props;
  const id = urlParameters.get("id");

  const auth = useContext(authContextHelper.AuthContext);
  const [browsers, setBrowsers] = useState<any>([]);
  const [selectedBrowser, setSelectedBrowser] = useState<any>(null);
  const [selectedBrowserNotes, setSelectedBrowserNotes] = useState<any>([]);
  const [isShowDeleteBrowserModal, setDeleteBrowserModalShow] = useState(false);
  const [selectedBrowserStatus, setSelectedBrowserStatus] = useState("PENDING");
  const [browsersListDeleteObject, setBrowsersListDeleteObject] = useState<any>(null);
  const [isShowAddBrowserModal, setAddBrowserModalShow] = useState(false);
  const [addBrowserModalSubmitError, setAddBrowserModalSubmitError] = useState<any>(null);

  const [groups, setGroups] = useState<any>([]);
  const [profiles, setProfiles] = useState<any>([]);
  const [projects, setProjects] = useState<any>([]);

  const [browserVaultPasswordSubmitError, setBrowserVaultPasswordSubmitError] = useState<any>(null);
  const [browserVaultSecretEditSubmitError, setBrowserVaultSecretEditSubmitError] = useState<any>(null);

  const [selectedBrowserVault, setSelectedBrowserVault] = useState<bsgCrypto.Vault | null>(null);
  const [decryptedVaultKey, setDecryptedVaultKey] = useState<any>(null);
  const [preSelectedSecretId, setPreSelectedSecretId] = useState<string>("");

  const handleBrowserStart = async(event: any) => {
    event.preventDefault();

    await api.startProduct({
      body: {
        id: id
      }
    });

    getBrowsers();
  }

  const handleIframeMouseOver = async(event: any) => {
    event.preventDefault();
    const iframeDocument = document.getElementById(event.target.id);
    iframeDocument?.focus();
  }

  const  handleBrowserStop = async (event: any) => {
    event.preventDefault();
    
    await api.stopProduct({
      body: {
        id: id
      }
    });

    getBrowsers();
  }

  const  handleBrowserFullScreen = (event: any) => {
    event.preventDefault();
  }

  const  handleBrowserDelete = (event: any) => {
    event.preventDefault();

    setDeleteBrowserModalShow(true)
  }

  const  handleBrowsersListDelete = (event: any, browsersListDeleteObjectItem: any) => {
    event.preventDefault();

    setDeleteBrowserModalShow(true)
    setBrowsersListDeleteObject(browsersListDeleteObjectItem)
  }

  const  handleBrowserHibernate = async(event: any) => {
    event.preventDefault();
    
    await api.hibernateProduct({
      body: {
        id: id
      }
    });

    getBrowsers();
  }

  const handleCloseDeleteBrowserModal = (event: any) => {
    // event.preventDefault();
    setDeleteBrowserModalShow(false)
  };

  const handleBrowserDeleteSubmit = async() => {
    // setLoaded(false);
    const { result } =  await api.deleteProduct({
      body: {
        id: id
      }
    });

    if (result === "Success"){
      handleCloseDeleteBrowserModal(true);
      currentHistory.push("/");
    }
    // setLoaded(true);
  }

  const handleBrowsersListDeleteSubmit = async() => {
    const { result } =  await api.deleteProduct({
      body: {
        id: browsersListDeleteObject && browsersListDeleteObject.id
      }
    });

    if (result === "Success"){
      handleCloseDeleteBrowserModal(true);
      // currentHistory.push("/browsers");
      window.location.reload();
    }
    // setLoaded(true);
  }

  const getBrowsers = async() => {
    setLoaded(false);

    if(id){
      const browserData = await api.getProduct({
        body: {
          id: id
        }, 
      });

      setSelectedBrowser(browserData);
      setSelectedBrowserStatus(browserData.state.toUpperCase());
    }

    const browsersData = await api.getProducts({
      productTypes: [variables.DEFAULT_PRODUCT_TYPES.BROWSER.API_KEY]
    });

    setBrowsers(browsersData);
    setLoaded(true);
  }

  const getBrowsersStatuses = async () => {
    if(id){
      const browserData = await api.getProduct({
        body: {
          id: id
        }
      });

      setSelectedBrowser(browserData);
      setSelectedBrowserStatus(browserData.state.toUpperCase());
    }
  }

  const handleShowAddBrowserModal = (event: any) => {
    // event.preventDefault();
    setAddBrowserModalShow(true)
  };

  const handleCloseAddBrowserModal = (event: any) => {
    // event.preventDefault();
    setAddBrowserModalShow(false)
  };

  const handleBrowserAddSubmit = async(query: any) => {
    const vaultKeys = query.body.security ? 
      await helpers.createVaultKeysForSecurityGroup(query.body.security) : 
      await bsgCrypto.VaultKey.createVaultKey([{username: auth.userProfile.username, publicKey: auth.userKeys!.publicKey}]);

    const submitResponse =  await api.postProduct({
      body: query.body,
      productType: variables.DEFAULT_PRODUCT_TYPES.BROWSER.API_KEY,
      vaultKeys: vaultKeys
    });

    if ( submitResponse.result === "Failed"){
      setAddBrowserModalSubmitError(submitResponse.error);

    } else if ( submitResponse.result === "Success"){
      handleCloseAddBrowserModal(null);
      currentHistory.push(`/browsers?id=${submitResponse.id}`);

    } else {
      console.log("ERROR RESPONSE: Product PUT: ", submitResponse);
    }
  }


  const getGroups = async() => {
    setLoaded(false);

    const groupsData = await api.getSecurityGroups({});

    setGroups(groupsData);
    setLoaded(true);
  }

  const getProfiles = async() => {
    setLoaded(false);

    const profilesData = await api.getProfiles({});

    setProfiles(profilesData);
    setLoaded(true);  
  }

  const getProjects = async() => {
    setLoaded(false);

    const projectsData = await api.getProjects({});

    setProjects(projectsData);
    setLoaded(true);  
  }

  const getSelectedBrowserNotes = async() => {
    if(id){
      const browserNotes = await api.getResourceNotes({resourceId: id, resourceType: "product"});
      setSelectedBrowserNotes(browserNotes && browserNotes.sort((a: { modified: number; }, b: { modified: number; }) => (a.modified < b.modified) ? 1 : -1));
    }
  }

  const getSelectedBrowserVault = async() => {
    console.log("Resource Vault: Refresh: 1: ID:, ", id);
    console.log("Resource Vault: Refresh: 1: Dycrypted Vault: ", decryptedVaultKey);

    if(id && decryptedVaultKey){
      const browserVaultData = await api.getResourceVault({
        resourceId: id,
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY,
      });

      console.log("Resource Vault: Refresh: 2: Response: ", browserVaultData);
      console.log("Resource Vault: Refresh: 2: Data: ", browserVaultData.data);

      const browserVaultRefreshed = new bsgCrypto.Vault(browserVaultData.data, decryptedVaultKey);
      console.log("Resource Vault: Refresh: 3: Data Updated: ", browserVaultRefreshed);
      
      setSelectedBrowserVault(browserVaultRefreshed);
    }
  }

  const handlePutBrowserSecurityGroupSubmit = async(event: any, query: any) => {
    event.preventDefault();
    const submitResponse =  await api.putResourceSecurityGroup({
      resourceId: selectedBrowser.id, 
      securityGroupID: query.id,
      resourceType: "product" 
    });

    if ( submitResponse.result === "Failed"){
      toast.warn(submitResponse.error, {
        position: "top-right",
        autoClose: 8000,
        theme: "light",
        });
    } else if ( submitResponse.result === "Success"){
      // const newName = query.name;
      // setGroupName(newName);
      toast.success('Security Group has been changed successfully', {
        position: "top-right",
        autoClose: 5000,
        theme: "light",
        });
    } else {
      toast.warn(submitResponse.error, {
        position: "top-right",
        autoClose: 8000,
        theme: "light",
        });
    }
  }

  const handlePutBrowserProfileSubmit = async(event: any, query: any) => {
    event.preventDefault();
    const submitResponse =  await api.putProductProfile({
      id: selectedBrowser.id, 
      profileID: query.id
    });

    if ( submitResponse.result === "Failed"){
      toast.warn(submitResponse.error, {
        position: "top-right",
        autoClose: 8000,
        theme: "light",
        });
    } else if ( submitResponse.result === "Success"){
      toast.success('Profile has been changed successfully', {
        position: "top-right",
        autoClose: 5000,
        theme: "light",
        });
    } else {
      toast.warn(submitResponse.error, {
        position: "top-right",
        autoClose: 8000,
        theme: "light",
        });
    }
  }

  const handlePutBrowserProjectSubmit = async(event: any, query: any) => {
    event.preventDefault();
    console.log("Attempting to Updated Browser's project: TBD...");

    toast.success('Project has been changed successfully', {
      position: "top-right",
      autoClose: 5000,
      theme: "light",
    });
  }

  const handleGetBrowserNote = async(event: any) => {
    //const ele = document.getElementById('profile-selectednote-editor');
    const noteData = await api.getResourceNote({resourceId: id, noteId: event.noteID, resourceType: "product"});
    
    if(noteData){
      event.editorRef.current.setContent(noteData);
      event.editorRef.current.id = event.noteID;
    }
  }

  const handlePutBrowserNote = async(event: any) => {
    console.log("LOG NOTE ERROR Event: ", event);
    const noteBody = event.current.getContent();
    const noteTmp = noteBody.replace(/<[^>]*>/g, "")
    const firstLine = noteTmp.split('\n')[0].substring(0, 35);
    const secondLine = noteTmp.split('\n')[1]?.substring(0, 35);

    console.log("LOG NOTE ERROR Body: ", noteBody);
    console.log("LOG NOTE ERROR noteTmp: ", noteTmp);
    console.log("LOG NOTE ERROR firstLine: ", firstLine);
    console.log("LOG NOTE ERROR secondLine: ", secondLine);

    if (noteTmp.split('\n').length > 1) {
      //const secondLine = noteTmp.split('\n')[1].substring(0, 35);

      await api.putResourceNote({
        resourceId: id, 
        noteId: event.current.id, 
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY,
        body: noteBody,
        headline: secondLine, 
        title: firstLine
      });
    } else {
      await api.putResourceNote({
        resourceId: id, 
        noteId: event.current.id, 
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY,
        body: noteBody,
        title: firstLine
      });
    }
    getSelectedBrowserNotes();
  }

  const handlePostBrowserNote = async(event: any) => {
    await api.postResourceNote({
      resourceId: id,
      body: 'New Note', 
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY, 
      headline: 'New Note', 
      title: 'New Note'
    });

    getSelectedBrowserNotes();
  }

  const handleDeleteBrowserNote = async(noteID: any) => {

    console.log("LOG NOTE handleDeleteProductNote Browser : ", noteID)

    document.getElementById(`notecard-${noteID}`)?.classList.add('flash');
    await api.deleteResourceNote({
      resourceId: id, 
      noteId: noteID,
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY, 
    });

    await getSelectedBrowserNotes();
    document.getElementById(`notecard-${noteID}`)?.classList.remove('flash');
  }
  
  const handleDycryptVaultSubmit = async(query: any) => {
    const browserVaultData = await api.getResourceVault({
      resourceId: id,
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY
    });

    console.log("Resource Vault: 1: ", browserVaultData.data);
    const base64EncodedVaultKey = browserVaultData.data.key[auth.userProfile.username]!;
    console.log("Resource Vault: 2: ", browserVaultData.data);
    const myVaultKey = bsgCrypto.VaultKey.fromBase64(base64EncodedVaultKey);
    console.log("Resource Vault: 3: ", browserVaultData.data);
    
    const unwrappedVaultKey = await bsgCrypto.UnwrappedVaultKey.fromWrappedVaultKey(
      myVaultKey,
      query.body.password,
      auth.userKeys!.wrappedPrivateKey
    );

    console.log("Resource Vault: 4: ", browserVaultData.data);
    
    if(unwrappedVaultKey){
      setDecryptedVaultKey(unwrappedVaultKey);

      const browserVaultRefreshed = new bsgCrypto.Vault(browserVaultData.data, unwrappedVaultKey);
      console.log("Resource Vault: 5: Data Updated: ", browserVaultRefreshed);
      
      setSelectedBrowserVault(browserVaultRefreshed);
    } else {
      setBrowserVaultPasswordSubmitError("Password Error: Enter Valid Password");
    }
  };

  const handlePostBrowserVaultPassword = async(event: any) => {
    const postResponse = await api.postResourceVaultPassword({
      resourceId: id,
      body: '<h1> New Password </h1>', 
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY, 
      headline: 'New Password', 
      title: 'New Password'
    });

    console.log("Creating new password: 1 : ", postResponse);

    if(postResponse.data && postResponse.data.id){
      const passwordChangeset = await selectedBrowserVault!.addOrUpdateItemInVault(
        "My new password",
        new bsgCrypto.SecretMetadata(postResponse.data.id, "My New Password Title", "Username")
      );

      console.log("Creating new password: 2 : Changeset: ", passwordChangeset); 

      const passwordChangeResponse = await api.patchResourceVaultPassword({
        resourceId: id, 
        body: passwordChangeset, 
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY, 
      });

      console.log("Creating new password: 3 : Change Patch: ", passwordChangeResponse); 
    }

    await getSelectedBrowserVault();
  }


  const handleDeleteBrowserVaultPassword = async(query: any) => {
    console.log("Deleting password: 1 : ", query);

    if(query.body && query.body.secretId){

      const passwordChangeset = await selectedBrowserVault!.removeItemFromVault(query.body.secretId);

      console.log("Deleting password: 2 : Changeset: ", passwordChangeset); 

      const passwordChangeResponse = await api.patchResourceVaultPassword({
        resourceId: id, 
        body: passwordChangeset, 
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY, 
      });

      console.log("Deleting password: 3 : Change Patch: ", passwordChangeResponse); 

      if (passwordChangeResponse && passwordChangeResponse.result === "Success") {

        const apiResponse = await api.deleteResourceVaultPassword({
          resourceId: id, 
          resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY,
          secretId: query.body.secretId
        });

        console.log("Deleting password: 4 : API Response: ", apiResponse); 
      }
    }

    await getSelectedBrowserVault();
  }


  const handleEditBrowserVaultPassword = async(query: any) => {
    console.log("Editing password: 1 : ", query);

    if(query.body && query.body.secretId){
      console.log("Deleting password: 2 : ", query); 
      
      setPreSelectedSecretId(query.body.secretId);
      
      const passwordChangeset = await selectedBrowserVault!.addOrUpdateItemInVault(query.password, query.body);

      console.log("Deleting password: 3 : Changeset: ", passwordChangeset); 

      const passwordChangeResponse = await api.patchResourceVaultPassword({
        resourceId: id, 
        body: passwordChangeset, 
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY, 
      });

      console.log("Deleting password: 4 : Change Patch: ", passwordChangeResponse); 
      
    }

    await getSelectedBrowserVault();
  }

  const handleBrowsersListToggleFavorite = async(event: any) => {
    const toggleResponse = await api.toggleResourceFavourite({
      resourceId: event.resourceId, 
      toggleType: event.toggleType, 
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY
    });
    console.log("BROWSER: Toggle : ", toggleResponse);

    await getBrowsers();
    await getBrowsersStatuses();

    toast.success('Favorites have been updated successfully', {
      position: "top-right",
      autoClose: 3000,
      theme: "light",
      });
  }

  // On mount: hook effect
  useEffect(() =>{

    // Fetch All Data
    getBrowsers();
    getGroups();
    getProfiles();
    getProjects();
    getSelectedBrowserNotes();

    setBrowsersListDeleteObject(null);
  },[id]);


  console.log("BROWSERS: USER KEYS FROM CONTEXT: ", auth.userKeys);

  
  helpers.useInterval(() => {
    getBrowsersStatuses();

  }, 1000 * 10);


  if(id){
    // Check if Selected browser state value is not null
    // Render Selected browser with success res object as props, else redirect to 404 page
    
    return (
      <>
        {(selectedBrowser) && (
          <>
            <ProductDeleteModal
              show={isShowDeleteBrowserModal}
              handleClose={handleCloseDeleteBrowserModal}
              handleModalFormSubmit={handleBrowserDeleteSubmit}
              name={selectedBrowser.name}
              productType={DEFAULT_PRODUCT_TYPE}
            />

            <SelectedBrowser 
              browserDetails={selectedBrowser} 
              browserNotes={selectedBrowserNotes}
              browserStatus={selectedBrowserStatus}
              productType={DEFAULT_PRODUCT_TYPE}

              groups={groups}
              projects={projects}
              profiles={profiles}
              browserVault={selectedBrowserVault}

              browserVaultPasswordSubmitError={browserVaultPasswordSubmitError}
              browserVaultSecretEditSubmitError={browserVaultSecretEditSubmitError}
              decryptedVaultKey={decryptedVaultKey}
              preSelectedSecretId={preSelectedSecretId}

              toggleHandlers={{
                handlePutBrowserSecurityGroupSubmit,
                handlePutBrowserProfileSubmit,
                handlePutBrowserProjectSubmit,
              }}

              mediaControlHandlers={{
                handleProductStart: handleBrowserStart,
                handleProductStop: handleBrowserStop,
                handleProductHibernate: handleBrowserHibernate,
                handleProductFullScreen: handleBrowserFullScreen,
                handleProductDelete: handleBrowserDelete,
                handleIframeMouseOver
              }}

              productNoteHandlers={{
                handleGetProductNote: handleGetBrowserNote,
                handlePutProductNote: handlePutBrowserNote,
                handlePostProductNote: handlePostBrowserNote,
                handleDeleteProductNote: handleDeleteBrowserNote
              }}

              productVaultHandlers={{
                handleDycryptVaultSubmit: handleDycryptVaultSubmit,
                handlePostProductVaultPassword: handlePostBrowserVaultPassword,
                handleDeleteProductVaultPassword: handleDeleteBrowserVaultPassword,
                handleEditProductVaultPassword: handleEditBrowserVaultPassword
              }}

            />
        </>
        
        )}
      </>
    )
  } else {
    return (
      <>
        <ProductDeleteModal
          show={isShowDeleteBrowserModal}
          handleClose={handleCloseDeleteBrowserModal}
          handleModalFormSubmit={handleBrowsersListDeleteSubmit}
          name={browsersListDeleteObject && browsersListDeleteObject.name}
          productType={DEFAULT_PRODUCT_TYPE}
        />
        <BrowserAddModal
          show={isShowAddBrowserModal}
          submitError={addBrowserModalSubmitError}
          handleClose={handleCloseAddBrowserModal}
          handleModalFormSubmit={handleBrowserAddSubmit}
          securityGroups={groups}
          projects={projects}
          profiles={profiles}
        />
        <div>
          <Row>
            <Col md={5}><h1> Browser Overview </h1></Col>
          </Row>
          {/* <h1> Browsers </h1> */}
          <h5>Hello {auth.userProfile.username}, all of your browsers are shown below.</h5>
        </div>

        <Container className="ps-3 ms-0 pt-3"> 
          <div className="pt-0">
            <div>
              <Row>
                {browsers && browsers.map((browser: any, index: number) => {
                  return (
                    <HighlightedProduct key={`HR-${index}`}
                      header={browser.name}
                      description={browser.description}
                      linkPath={`/browsers?id=${browser.id}`}
                      status={browser.state}
                      product={browser.product}
                      system={browser.system}
                    />
                  )
                })}

                {/* <HighlightedResourceAddButton
                  clickHandler={handleShowAddBrowserModal}
                  header={"Add Browser"}
                  description={"Create a new browser"}
                  image={resourceAddIcon}
                /> */}
              </Row>
              <Row>
                <HighlightedResourceAddButton
                  clickHandler={handleShowAddBrowserModal}
                  header={"Add Browser"}
                  description={"Create a new browser"}
                  image={resourceAddIcon}
                />
              </Row>
              <br/>
              <Row>
                <Row>
                  <div className="ps-0">
                    <h6> All Browsers </h6>
                  </div>
                </Row>
                <br/>
                <Row>
                  <BrowsersTable 
                    browsers={browsers} 
                    mediaControlHandlers={{
                      handleProductsListDelete: handleBrowsersListDelete,
                      handleProductsListToggleFavorite: handleBrowsersListToggleFavorite
                    }}
                />
                </Row>
              </Row>
            </div>
          </div>
        </Container>
      </>
    );
  }
};







export default Browsers;