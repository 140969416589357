import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Container} from 'react-bootstrap';
import { toast } from 'react-toastify';

import { 
  HighlightedResourceAddButton, 
  HighlightedProduct,
  SelectedCommunicate, 
  ProductDeleteModal,
  CommunicateAddModal,
  CommunicateTable
} from "../../components";

import { 
  authContextHelper, 
  api, 
  helpers,
  variables,
  bsgCrypto
} from '../../utilities';

import {
  resourceAddIcon,
} from "../../assets/images";



const Communicates: React.FunctionComponent = (props: any) => {
  const DEFAULT_PRODUCT_TYPE = "communicate";
  console.log("ALL PROPS: ", props)
  const { setLoaded, urlParameters, currentHistory } = props;
  const id = urlParameters.get("id");

  const auth = useContext(authContextHelper.AuthContext);
  const [communicates, setCommunicates] = useState<any>([]);
  const [selectedCommunicate, setSelectedCommunicate] = useState<any>(null);
  const [selectedCommunicateNotes, setSelectedCommunicateNotes] = useState<any>([]);
  const [isShowDeleteCommunicateModal, setDeleteCommunicateModalShow] = useState(false);
  const [selectedCommunicateStatus, setSelectedCommunicateStatus] = useState("PENDING");
  const [communicatesListDeleteObject, setCommunicatesListDeleteObject] = useState<any>(null);
  const [isShowAddCommunicateModal, setAddCommunicateModalShow] = useState(false);
  const [addCommunicateModalSubmitError, setAddCommunicateModalSubmitError] = useState<any>(null);

  const [groups, setGroups] = useState<any>([]);
  const [profiles, setProfiles] = useState<any>([]);
  const [projects, setProjects] = useState<any>([]);

  const [communicateVaultPasswordSubmitError, setCommunicateVaultPasswordSubmitError] = useState<any>(null);
  const [communicateVaultSecretEditSubmitError, setCommunicateVaultSecretEditSubmitError] = useState<any>(null);

  const [selectedCommunicateVault, setSelectedCommunicateVault] = useState<bsgCrypto.Vault | null>(null);
  const [decryptedVaultKey, setDecryptedVaultKey] = useState<any>(null);
  const [preSelectedSecretId, setPreSelectedSecretId] = useState<string>("");
  

  const handleCommunicateStart = async(event: any) => {
    event.preventDefault();

    const sessionTokens = await auth.getApiSessionTokens();

    await api.startProduct({
      body: {
        id: id
      }, 
      user: sessionTokens
    });

    await getCommunicates();
  }

  const handleIframeMouseOver = async(event: any) => {
    event.preventDefault();
    const iframeDocument = document.getElementById(event.target.id);
    iframeDocument?.focus();
  }

  const handleCommunicateStop = async (event: any) => {
    event.preventDefault();
    const sessionTokens = await auth.getApiSessionTokens();

    await api.stopProduct({
      body: {
        id: id
      }, 
      user: sessionTokens
    });

    await getCommunicates();
  }

  const handleCommunicateFullScreen = (event: any) => {
    event.preventDefault();
  }

  const handleCommunicateDelete = (event: any) => {
    event.preventDefault();

    setDeleteCommunicateModalShow(true)
  }

  const handleCommunicatesListDelete = (event: any, communicatesListDeleteObjectItem: any) => {
    event.preventDefault();

    setDeleteCommunicateModalShow(true)
    setCommunicatesListDeleteObject(communicatesListDeleteObjectItem)
  }

  const handleCommunicateHibernate = async(event: any) => {
    event.preventDefault();

    await api.hibernateProduct({
      body: {
        id: id
      }, 
    });

    await getCommunicates();
  }

  const handleCloseDeleteCommunicateModal = (event: any) => {
    // event.preventDefault();
    setDeleteCommunicateModalShow(false)
  };

  const handleCommunicateDeleteSubmit = async(query: any) => {
    const { result } =  await api.deleteProduct({
      body: {
        id: id
      }, 
    });

    if (result === "Success"){
      handleCloseDeleteCommunicateModal(true);
      currentHistory.push("/communicate");
    }
    // setLoaded(true);
  }

  const handleCommunicateListDeleteSubmit = async() => {
    const { result } =  await api.deleteProduct({
      body: {
        id: communicatesListDeleteObject && communicatesListDeleteObject.id
      }, 
    });

    if (result === "Success"){
      handleCloseDeleteCommunicateModal(true);
      // currentHistory.push("/communicate");
      window.location.reload();
    }
  }

  const handleShowAddCommunicateModal = (event: any) => {
    // event.preventDefault();
    setAddCommunicateModalShow(true)
  };

  const handleCloseAddCommunicateModal = (event: any) => {
    // event.preventDefault();
    setAddCommunicateModalShow(false)
  };

  const handleCommunicateAddSubmit = async(query: any) => {
    console.log("LOG handling PRODUCT ADD SUBMIT : ", query);
    
    const vaultKeys = query.body.security ? 
      await helpers.createVaultKeysForSecurityGroup(query.body.security) : 
      await bsgCrypto.VaultKey.createVaultKey([{username: auth.userProfile.username, publicKey: auth.userKeys!.publicKey}]);

    const submitResponse =  await api.postProduct({
      body: query.body,
      productType: query.productType,
      vaultKeys: vaultKeys
    });

    if ( submitResponse.result === "Failed"){
      setAddCommunicateModalSubmitError(submitResponse.error);

    } else if ( submitResponse.result === "Success"){
      handleCloseAddCommunicateModal(null);
      currentHistory.push(`/communicate?id=${submitResponse.id}`);

    } else {
      console.log("ERROR RESPONSE: Services PUT: ", submitResponse);
    }
  }

  const getCommunicates = async() => {
    setLoaded(false);
    
    if(id){
      const communicateData = await api.getProduct({
        body: {
          id: id
        }, 
      });

      setSelectedCommunicate(communicateData);
      setSelectedCommunicateStatus(communicateData.state.toUpperCase());
      
      await getSelectedCommunicateNotes();
    }

    const communicatesData = await api.getProducts({
      productTypes: [
        variables.DEFAULT_PRODUCT_TYPES.CHAT.API_KEY,
        variables.DEFAULT_PRODUCT_TYPES.VTC.API_KEY,
      ]
    });
    
    setCommunicates(communicatesData);
    setLoaded(true);
  }

  const getCommunicatesStatuses = async () => {
    const sessionTokens = await auth.getApiSessionTokens();

    if(id){
      const communicateData = await api.getProduct({
        body: {
          id: id
        }, 
        user: sessionTokens
      });

      setSelectedCommunicate(communicateData);
      setSelectedCommunicateStatus(communicateData.state.toUpperCase());
    }
  }

  const getGroups = async() => {
    setLoaded(false);
    const groupsData = await api.getSecurityGroups({});

    setGroups(groupsData);
    setLoaded(true);
  }

  const getProfiles = async() => {
    setLoaded(false);

    const profilesData = await api.getProfiles({});

    setProfiles(profilesData);
    setLoaded(true);  
  }

  const getProjects = async() => {
    setLoaded(false);

    const projectsData = await api.getProjects({});

    setProjects(projectsData);
    setLoaded(true);  
  }

  const getSelectedCommunicateNotes = async() => {
    if(id){
      const communicateNotes = await api.getResourceNotes({
        resourceId: id, resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY
      });
      setSelectedCommunicateNotes(communicateNotes && communicateNotes.sort((a: { modified: number; }, b: { modified: number; }) => (a.modified < b.modified) ? 1 : -1));
    }
  }

  const getSelectedCommunicateVault = async() => {
    console.log("Resource Vault: Refresh: 1: ID:, ", id);
    console.log("Resource Vault: Refresh: 1: Dycrypted Vault: ", decryptedVaultKey);

    if(id && decryptedVaultKey){
      const browserVaultData = await api.getResourceVault({
        resourceId: id,
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY,
      });

      console.log("Resource Vault: Refresh: 2: Response: ", browserVaultData);
      console.log("Resource Vault: Refresh: 2: Data: ", browserVaultData.data);

      const browserVaultRefreshed = new bsgCrypto.Vault(browserVaultData.data, decryptedVaultKey);
      console.log("Resource Vault: Refresh: 3: Data Updated: ", browserVaultRefreshed);
      
      setSelectedCommunicateVault(browserVaultRefreshed);
    }
  }

  const handlePutCommunicateSecurityGroupSubmit = async(event: any, query: any) => {
    event.preventDefault();
    const submitResponse =  await api.putResourceSecurityGroup({
      resourceId: selectedCommunicate.id, 
      securityGroupID: query.id,
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY
    });

    if ( submitResponse.result === "Failed"){
      toast.warn(submitResponse.error, {
        position: "top-right",
        autoClose: 8000,
        theme: "light",
        });
    } else if ( submitResponse.result === "Success"){
      // const newName = query.name;
      // setGroupName(newName);
      toast.success('Security Group has been changed successfully', {
        position: "top-right",
        autoClose: 5000,
        theme: "light",
        });
    } else {
      toast.warn(submitResponse.error, {
        position: "top-right",
        autoClose: 8000,
        theme: "light",
        });
    }
  }

  const handlePutCommunicateProfileSubmit = async(event: any, query: any) => {
    event.preventDefault();
    const sessionTokens = await auth.getApiSessionTokens();
    const submitResponse =  await api.putProductProfile({id: selectedCommunicate.id, profileID: query.id, user: sessionTokens});

    if ( submitResponse.result === "Failed"){
      toast.warn(submitResponse.error, {
        position: "top-right",
        autoClose: 8000,
        theme: "light",
        });
    } else if ( submitResponse.result === "Success"){
      toast.success('Profile has been changed successfully', {
        position: "top-right",
        autoClose: 5000,
        theme: "light",
        });
    } else {
      toast.warn(submitResponse.error, {
        position: "top-right",
        autoClose: 8000,
        theme: "light",
        });
    }
  }

  const handlePutCommunicateProjectSubmit = async(event: any, query: any) => {
    event.preventDefault();
    console.log("Attempting to Updated Commuicate's project: TBD...");

    toast.success('Project has been changed successfully', {
      position: "top-right",
      autoClose: 5000,
      theme: "light",
    });
  }

  const handleGetCommunicateNote = async(event: any) => {
    //const ele = document.getElementById('profile-selectednote-editor');
    const noteData = await api.getResourceNote({
      resourceId: id, 
      noteId: event.noteID, 
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY
    });
    
    if(noteData){
      event.editorRef.current.setContent(noteData);
      event.editorRef.current.id = event.noteID;
    }
  }

  const handlePutCommunicateNote = async(event: any) => {
    const noteBody = event.current.getContent();
    const noteTmp = noteBody.replace(/<[^>]*>/g, "")
    const firstLine = noteTmp.split('\n')[0].substring(0, 35);
    const secondLine = noteTmp.split('\n')[1]?.substring(0, 35);

    if (noteTmp.split('\n').length > 1) {
      //const secondLine = noteTmp.split('\n')[1].substring(0, 35);

      await api.putResourceNote({
        resourceId: id, 
        noteId: event.current.id, 
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY,
        body: noteBody,
        headline: secondLine, 
        title: firstLine
      });
    } else {
      await api.putResourceNote({
        resourceId: id, 
        noteId: event.current.id, 
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY,
        body: noteBody,
        title: firstLine
      });
    }

    await getSelectedCommunicateNotes();
  }

  const handlePostCommunicateNote = async(event: any) => {
    await api.postResourceNote({
      resourceId: id,
      body: 'New Note', 
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY, 
      headline: 'New Note', 
      title: 'New Note'
    });

    await getSelectedCommunicateNotes();
  }

  const handleDeleteCommunicateNote = async(noteID: any) => {

    console.log("LOG NOTE handleDeleteProductNote communicate : ", noteID)

    document.getElementById(`notecard-${noteID}`)?.classList.add('flash');
    
    await api.deleteResourceNote({
      resourceId: id, 
      noteId: noteID,
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY, 
    });

    await getSelectedCommunicateNotes();
    document.getElementById(`notecard-${noteID}`)?.classList.remove('flash');
  }

  const handleDycryptVaultSubmit = async(query: any) => {
    const browserVaultData = await api.getResourceVault({
      resourceId: id,
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY
    });

    console.log("Resource Vault: 1: ", browserVaultData.data);
    const base64EncodedVaultKey = browserVaultData.data.key[auth.userProfile.username]!;
    console.log("Resource Vault: 2: ", browserVaultData.data);
    const myVaultKey = bsgCrypto.VaultKey.fromBase64(base64EncodedVaultKey);
    console.log("Resource Vault: 3: ", browserVaultData.data);
    
    const unwrappedVaultKey = await bsgCrypto.UnwrappedVaultKey.fromWrappedVaultKey(
      myVaultKey,
      query.body.password,
      auth.userKeys!.wrappedPrivateKey
    );

    console.log("Resource Vault: 4: ", browserVaultData.data);
    
    if(unwrappedVaultKey){
      setDecryptedVaultKey(unwrappedVaultKey);

      const browserVaultRefreshed = new bsgCrypto.Vault(browserVaultData.data, unwrappedVaultKey);
      console.log("Resource Vault: 5: Data Updated: ", browserVaultRefreshed);
      
      setSelectedCommunicateVault(browserVaultRefreshed);
    } else {
      setCommunicateVaultPasswordSubmitError("Password Error: Enter Valid Password");
    }
  };

  const handlePostCommunicateVaultPassword = async(event: any) => {
    const postResponse = await api.postResourceVaultPassword({
      resourceId: id,
      body: '<h1> New Password </h1>', 
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY, 
      headline: 'New Password', 
      title: 'New Password'
    });

    console.log("Creating new password: 1 : ", postResponse);

    if(postResponse.data && postResponse.data.id){
      const passwordChangeset = await selectedCommunicateVault!.addOrUpdateItemInVault(
        "My new password",
        new bsgCrypto.SecretMetadata(postResponse.data.id, "My New Password Title", "Username")
      );

      console.log("Creating new password: 2 : Changeset: ", passwordChangeset); 

      const passwordChangeResponse = await api.patchResourceVaultPassword({
        resourceId: id, 
        body: passwordChangeset, 
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY, 
      });

      console.log("Creating new password: 3 : Change Patch: ", passwordChangeResponse); 
    }

    await getSelectedCommunicateVault();
  }


  const handleDeleteCommunicateVaultPassword = async(query: any) => {
    console.log("Deleting password: 1 : ", query);

    if(query.body && query.body.secretId){

      const passwordChangeset = await selectedCommunicateVault!.removeItemFromVault(query.body.secretId);

      console.log("Deleting password: 2 : Changeset: ", passwordChangeset); 

      const passwordChangeResponse = await api.patchResourceVaultPassword({
        resourceId: id, 
        body: passwordChangeset, 
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY, 
      });

      console.log("Deleting password: 3 : Change Patch: ", passwordChangeResponse); 

      if (passwordChangeResponse && passwordChangeResponse.result === "Success") {

        const apiResponse = await api.deleteResourceVaultPassword({
          resourceId: id, 
          resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY,
          secretId: query.body.secretId
        });

        console.log("Deleting password: 4 : API Response: ", apiResponse); 
      }
    }

    await getSelectedCommunicateVault();
  }


  const handleEditCommunicateVaultPassword = async(query: any) => {
    console.log("Editing password: 1 : ", query);

    if(query.body && query.body.secretId){
      console.log("Deleting password: 2 : ", query); 
      
      setPreSelectedSecretId(query.body.secretId);
      
      const passwordChangeset = await selectedCommunicateVault!.addOrUpdateItemInVault(query.password, query.body);

      console.log("Deleting password: 3 : Changeset: ", passwordChangeset); 

      const passwordChangeResponse = await api.patchResourceVaultPassword({
        resourceId: id, 
        body: passwordChangeset, 
        resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY, 
      });

      console.log("Deleting password: 4 : Change Patch: ", passwordChangeResponse); 
      
    }

    await getSelectedCommunicateVault();
  }

  const handleCommunicatesListToggleFavorite = async(event: any) => {
    const toggleResponse = await api.toggleResourceFavourite({
      resourceId: event.resourceId, 
      toggleType: event.toggleType, 
      resourceType: variables.DEFAULT_RESOURCE_TYPES.PRODUCT.API_KEY
    });
    console.log("BROWSER: Toggle : ", toggleResponse);

    await getCommunicates();
    await getCommunicatesStatuses();

    toast.success('Favorites have been updated successfully', {
      position: "top-right",
      autoClose: 3000,
      theme: "light",
    });
  }
  


  // On mount: hook effect
  useEffect(() =>{

    // Fetch All Data
    getCommunicates();
    getGroups();
    getProfiles();
    getProjects();
    getSelectedCommunicateNotes();

    // Reset Communicate List Delete
    setCommunicatesListDeleteObject(null)
  },[id]);


  
  helpers.useInterval(() => {
    getCommunicatesStatuses();

  }, 1000 * 10);


  if(id){
    // Check if Selected communicate state value is not null
    // Render Selected communicate with success res object as props, else redirect to 404 page
    
    return (
      <>
        {(selectedCommunicate) && (
          <>
            <ProductDeleteModal
                show={isShowDeleteCommunicateModal}
                handleClose={handleCloseDeleteCommunicateModal}
                handleModalFormSubmit={handleCommunicateDeleteSubmit}
                name={selectedCommunicate.name}
                productType={DEFAULT_PRODUCT_TYPE}
              />

            <SelectedCommunicate
              communicateDetails={selectedCommunicate} 
              communicateNotes={selectedCommunicateNotes}
              communicateStatus={selectedCommunicateStatus}
              productType={DEFAULT_PRODUCT_TYPE}
              
              groups={groups}
              projects={projects}
              profiles={profiles}
              communicateVault={selectedCommunicateVault}

              communicateVaultPasswordSubmitError={communicateVaultPasswordSubmitError}
              communicateVaultSecretEditSubmitError={communicateVaultSecretEditSubmitError}
              decryptedVaultKey={decryptedVaultKey}
              preSelectedSecretId={preSelectedSecretId}

              toggleHandlers={{
                handlePutCommunicateSecurityGroupSubmit,
                handlePutCommunicateProfileSubmit,
                handlePutCommunicateProjectSubmit,
              }}

              mediaControlHandlers={{
                handleProductStart: handleCommunicateStart,
                handleProductStop: handleCommunicateStop,
                handleProductHibernate: handleCommunicateHibernate,
                handleProductFullScreen: handleCommunicateFullScreen,
                handleProductDelete: handleCommunicateDelete,
                handleIframeMouseOver
              }}

              productNoteHandlers={{
                handleGetProductNote: handleGetCommunicateNote,
                handlePutProductNote: handlePutCommunicateNote,
                handlePostProductNote: handlePostCommunicateNote,
                handleDeleteProductNote: handleDeleteCommunicateNote
              }}

              productVaultHandlers={{
                handleDycryptVaultSubmit: handleDycryptVaultSubmit,
                handlePostProductVaultPassword: handlePostCommunicateVaultPassword,
                handleDeleteProductVaultPassword: handleDeleteCommunicateVaultPassword,
                handleEditProductVaultPassword: handleEditCommunicateVaultPassword
              }}
            />
        </>
        
        )}
      </>
    )
  } else {
    return (
      <>
      <ProductDeleteModal
        show={isShowDeleteCommunicateModal}
        handleClose={handleCloseDeleteCommunicateModal}
        handleModalFormSubmit={handleCommunicateListDeleteSubmit}
        name={communicatesListDeleteObject && communicatesListDeleteObject.name}
        productType={DEFAULT_PRODUCT_TYPE}
      />

      <CommunicateAddModal
        show={isShowAddCommunicateModal}
        submitError={addCommunicateModalSubmitError}
        handleClose={handleCloseAddCommunicateModal}
        handleModalFormSubmit={handleCommunicateAddSubmit}
        securityGroups={groups}
        profiles={profiles}
        projects={projects}
      />

      <div>
        <Row>
          <Col md={5}><h1> Communicate Overview </h1></Col>
        </Row>
        <h5>Hello {auth.userProfile.username}, all of your communication products are shown below.</h5>
      </div>

      <Container className="ps-3 ms-0 pt-3"> 
        <div className="pt-0">
          <div>
            <Row>
              {communicates && communicates.map((communicate: any, index: number) => {
                return (
                  <HighlightedProduct key={`HR-${index}`}
                    header={communicate.name}
                    description={communicate.description}
                    linkPath={`/communicate?id=${communicate.id}`}
                    status={communicate.state}
                    product={communicate.product}
                    system={communicate.system}
                  />
                )
              })}
            </Row>
            <Row>
              <HighlightedResourceAddButton
                clickHandler={handleShowAddCommunicateModal}
                header={"Add Service"}
                description={"Create a comms service"}
                image={resourceAddIcon}
              />
            </Row>
            <br/>
            <Row>
              <Row>
                <div className="ps-0">
                  <h6> All Communication Services </h6>
                </div>
              </Row>

              <Row>
                <CommunicateTable 
                  products={communicates} 
                  mediaControlHandlers={{
                    handleProductsListDelete: handleCommunicatesListDelete,
                    handleProductsListToggleFavorite: handleCommunicatesListToggleFavorite
                  }}
              />
              </Row>
            </Row>
          </div>
        </div>
      </Container>
    </>
    );
  }
};







export default Communicates;