import React, {useState, useContext} from 'react';
import { useLocation, useHistory, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Nav, Image, Navbar, Dropdown, Container, ListGroup, NavDropdown, Badge } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { CSSTransition } from 'react-transition-group';

import { authContextHelper, api, bsgCrypto } from './../../utilities';


import {
  homeNavIcon,
  projectsNavIcon,
  profilesNavIcon,
  communicateNavIcon,
  userProfileNavIcon,
  settingsNavIcon, 
  desktopsNavIcon, 
  messagesNavIcon, 
  browsersNavIcon, 
  signoutNavIcon, 
  quickLaunchNavIcon, 
  quickTimeNavIcon, 
  browserAppIcon, 
  desktopAppIcon, 
  messagingAppIcon,
} from "./../../assets/images";



const ProtectedNavBar: React.FunctionComponent<{ handleSignout: any, handleChangePassword:any }> = ({handleSignout, handleChangePassword}) => {

  return (<>
  <div>
    <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="/"></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/">Link</Nav.Link>
              <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
          
          <Nav className="justify-content-end">
            <Nav.Item>
              <Nav.Link href="/home">Active</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="link-1">Link</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="link-2">Link</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="disabled" disabled>
                Disabled
              </Nav.Link>
            </Nav.Item>
            <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Profile</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item onClick={handleChangePassword}>Change Password</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={handleSignout}>
                  Sign Out
                </NavDropdown.Item>
              </NavDropdown>
          </Nav>
        </Container>
      </Navbar>
    </div>
    </>
  )
}


const OpenNavBar: React.FunctionComponent = () => (
  <><div>
    LEFT Nav AREA
    </div></>
)

const SearchArea: React.FunctionComponent = () => (
  <><div>
  Search Area: Logged in
    </div></>
)

const PreLoader: React.FunctionComponent<any> = ({ show }) => {

  return (
    <div className={`k-preloader bg-soft flex-column justify-content-center align-items-center ${show ? "loading" : "show"}`}>
      {/* <Image className="loader-element" src={loadingIcon} height={100}/> */}
    </div>
  );
};




const Sidebar: React.FunctionComponent<any> =  () => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";

  // const onCollapse = () => setShow(!show);

  // const CollapsableNavItem = (props: any) => {
  //   const { eventKey, title, icon, children = null } = props;
  //   const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

  //   return (
  //     <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
  //       <Accordion.Item eventKey={eventKey}>
  //         <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center">
  //           <span>
  //             {/* <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> */}
  //             <span className="sidebar-icon kuro-sidebar-icon"><img src={icon} alt={`${title}-img`}/> </span>
  //             {/* <span className="sidebar-text">{title }</span> */}
  //           </span>
  //         </Accordion.Button>
  //         <Accordion.Body className="multi-level">
  //           <Nav className="flex-column">
  //             {children}
  //           </Nav>
  //         </Accordion.Body>
  //       </Accordion.Item>
  //     </Accordion>
  //   );
  // };

  const KuroNavLinkItem = (props: any) => {
    const { 
      // title, 
      disabled,
      link, 
      // external, 
      target, 
      icon, image, badgeText, badgeColor = "primary" } = props;
    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between k-sidenav" : "k-sidenav";
    const isDisabledClassname = disabled ? "disabled " : null;
    const imageClassNames = disabled ? "sidebar-icon svg-icon k-opacityLight" : "sidebar-icon svg-icon k-side-nav-image";
    const navItemClassName = link === pathname ? "active" : "";
    // const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}  >
        <Nav.Link 
          as={Link}
          to={link}
          // {...linkProps} 
          target={target} className={`${classNames} kuro-nav-container ${isDisabledClassname}`}>
          <span>
            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
            {image ? <img src={image} className={imageClassNames} alt='Link img' /> : null}
            {/* <span className="sidebar-text">{title}</span> */}
          </span>
          {badgeText ? (
            <Badge pill text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white noShowScroll`}>
          <div className="sidebar-inner px-3 pt-6">
            
            <Nav className="flex-column pt-3 pt-md-0">
              
              <KuroNavLinkItem link="/" title="" image={homeNavIcon}/>
              <Dropdown.Divider className="my-3" />

              <KuroNavLinkItem link="/projects" title="Projects" image={projectsNavIcon} />
              <Dropdown.Divider className="my-3" />
              
              <KuroNavLinkItem link="/profiles" title="Profiles" image={profilesNavIcon} />
              <Dropdown.Divider className="my-3" />

              <KuroNavLinkItem link="/desktops" title="Desktops" image={desktopsNavIcon} />
              <Dropdown.Divider className="my-3" />

              <KuroNavLinkItem link="/browsers" title="Browsers" image={browsersNavIcon} />
              <Dropdown.Divider className="my-3" />

              <KuroNavLinkItem link="/communicate" title="Communicate" image={communicateNavIcon} />
              <Dropdown.Divider className="my-3" />

              
              {
                // JDD Removed for Demo

                //<KuroNavLinkItem link="/projects" title="Projects" image={projectsNavIcon} />
                //<Dropdown.Divider className="my-3" />

                //<KuroNavLinkItem disabled link="/messages" title="Messages" image={messagesNavIcon} />
                //<Dropdown.Divider className="my-3" />
              }

              <KuroNavLinkItem link="/admin" title="Admin" image={settingsNavIcon} />
              <Dropdown.Divider className="my-3" />

              {/* <CollapsableNavItem eventKey="projects" linkPath="/projects" title="Projects" icon={projectsNavIcon}>
                <KuroNavLinkItem title="All Projects" link="/projects" />
                <KuroNavLinkItem title="Investigations" link="/projects/investigations" />
                <KuroNavLinkItem title="Pentests" link="/projects/pentests" />
                <KuroNavLinkItem title="Generic Projects" link="/projects/generic" />
              </CollapsableNavItem> */}
              
              {/* <Dropdown.Divider className="my-3 border-indigo" /> */}


            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};




const SearchNavBar: React.FunctionComponent<any> = (props: any)=> {
  // const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA);
  // const areNotificationsRead = notifications.reduce((acc, notif) => acc && notif.read, true);
  const appRouterHistory = useHistory()

  // const markNotificationsAsRead = () => {
  //   setTimeout(() => {
  //     setNotifications(notifications.map(n => ({ ...n, read: true })));
  //   }, 300);
  // };

  const auth = useContext(authContextHelper.AuthContext)

  const handleSignoutClick = (event: any) => {
    event.preventDefault();
    auth.signOut()
    appRouterHistory.push('/');
  }

  const handleQuickLaunch = async(query: any) => {
    //const sessionTokens = await auth.getApiSessionTokens();
    const randomNumber = Math.floor(Math.random() * 9000) + 1000;
    const vaultKeys = await bsgCrypto.VaultKey.createVaultKey([{username: auth.userProfile.username, publicKey: auth.userKeys!.publicKey}]);
    let createProductBody;

    const newDesktopBody: any = {
        "name": "Quick Launch " + randomNumber,
        "location": "eu-west-2",
        "system": query.system,
        "vault_keys": vaultKeys,
        "productUsername": "quicklaunch",
      
    }

    const newBrowserBody: any = {
        "name": "Quick Launch " + randomNumber,
        "location": "eu-west-2",
        "system": query.system,
        "vault_keys": vaultKeys,
    }

    switch(query.productType){
      case "browser":
        createProductBody = newBrowserBody;
        break;
      
      case "vm":
        createProductBody = newDesktopBody;
        break;

      default:
        createProductBody = null;
        break;
    };
    
    const submitResponse =  await api.postProduct({
      body: createProductBody, 
      productType: query.productType,
      vaultKeys: vaultKeys
    });

    if ( submitResponse.result === "Failed"){
      console.log("Error: res", submitResponse)
    } else if ( submitResponse.result === "Success"){
      //appRouterHistory.push(`/${query.productType}?id=${submitResponse.id}`);
      appRouterHistory.push(`/`);
    } else {
      console.log("ERROR RESPONSE: PRODUCTS PUT: ", submitResponse);
    }
  }

  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
           
          </div>
          <Nav className="align-items-center">
            
            <Dropdown as={Nav.Item} style={{ right: "30%"}}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <Image src={quickLaunchNavIcon} className="user-avatar md-avatar k-opacityLight" />
                  {/*<div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    //<span className="mb-0 font-small fw-bold">{auth.userProfile.given_name + " " + auth.userProfile.family_name}</span>
                  </div>*/}
                </div>
              </Dropdown.Toggle>
              
              <Dropdown.Menu align="start" className="user-dropdown dropdown-menu-right mt-2" style={{width: "300px"}}>
                <Container>
                  <Row>
                    <Col md={8}>
                      <h6 style={{marginBottom: "0"}}>Quick Launch</h6>
                    </Col>
                    <Col md={4}>
                      <Row>
                        <Col md={6}>
                        </Col>
                        <Col md={6}>
                          <Link to="/hello">
                            <img src={quickTimeNavIcon} alt={"Signout"} className="sidebar-icon svg-icon " />
                          </Link>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <ListGroup horizontal>
                      <ListGroup.Item>
                        <Link to={"/browser-app"}>
                          <img src={browserAppIcon} alt={"Browser"} className="sidebar-icon svg-icon " onClick={() => handleQuickLaunch({ productType: "browser", system: "edge" })}/>
                        </Link>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Link to={"/"} onClick={props.handleShowModal}>
                          <img src={desktopAppIcon} alt={"Desktop"} className="sidebar-icon svg-icon " onClick={() => handleQuickLaunch({ productType: "vm", system: "windows" })} />
                        </Link>
                      </ListGroup.Item>
                    </ListGroup>

                  </Row>
                </Container>
              </Dropdown.Menu>
            </Dropdown>
            
            
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <Image src={userProfileNavIcon} className="user-avatar md-avatar" />
                </div>
              </Dropdown.Toggle>
              
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2" style={{width: "300px"}}>
                <Container>
                  <Row>
                    <Col md={8}>
                      <h6 style={{marginBottom: "0"}}>{auth.userProfile.username}</h6>
                      <small style={{fontSize: "0.75rem"}}>Last login: 00-00-00</small>
                    </Col>

                    <Col md={4}>
                      <Row>
                        <Col md={6}>
                            <img src={settingsNavIcon} alt={"Settings"} className="sidebar-icon svg-icon k-fade" style={{filter: "invert(54%) sepia(5%) saturate(10%) hue-rotate(346deg) brightness(92%) contrast(93%)"}} />
                        </Col>

                        <Col md={6}>
                          <Link to={""}>
                            <img src={signoutNavIcon} alt={"Signout"} className="sidebar-icon svg-icon "  onClick={handleSignoutClick}/>
                          </Link>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};



export {
  ProtectedNavBar,
  OpenNavBar,
  SearchArea,
  PreLoader,
  SearchNavBar,
  Sidebar
};