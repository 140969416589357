
import React from 'react';
import { Col, Row, Dropdown} from 'react-bootstrap';

import {
  identityIconTwo,
} from "./../../assets/images";


import { 
  ProfileContentPane,
  ProfileLinkedProductsPane, 
  SelectGroupDropdownMenu,
  SelectGroupDropdownToggle,
  SelectProjectDropdownMenu,
  SelectProjectDropdownToggle,
} from './../';



const Profile: React.FunctionComponent<any> = (props:any) => {
  const {
    profileLinkedProducts,
    groups,
    projects,
    profileDetails,
    profileNotes, 
    profileContentActiveProducts,
    handleProfileContentPaneTabsToggle,
    handleProfileLinkedProductSelected,
    profileContentPaneTabsActiveKey, 
    handleProfileEditSecurityGroup,
    handleProfileEditProject,
    profileNoteHandlers,

    // Vault Handlers
    profileVaultHandlers,

    // Tab Handlers

    // Modal Handlers
    profileModalHandlers
  } = props;

  console.log("PROFILE Details : ", profileDetails);
  console.log("PROFILE Products : ", profileLinkedProducts);


  return (
    <>
      <Row className="ps-3">
        <Col className="col-6">
          <Row className="pb-3">
            <Col className="col-1 px-0">
              <img src={identityIconTwo} alt={"Icon"} className="width-90-PC k-mh-60"/>
            </Col>
            <Col className="col-11 ps-3">
              <Row>
                <span className="k-detailHeader p-0">{profileDetails && profileDetails.name}</span>
              </Row>
              <Row>
                <span className="k-detailSubHeader p-0">{profileDetails && profileDetails.description}</span>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col >
              <Row className="k-inputText15">
                Security Group
              </Row>
              <Row className="disabled-text">
                <Dropdown>
                  <Dropdown.Toggle as={SelectGroupDropdownToggle}>
                    <Row>
                      <Col className="col-11 p-0 ">
                        { (groups && profileDetails && profileDetails.security && (profileDetails.security !== "NULL")) ? 
                          groups.find((group: any)  => group.id === profileDetails.security)?.name : 
                          "----"
                        }
                      </Col>
                    </Row>
                  </Dropdown.Toggle>

                  <Dropdown.Menu as={SelectGroupDropdownMenu} >
                    {groups && groups.map((group: any, index: number) => {
                      return (
                        <Dropdown.Item 
                          className="k-inputText12" 
                          key={`paneUserEditForm.Dropdown.${index}`} 
                          onClick={(event: any) => handleProfileEditSecurityGroup(event, group)}
                        >
                            {group.name}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Row>
            </Col>
            <Col >
              <Row className="k-inputText15">
                Linked Project
              </Row>
              <Row className="disabled-text">
                <Dropdown>
                  <Dropdown.Toggle as={SelectProjectDropdownToggle}>
                    <Row>
                      <Col className="col-11 p-0 ">
                        { (projects && profileDetails && profileDetails.project && (profileDetails.project !== "NULL")) ? 
                          projects.find((project: any)  => project.id === profileDetails.project)?.name : 
                          "----"
                        }
                      </Col>
                    </Row>
                  </Dropdown.Toggle>

                  <Dropdown.Menu as={SelectProjectDropdownMenu} >
                    {projects && projects.map((project: any, index: number) => {
                      return (
                        <Dropdown.Item 
                          className="k-inputText12" 
                          key={`paneUserEditForm.Dropdown.${index}`} 
                          onClick={(event: any) => handleProfileEditProject(event, project)}
                        >
                            {project.name}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Row>
            </Col>
            <Col >
              
            </Col>
          </Row>
        </Col>
        <Col className="col-6 pt">
          <ProfileLinkedProductsPane
            handleProfileLinkedProductSelected={handleProfileLinkedProductSelected}
            
            products={profileLinkedProducts}

            // Modal Controls: Profile Products
            modalHandlers={profileModalHandlers}
          />
        </Col>
      </Row>

      <Row className="">
        <Col>
          <ProfileContentPane
            profileContentActiveProducts={profileContentActiveProducts}
            handleProfileContentPaneTabsToggle={handleProfileContentPaneTabsToggle}
            profileContentPaneTabsActiveKey={profileContentPaneTabsActiveKey}
            profileDetails={profileDetails}
            profileNoteHandlers={profileNoteHandlers}
            profileVaultHandlers={profileVaultHandlers}
            profileNotes={profileNotes}
            profileProducts={profileLinkedProducts}
          />
        </Col>
      </Row>
    </>
  );
};



export default Profile;

