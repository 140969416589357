
import React from 'react';
import { Col, Row, Dropdown } from 'react-bootstrap';

import {
  hideContentBtn,
  retractContentBtn,
  expandContentBtn,
  openMenuDownBtn,
  infoIcon
} from "./../../assets/images";


/**
 * TEMP: Import Other Resource Pages
 */
import Project from "./project";
import Profile from "./profile";

import { 
  ProductControlPanel, 
  ProductContentPane,
  BrowserLogoImage, 
  DesktopLogoImage,
  CommunicateLogoImage,
  ProductToolsPanel,
  SelectGroupDropdownMenu,
  SelectGroupDropdownToggle,
  SelectProjectDropdownMenu,
  SelectProjectDropdownToggle,
  SelectProfileDropdownMenu,
  SelectProfileDropdownToggle,
} from './../';


import { 
  helpers,
} from './../../utilities';




const SelectedDesktop: React.FunctionComponent<any> = (props:any) => {
  const {
    desktopDetails, 
    mediaControlHandlers, 
    desktopStatus, 
    productType,
    desktopNotes, 
    groups, 
    profiles,
    projects,
    desktopVault,
    desktopVaultPasswordSubmitError,
    desktopVaultSecretEditSubmitError,
    decryptedVaultKey,
    preSelectedSecretId,
    productNoteHandlers,
    productVaultHandlers,
    toggleHandlers,
  } = props;

  const lowercaseProductType = productType && productType.toLowerCase();

  console.log("Desktop: Vault :", desktopVault);
  console.log("Desktop: Decrypted Vault Key", decryptedVaultKey);
  console.log("Desktop: Preselected Id", preSelectedSecretId);
  console.log("Desktop: Password Submit Error", desktopVaultPasswordSubmitError);
  console.log("Desktop: Secret Edit Submit Errors", desktopVaultSecretEditSubmitError);

  return (
    <>
      <Row className="ps-3">
        <Col className="col-6">
          <Row className="pb-3">
            <Col className="col-1 px-0">
              <DesktopLogoImage
                system={desktopDetails.system}
                classOverride="width-90-PC"
              />
            </Col>
            <Col className="col-11 ps-3">
              <Row>
                <span className="k-detailHeader p-0">{desktopDetails.name}</span>
              </Row>
              <Row>
                <span className="k-detailSubHeader p-0">{desktopDetails.description}</span>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col >
              <Row className="k-inputText15">
                Security Group
              </Row>
              <Row className="disabled-text">
                <Dropdown>
                  <Dropdown.Toggle as={SelectGroupDropdownToggle}>
                    <Row>
                      <Col className="col-11 p-0 ">
                        { (groups && desktopDetails && desktopDetails.security && (desktopDetails.security !== "NULL")) ? 
                          groups.find((group: any)  => group.id === desktopDetails.security)?.name : 
                          "----"
                        }
                      </Col>
                    </Row>
                  </Dropdown.Toggle>

                  <Dropdown.Menu as={SelectGroupDropdownMenu} >
                    {groups && groups.map((group: any, index: number) => {
                      return (
                        <Dropdown.Item 
                          className="k-inputText12" 
                          key={`paneGroupEditForm.Dropdown.${index}`} 
                          onClick={(event: any) => toggleHandlers.handlePutDesktopSecurityGroupSubmit(event, group)}
                        >
                          {group.name}
                        </Dropdown.Item>
                      )
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Row>
            </Col>
            <Col >
              <Row className="k-inputText15">
                Linked Project
              </Row>
              <Row className="disabled-text">
                {/* {desktopDetails.project  || "----"} */}
                <Dropdown>
                  <Dropdown.Toggle as={SelectProjectDropdownToggle}>
                    <Row>
                      <Col className="col-11 p-0 ">
                        { (projects && desktopDetails && desktopDetails.project && (desktopDetails.project !== "NULL")) ? 
                          projects.find((project: any)  => project.id === desktopDetails.project)?.name : 
                          "----"
                        }
                      </Col>
                    </Row>
                  </Dropdown.Toggle>

                  <Dropdown.Menu as={SelectProjectDropdownMenu} >
                    {projects && projects.map((project: any, index: number) => {
                      return (
                        <Dropdown.Item 
                          className="k-inputText12" 
                          key={`paneProjectEditForm.Dropdown.${index}`} 
                          onClick={(event: any) => toggleHandlers.handlePutDesktopProjectSubmit(event, project)}
                        >
                          {project.name}
                        </Dropdown.Item>
                      )
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Row>
            </Col>
            <Col >
              <Row className="k-inputText15">
                Linked Profile
              </Row>
              <Row className="disabled-text">
                <Dropdown>
                  <Dropdown.Toggle as={SelectProfileDropdownToggle}>
                    <Row>
                      <Col className="col-11 p-0 ">
                        { (profiles && desktopDetails && desktopDetails.profile && (desktopDetails.profile !== "NULL")) ? 
                          profiles.find((profile: any)  => profile.id === desktopDetails.profile)?.name : 
                          "----"
                        }
                      </Col>
                    </Row>
                  </Dropdown.Toggle>

                  <Dropdown.Menu as={SelectProfileDropdownMenu} >
                    {profiles && profiles.map((object: any, index: number) => {
                      return (
                        <Dropdown.Item 
                          className="k-inputText12" 
                          key={`paneProfileEditForm.Dropdown.${index}`} 
                          onClick={(event: any) => toggleHandlers.handlePutDesktopProfileSubmit(event, object)}
                        >
                          {object.name}
                        </Dropdown.Item>)
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col className="col-6 pt">
          <Row className="k-bb pt-4 mt-2">
            <span className="k-detailControlHeader p-0">Desktop Details</span>
          </Row>
          <Row className="pt-2">
            <Col className="">
              <Row className=""><span className="k-inputText15 p-0">Status</span></Row>
              <Row className=""><span className={`k-inputText15 p-0 ${ desktopStatus }`}>{desktopStatus.toUpperCase()}</span></Row>
            </Col>
            <Col>
              <Row className="p-0"><span className="k-inputText15 p-0">Desktop Control</span></Row>
              <Row className="p-0"><ProductControlPanel  medialControlHandlers={mediaControlHandlers} desktopStatus={desktopStatus} iFrameID={`fullscreen-${desktopDetails.id}`}/></Row>
            </Col>
            <Col>
            <Row className="p-0"><span className="k-inputText15 p-0">Location</span></Row>
              <Row className="p-0"><span className="k-inputText15 p-0">{helpers.getReadableProductLocation(desktopDetails.region)}</span></Row>
            </Col>
            <Col>
            <Row className="p-0"><span className="k-inputText15 p-0">IP Address</span></Row>
              <Row className="p-0"><span className="k-inputText15 p-0">{desktopDetails.ipv4  || "----"}</span></Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="pt-4">
        <Col>
          <div id={`fullscreen-${desktopDetails.id}`} className="fullScreenContainer">
            <Row id="aa" xs="auto" className="toolbar">
              <Col className="pe-0"><img id="dd" src={hideContentBtn} alt={"Hide Content"} className="toobarButton pe-1" onClick={helpers.hideContent}/></Col>
              <Col className="ps-1 pe-0"><img id="ee" src={retractContentBtn} alt={"Redact"} className="toobarButton pe-1" onClick={helpers.exitFullScreen}/></Col>
              <Col className="ps-1 pe-0 col-auto me-auto"><img id="ff" src={expandContentBtn} alt={"Expand"} className="toobarButton pe-1" onClick={() => helpers.enterFullScreen(`fullscreen-${desktopDetails.id}`)}/></Col>
              <Col className="col-auto"><img id="gg" src={openMenuDownBtn} alt={"Dopdown"} className="toobarButton" onClick={() => helpers.showDropDown(`dropdown-${desktopDetails.id}`)}/></Col>
            </Row>

            <ProductContentPane
              productDetails={desktopDetails} 
              mediaControlHandlers={mediaControlHandlers} 
              productType={productType}
            />

            <Row id="priv-content" className="overlayBase justify-content-md-center ">
              <Col md="auto" className="k-iFrameCenter">
                <Row>
                  <Col className="col-3 pt-2">
                    <img src={infoIcon} alt={"Icon"} className="k-detailHeaderImg k-statusImg"/>
                  </Col>
                  <Col className="ps-0">
                    <h1>Locked</h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h4>Your {lowercaseProductType} has been locked</h4>
                  </Col>
                </Row>
              </Col>
            </Row>

            <ProductToolsPanel
              productDetails={desktopDetails}
              notes={desktopNotes}
              vault={desktopVault}
              decryptedVaultKey={decryptedVaultKey}
              preSelectedSecretId={preSelectedSecretId}

              vaultPasswordSubmitError={desktopVaultPasswordSubmitError}
              vaultSecretEditSubmitError={desktopVaultSecretEditSubmitError}

              vaultHandlers={productVaultHandlers}
              noteHandlers={productNoteHandlers}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};


const SelectedCommunicate: React.FunctionComponent<any> = (props:any) => {
  const {
    communicateDetails, 
    mediaControlHandlers, 
    communicateStatus, 
    productType,
    communicateNotes, 
    groups, 
    profiles,
    projects,
    communicateVault,
    communicateVaultPasswordSubmitError,
    communicateVaultSecretEditSubmitError,
    decryptedVaultKey,
    preSelectedSecretId,
    productNoteHandlers,
    productVaultHandlers,
    toggleHandlers
  } = props;

  const lowercaseProductType = productType && productType.toLowerCase();

  console.log("Desktop: Vault :", communicateVault);
  console.log("Desktop: Decrypted Vault Key", decryptedVaultKey);
  console.log("Desktop: Preselected Id", preSelectedSecretId);
  console.log("Desktop: Password Submit Error", communicateVaultPasswordSubmitError);
  console.log("Desktop: Secret Edit Submit Errors", communicateVaultSecretEditSubmitError);

  return (
    <>
      <Row className="ps-3">
        <Col className="col-6">
          <Row className="pb-3">
            <Col className="col-1 px-0">
              <CommunicateLogoImage
                system={communicateDetails.system}
                classOverride="width-90-PC"
              />
            </Col>
            <Col className="col-11 ps-3">
              <Row>
                <span className="k-detailHeader p-0">{communicateDetails.name}</span>
              </Row>
              <Row>
                <span className="k-detailSubHeader p-0">{communicateDetails.description}</span>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col >
              <Row className="k-inputText15">
                Security Group
              </Row>
              <Row className="disabled-text">
                <Dropdown>
                  <Dropdown.Toggle as={SelectGroupDropdownToggle}>
                    <Row>
                      <Col className="col-11 p-0 ">
                        { (groups && communicateDetails && communicateDetails.security && (communicateDetails.security !== "NULL")) ? 
                          groups.find((group: any)  => group.id === communicateDetails.security)?.name : 
                          "----"
                        }
                      </Col>
                    </Row>
                  </Dropdown.Toggle>

                  <Dropdown.Menu as={SelectGroupDropdownMenu} >
                    {groups && groups.map((group: any, index: number) => {
                      return (
                        <Dropdown.Item 
                          className="k-inputText12" 
                          key={`paneGroupEditForm.Dropdown.${index}`} 
                          onClick={(event: any) => toggleHandlers.handlePutCommunicateSecurityGroupSubmit(event, group)}
                        >
                          {group.name}
                        </Dropdown.Item>
                      )
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Row>
            </Col>
            <Col >
              <Row className="k-inputText15">
                Linked Project
              </Row>
              <Row className="disabled-text">
                <Dropdown>
                  <Dropdown.Toggle as={SelectProjectDropdownToggle}>
                    <Row>
                      <Col className="col-11 p-0 ">
                        { (projects && communicateDetails && communicateDetails.project && (communicateDetails.project !== "NULL")) ? 
                          projects.find((project: any)  => project.id === communicateDetails.project)?.name : 
                          "----"
                        }
                      </Col>
                    </Row>
                  </Dropdown.Toggle>

                  <Dropdown.Menu as={SelectProjectDropdownMenu} >
                    {projects && projects.map((project: any, index: number) => {
                      return (
                        <Dropdown.Item 
                          className="k-inputText12" 
                          key={`paneProjectEditForm.Dropdown.${index}`} 
                          onClick={(event: any) => toggleHandlers.handlePutCommunicateProjectSubmit(event, project)}
                        >
                          {project.name}
                        </Dropdown.Item>
                      )
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Row>
            </Col>
            <Col >
              <Row className="k-inputText15">
                Linked Profile
              </Row>
              <Row className="disabled-text">
                <Dropdown>
                  <Dropdown.Toggle as={SelectProfileDropdownToggle}>
                    <Row>
                      <Col className="col-11 p-0 ">
                        { (profiles && communicateDetails && communicateDetails.profile && (communicateDetails.profile !== "NULL")) ? 
                          profiles.find((profile: any)  => profile.id === communicateDetails.profile)?.name : 
                          "----"
                        }
                      </Col>
                    </Row>
                  </Dropdown.Toggle>

                  <Dropdown.Menu as={SelectProfileDropdownMenu} >
                    {profiles && profiles.map((object: any, index: number) => {
                      return (
                        <Dropdown.Item 
                          className="k-inputText12" 
                          key={`paneProfileEditForm.Dropdown.${index}`} 
                          onClick={(event: any) => toggleHandlers.handlePutCommunicateProfileSubmit(event, object)}
                        >
                          {object.name}
                        </Dropdown.Item>)
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col className="col-6 pt">
          <Row className="k-bb pt-4 mt-2">
            <span className="k-detailControlHeader p-0">Service Details</span>
          </Row>
          <Row className="pt-2">
            <Col className="">
              <Row className=""><span className="k-inputText15 p-0">Status</span></Row>
              <Row className=""><span className={`k-inputText15 p-0 ${ communicateStatus }`}>{communicateStatus.toUpperCase()}</span></Row>
            </Col>
            <Col>
              <Row className="p-0"><span className="k-inputText15 p-0">Service Control</span></Row>
              <Row className="p-0">
                <ProductControlPanel  
                  medialControlHandlers={mediaControlHandlers} 
                  desktopStatus={communicateStatus} 
                  iFrameID={`fullscreen-${communicateDetails.id}`}
                />
              </Row>
            </Col>
            <Col>
            <Row className="p-0"><span className="k-inputText15 p-0">Location</span></Row>
              <Row className="p-0"><span className="k-inputText15 p-0">{helpers.getReadableProductLocation(communicateDetails.region)}</span></Row>
            </Col>
            <Col>
            <Row className="p-0"><span className="k-inputText15 p-0">IP Address</span></Row>
              <Row className="p-0"><span className="k-inputText15 p-0">{communicateDetails.ipv4  || "----"}</span></Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="pt-4">
        <Col>
          <div id={`fullscreen-${communicateDetails.id}`} className="fullScreenContainer">
            <Row id="aa" xs="auto" className="toolbar">
              <Col className="pe-0"><img id="dd" src={hideContentBtn} alt={"Hide Content"} className="toobarButton pe-1" onClick={helpers.hideContent}/></Col>
              <Col className="ps-1 pe-0"><img id="ee" src={retractContentBtn} alt={"Redact"} className="toobarButton pe-1" onClick={helpers.exitFullScreen}/></Col>
              <Col className="ps-1 pe-0 col-auto me-auto"><img id="ff" src={expandContentBtn} alt={"Expand"} className="toobarButton pe-1" onClick={() => helpers.enterFullScreen(`fullscreen-${communicateDetails.id}`)}/></Col>
              <Col className="col-auto"><img id="gg" src={openMenuDownBtn} alt={"Dopdown"} className="toobarButton" onClick={() => helpers.showDropDown(`dropdown-${communicateDetails.id}`)}/></Col>
            </Row>

            <ProductContentPane
              productDetails={communicateDetails} 
              mediaControlHandlers={mediaControlHandlers} 
              productType={productType}
            />

            <Row id="priv-content" className="overlayBase justify-content-md-center ">
              <Col md="auto" className="k-iFrameCenter">
                <Row>
                  <Col className="col-3 pt-2">
                    <img src={infoIcon} alt={"Icon"} className="k-detailHeaderImg k-statusImg"/>
                  </Col>
                  <Col className="ps-0">
                    <h1>Locked</h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h4>Your {lowercaseProductType} has been locked</h4>
                  </Col>
                </Row>
              </Col>
            </Row>

            <ProductToolsPanel
              productDetails={communicateDetails}
              notes={communicateNotes}
              vault={communicateVault}
              decryptedVaultKey={decryptedVaultKey}
              preSelectedSecretId={preSelectedSecretId}

              vaultPasswordSubmitError={communicateVaultPasswordSubmitError}
              vaultSecretEditSubmitError={communicateVaultSecretEditSubmitError}

              vaultHandlers={productVaultHandlers}
              noteHandlers={productNoteHandlers}
            />

          </div>
        </Col>
      </Row>
    </>
  );
};


const SelectedBrowser: React.FunctionComponent<any> = (props:any) => {
  const {
    browserDetails, 
    mediaControlHandlers, 
    browserStatus, 
    productType, 
    browserNotes,
    groups, 
    profiles, 
    projects,
    browserVault,
    browserVaultPasswordSubmitError,
    browserVaultSecretEditSubmitError,
    decryptedVaultKey,
    preSelectedSecretId,
    productNoteHandlers,
    productVaultHandlers,
    toggleHandlers
  } = props;

  const lowercaseProductType = productType && productType.toLowerCase();

  console.log("Desktop: Vault :", browserVault);
  console.log("Desktop: Decrypted Vault Key", decryptedVaultKey);
  console.log("Desktop: Preselected Id", preSelectedSecretId);
  console.log("Desktop: Password Submit Error", browserVaultPasswordSubmitError);
  console.log("Desktop: Secret Edit Submit Errors", browserVaultSecretEditSubmitError);

  return (
    <>
      <Row className="ps-3">
        <Col className="col-6">
          <Row className="pb-3">
            <Col className="col-1 px-0">
              <BrowserLogoImage
                system={browserDetails.system}
              />
            </Col>
            <Col className="col-11 ps-3">
              <Row>
                <span className="k-detailHeader p-0">{browserDetails.name}</span>
              </Row>
              <Row>
                <span className="k-detailSubHeader p-0">{browserDetails.description}</span>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col >
              <Row className="k-inputText15">
                Security Group
              </Row>
              <Row className="disabled-text">
                <Dropdown>
                  <Dropdown.Toggle as={SelectGroupDropdownToggle}>
                    <Row>
                      <Col className="col-11 p-0 ">
                        { (groups && browserDetails && browserDetails.security && (browserDetails.security !== "NULL")) ? 
                          groups.find((group: any)  => group.id === browserDetails.security)?.name : 
                          "----"
                        }
                      </Col>
                    </Row>
                  </Dropdown.Toggle>

                  <Dropdown.Menu as={SelectGroupDropdownMenu} >
                    {groups && groups.map((group: any, index: number) => {
                      return (
                        <Dropdown.Item 
                          className="k-inputText12" 
                          key={`paneGroupEditForm.Dropdown.${index}`} 
                          onClick={(event: any) => toggleHandlers.handlePutBrowserSecurityGroupSubmit(event, group)}
                        >
                          {group.name}
                        </Dropdown.Item>)
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Row>
            </Col>
            <Col >
              <Row className="k-inputText15">
                Linked Project
              </Row>
              <Row className="disabled-text">
                <Dropdown>
                  <Dropdown.Toggle as={SelectProjectDropdownToggle}>
                    <Row>
                      <Col className="col-11 p-0 ">
                      { (projects && browserDetails && browserDetails.project && (browserDetails.project !== "NULL")) ? 
                          projects.find((project: any)  => project.id === browserDetails.project)?.name : 
                          "----"
                        }
                      </Col>
                    </Row>
                  </Dropdown.Toggle>

                  <Dropdown.Menu as={SelectProjectDropdownMenu} >
                    {projects && projects.map((object: any, index: number) => {
                      return (
                        <Dropdown.Item 
                          className="k-inputText12" 
                          key={`paneProjectEditForm.Dropdown.${index}`} 
                          onClick={(event: any) => toggleHandlers.handlePutBrowserProjectSubmit(event, object)}
                        >
                          {object.name}
                        </Dropdown.Item>)
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Row>
            </Col>
            <Col >
              <Row className="k-inputText15">
                Linked Profile
              </Row>
              <Row className="disabled-text">
                <Dropdown>
                  <Dropdown.Toggle as={SelectProfileDropdownToggle}>
                    <Row>
                      <Col className="col-11 p-0 ">
                      { (profiles && browserDetails && browserDetails.profile && (browserDetails.profile !== "NULL")) ? 
                          profiles.find((profile: any)  => profile.id === browserDetails.profile)?.name : 
                          "----"
                        }
                      </Col>
                    </Row>
                  </Dropdown.Toggle>

                  <Dropdown.Menu as={SelectProfileDropdownMenu} >
                    {profiles && profiles.map((object: any, index: number) => {
                      return (
                        <Dropdown.Item 
                          className="k-inputText12" 
                          key={`paneProfileEditForm.Dropdown.${index}`} 
                          onClick={(event: any) => toggleHandlers.handlePutBrowserProfileSubmit(event, object)}
                        >
                          {object.name}
                        </Dropdown.Item>)
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col className="col-6 pt">
          <Row className="k-bb pt-4 mt-2">
            <span className="k-detailControlHeader p-0">Browser Details</span>
          </Row>
          <Row className="pt-2">
            <Col className="">
              <Row className=""><span className="k-inputText15 p-0">Status</span></Row>
              <Row className=""><span className={`k-inputText15 p-0 ${ browserStatus }`}>{browserStatus.toUpperCase()}</span></Row>
            </Col>
            <Col>
              <Row className="p-0"><span className="k-inputText15 p-0">Browser Control</span></Row>
              <Row className="p-0">
                <ProductControlPanel  
                  medialControlHandlers={mediaControlHandlers} 
                  desktopStatus={browserStatus} 
                  iFrameID={`desktop-iframe-${browserDetails.id}`}
                />
              </Row>
            </Col>
            <Col>
            <Row className="p-0"><span className="k-inputText15 p-0">Location</span></Row>
              <Row className="p-0"><span className="k-inputText15 p-0">{helpers.getReadableProductLocation(browserDetails.region)}</span></Row>
            </Col>
            <Col>
            <Row className="p-0"><span className="k-inputText15 p-0">IP Address</span></Row>
              <Row className="p-0"><span className="k-inputText15 p-0">{browserDetails.ipv4  || "----"}</span></Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="pt-4">
        <Col>
          <div id={`fullscreen-${browserDetails.id}`} className="fullScreenContainer">
            <Row id="aa" xs="auto" className="toolbar">
              <Col className="pe-0"><img id="dd" src={hideContentBtn} alt={"Hide Content"} className="toobarButton pe-1" onClick={helpers.hideContent}/></Col>
              <Col className="ps-1 pe-0"><img id="ee" src={retractContentBtn} alt={"Redact"} className="toobarButton pe-1" onClick={helpers.exitFullScreen}/></Col>
              <Col className="ps-1 pe-0 col-auto me-auto"><img id="ff" src={expandContentBtn} alt={"Expand"} className="toobarButton pe-1" onClick={() => helpers.enterFullScreen(`fullscreen-${browserDetails.id}`)}/></Col>
              <Col className="col-auto"><img id="gg" src={openMenuDownBtn} alt={"Dopdown"} className="toobarButton" onClick={() => helpers.showDropDown(`dropdown-${browserDetails.id}`)}/></Col>
            </Row>

            <ProductContentPane
              productDetails={browserDetails} 
              mediaControlHandlers={mediaControlHandlers} 
              productType={productType}
            />

            <Row id="priv-content" className="overlayBase justify-content-md-center ">
              <Col md="auto" className="k-iFrameCenter">
                <Row>
                  <Col className="col-3 pt-2">
                    <img src={infoIcon} alt={"Icon"} className="k-detailHeaderImg k-statusImg"/>
                  </Col>
                  <Col className="ps-0">
                    <h1>Locked</h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h4>Your {lowercaseProductType} has been locked</h4>
                  </Col>
                </Row>
              </Col>
            </Row>
            
            <ProductToolsPanel
              productDetails={browserDetails}
              notes={browserNotes}
              vault={browserVault}
              decryptedVaultKey={decryptedVaultKey}
              preSelectedSecretId={preSelectedSecretId}

              vaultPasswordSubmitError={browserVaultPasswordSubmitError}
              vaultSecretEditSubmitError={browserVaultSecretEditSubmitError}

              vaultHandlers={productVaultHandlers}
              noteHandlers={productNoteHandlers}
            />

          </div>
        </Col>
      </Row>
    </>
  );
};



export {
  Project,
  Profile,
  SelectedDesktop,
  SelectedBrowser,
  SelectedCommunicate,

  // New 
};

